import apiClient from "../common/api/client";
import endpoints from "./endpoints";

const endpoint = endpoints.CLAIMS;
const route_insured = "/insured";
const route_provider = "/provider";
const route_reimbursement_claim_facts = "/reimbursement-claim-facts";
const route_claim_facts = "/claim-facts";
const route_claim_types = "/claim-types";
const route_claim_form_types = "/claim-form-types";
const rout_download_claim_details_service_level_report =
  "/download-claim-details-service-level-report";
const route_claim_form_comments = "/claim-form-comments";
const route_transaction_required_approvals = "/transaction-required-approvals";
const route_transaction_requires_approval_reasons =
  "/transaction-requires-approval-reasons";

const act_download_claim_details_service_level_system_user_report =
  "/download-claim-details-service-level-system-user-report";
const route_claim = "/claim";
const route_claim_invoices = "/claim-invoices";
const route_claim_form_attachment = "/claim-form-attachment";
const act_claim_form_adjustment_history = "/claim-form-adjustment-history";
const act_claim_invoice_taxes = "/claim-invoice-taxes";
const act_claim_form_attachments = "/claim-form-attachments";
const route_claim_booklet_provider_info = "/claim-booklet-provider-info";
const act_create_new_claim_form = "/create-new-claim-form";
const act_checkin = "/checkin";
const act_checkout = "/checkout";
const act_set_provider_amount_as_price_list = "/provider-amount-as-price-list";
const act_set_accident_date = "/accident-date";
const act_set_claim_form_prescription_code = "/set-prescription-code";
const act_clear_claim_form_prescription_code = "/clear-prescription-code";
const act_set_reimbursement = "/reimbursement";
const act_set_ignore_prescription_code = "/ignore-prescription-code/";
const act_set_ignore_old_claims = "/ignore-old-claims";
const act_set_admission_date = "/admission-date";
const act_set_main_operation = "/main-operation";
const act_set_originals_received = "/originals-received";
const act_set_override_originals_received_rule =
  "/override-originals-received-rule";
const act_set_ignore_approval_expiry_date = "/ignore-approval-expiry-date";
const act_set_override_policy_expiration_period =
  "/override-policy-expiration-period";
const act_set_provider = "/provider";
const act_set_treatment_doctor = "/treatment-doctor";
const act_set_consultation_date = "/consultation-date";
const act_set_physician = "/physician";
const route_claim_form_diagnosis = "/claim-form-diagnosis";
const act_is_preexisting = "/is-preexisting";
const act_is_chronic = "/is-chronic";
const act_set_primary = "/set-primary";
const route_claim_form_service = "/claim-form-service";
const route_claim_form_service_detail = "/claim-form-service-detail";
const act_attach = "/attach";
const act_set_claim_form_service_requested_quantities = "/requested-quantities";
const act_set_service_accepted_quantity = "/accepted-quantities";
const act_set_requested_amount = "/requested-amount";
const act_set_claim_form_service_Tooth_Number = "/tooth-number";
const act_set_provider_claimed_amount = "/provider-claimed-amount";
const act_set_user_price = "/user-price";
const act_override_waiting_period = "/override-waiting-period";
const act_get_alternate_benefits = "/alternate-benefits";
const act_set_primary_benefit = "/primary-benefit";
const act_reject = "/reject";
const act_accept = "/accept";
const act_refresh_benefits = "/refresh-benefits";
const act_delete_claim_form = "/delete-claim-form";
const act_post_claim_form = "/post-claim-form";
const act_unpost_claim_form = "/unpost-claim-form";
const act_rollback_reject = "/rollback-reject";
const act_recalculate = "/recalculate";
const act_reset_services_claimed_as_requested =
  "/reset-services-claimed-as-requested";
const act_set_services_accepted_as_requested =
  "/set-services-accepted-as-requested";
const act_reset_requested_amount = "/reset-requested-amount";
const route_claim_invoice = "/claim-invoice";
const act_delete_claim_invoice = "/delete-claim-invoice";
const act_settle_claim_invoice = "/settle-claim-invoice";
const act_unsettle_claim_invoice = "/unsettle-claim-invoice";
const act_set_debit = "/set-debit";
const act_accept_pending_amount_as_debit_automatically =
  "/set-accept-pending-amount-as-debit-automatically";
const act_deduct_claim_difference_copayment =
  "/set-deduct-claim-difference-copayment";
const act_discount_on_coverage_amount = "/set-discount-on-coverage-amount";
const act_override_reimbursement_benchmark =
  "/set-override-reimbursement-benchmark";
const act_set_waived_debit_admin_cost = "/set-waived-debit-admin-cost";
const act_remove_waived_debit_admin_cost = "/remove-waived-debit-admin-cost";
const act_accept_pending_as_debit = "/accept-pending-as-debit";
const act_set_hold_provider_payments = "/set-hold-provider-payments";
const act_unset_hold_provider_payments = "/unset-hold-provider-payments";
const act_set_disregard_deductible_as_debit =
  "/set-disregard-deductible-as-debit";
const act_unset_disregard_deductible_as_debit =
  "/unset-disregard-deductible-as-debit";
const act_accepted_as_requested = "/accepted-as-requested";
const act_calculate_accepted_amount = "/calculate-accepted-amounts";
const act_accept_as_approval = "/accept-as-approval";
const act_adjust_claim_invoice = "/adjust-claim-invoice";
const act_relink_claim_form_services = "/relink-claim-form-services";
const act_requested_quantity = "/requested-quantity";
const act_accepted_quantity = "/accepted-quantity";
const act_follows_main_service_price_ratio =
  "/follows-main-service-price-ratio";
const act_price_ratio = "/price-ratio";
const route_claim_invoice_service = "/claim-invoice-service";
const act_accepted_amount = "/accepted-amount";
const act_set_coverage_percentage = "/coverage-percentage";
const act_set_discount_percentage = "/discount-percentage";
const act_withhold_payment = "/withhold-payment";
const act_set_withheld_quantity = "/withheld-quantities";
const act_set_withheld_amount = "/withheld-amount";
const route_claim_invoice_service_detail = "/claim-invoice-service-detail";
const act_accept_pending_amount = "/accept-pending-amount";
const act_auto_apply_examination = "/auto-apply-examination";
const act_claim_form_details = "/claim-form-details";
const act_force_checkin = "/force-checkin";
const route_download_claims_extracted_from_fund_report =
  "/download-claims-extracted-from-fund-report";
const act_set_claim_reason = "/set-claim-reason";
const act_get_exclusion_Details = "/exclusion-details";
const act_update_provider_estimate_amount = "/update-provider-estimate-amount";
const act_set_override_referral_alert = "/override-referral-alert";
const act_transmittal_claim_services = "/transmittal-claim-services";
const act_import_transmittal_claim_services =
  "/import-transmittal-claim-services";
const act_download_claim_invoice_settlement_report =
  "/download-claim-invoice-settlement-report";
const act_download_claim_details_report = "/download-claim-details-report";
const act_download_claim_details_benefit_level_report =
  "/download-claim-details-benefit-level-report";
const act_override_price_update_request = "/override-price-update-request";
const act_download_claim_payment_details_report =
  "/download-claim-payment-details-report";
const act_download_claim_state_details_report =
  "/download-claim-state-details-report";

const route_claim_categories = "/claim-categories";
const act_delete_claim_form_type = "/delete-claim-form-type";
const act_update_claim_form_type = "/update-claim-form-type";
const act_delete_claim_type = "/delete-claim-type";
const route_claim_form_type = "/claim-form-type";
const route_claim_type = "/claim-type";
const act_update_claim_type = "/update-claim-type";
const route_booklet_types = "/booklet-types";
const act_update_booklet_type = "/update-booklet-type";
const act_delete_booklet_type = "/delete-booklet-type";
const route_booklet_type = "/booklet-type";
const route_claim_type_services = "/claim-type-services";
const route_claim_type_service = "/claim-type-service";
const act_delete_claim_type_service = "/delete-claim-type-service";
const route_enforce_is_preliminary = "/enforce-is-preliminary";
const route_enforce_main_operation = "/enforce-main-operation";
const route_enable_reimbursement_request = "/enable-reimbursement-request";
const route_claim_booklets = "/claim-booklets";
const route_claim_booklet = "/claim-booklet";
const act_checkout_claim_booklet = "/checkout-claim-booklet";
const act_checkin_claim_booklet = "/checkin-claim-booklet";
const act_force_checkin_claim_booklet = "/force-checkin-claim-booklet";
const act_deliver_claim_booklet = "/deliver-claim-booklet";
const act_print_claim_booklet = "/print-claim-booklet";
const act_remove_print_claim_booklet = "/remove-print-claim-booklet";
const act_remove_delivery_claim_booklet = "/remove-delivery-claim-booklet";
const act_delete_claim_booklet = "/delete-claim-booklet";
const act_assign_claim_booklet_to_provider =
  "/assign-claim-booklet-to-provider";
const act_download_claim_booklet_receipt_report =
  "/download-claim-booklet-receipt-report";
const route_syndicate_shares = "/syndicate-shares";
const route_syndicate_share = "/syndicate-share";
const act_update_syndicate_share = "/update-syndicate-share";
const act_cancel_syndicate_share = "/cancel-syndicate-share";
const act_reinstate_syndicate_share = "/reinstate-syndicate-share";
const act_delete_syndicate_share = "/delete-syndicate-share";
const act_set_user_comment = "/set-user-comment";
const route_check_consultation_eligibility = "/check-consultation-eligibility";
const act_download_claim_summary_report = "/download-claim-summary-report";
const act_download_policy_utilization_report =
  "/download-policy-utilization-report";
const act_download_utilization_report = "/download-utilization-report";
const act_download_utilization_claim_level_report =
  "/download-utilization-claim-level-report";
const act_download_outstanding_claims_payment_report =
  "/download-outstanding-claims-payment-report";
const act_download_outstanding_claims_report =
  "/download-outstanding-claims-report";
const act_download_claim_form_details_report_compact =
  "/download-claim-form-details-report-compact";
const act_download_claim_form_details_report =
  "/download-claim-form-details-report";
const act_download_diagnostic_test_claims_summary_report =
  "/download-diagnostic-test-claims-summary-report";
const act_download_diagnostic_test_claims_details_report =
  "/download-diagnostic-test-claims-details-report";
const act_download_claims_performance_report =
  "/download-claims-performance-report";
const act_set_override_third_party_approval = "/override-third-party-approval";
const act_set_third_party_approval_received = "/third-party-approval-received";
const act_set_claim_form_type = "/set-claim-form-type";
const act_set_override_exclusion = "/set-override-exclusion";
const act_get_scanned_claims_attachments = "/scanned-claims-attachments";
const act_download_claim_distribution_report =
  "/download-claim-distribution-report";
const act_download_claim_form_details_client_copy_report =
  "/download-claim-form-details-client-copy-report";
const act_download_medical_transactions_service_level_report =
  "/download-medical-transactions-service-level-report";
const act_claim_form_service_audit_comment =
  "/claim-form-service-audit-comment";
const act_update_claim_form_service_audit_comment =
  "/update-claim-form-service-audit-comment";
const act_delete_claim_form_service_audit_comment =
  "/delete-claim-form-service-audit-comment";
const route_claim_form_service_audit_comment =
  "/claim-form-service-audit-comment";
const act_download_medical_transactions_report =
  "/download-medical-transactions-report";
const act_download_utilization_payer_level_report =
  "/download-utilization-payer-level-report";

const getClaimTypes = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_claim_types,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getClaimFormTypes = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_claim_form_types,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getInsuredReimbursementClaimFacts = (
  insuredID,
  searchCriteria,
  paging
) => {
  return apiClient.post(
    endpoint +
      route_insured +
      "/" +
      insuredID +
      route_reimbursement_claim_facts,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getProviderClaimFacts = (providerID, searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_provider + "/" + providerID + route_claim_facts,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getClaimFact = (claimFormID) => {
  return apiClient.get(endpoint + route_claim_facts + "/" + claimFormID);
};

// const getClaimFormTypes = (searchCriteria) => {
//   return {
//     ok: 200,
//     data: [
//       {
//         ID: 1,
//         LocalName: "استشفاء",
//         ForeignName: "Hospital Claim Form",
//         Code: "HOSPITAL_CLAIM_FORM",
//         IsDoctorVisit: true,
//       },
//       {
//         ID: 2,
//         LocalName: "زيارة طبيب",
//         ForeignName: "Doctor Visit",
//         Code: "DOCTOR_VISIT",
//         IsDoctorVisit: false,
//       },
//       {
//         ID: 3,
//         LocalName: "مخبر",
//         ForeignName: "Lab",
//         Code: "LAB",
//         IsDoctorVisit: false,
//       },
//       {
//         ID: 4,
//         LocalName: "صيدلية",
//         ForeignName: "Pharmacy",
//         Code: "PHARMACY",
//         IsDoctorVisit: false,
//       },
//       {
//         ID: 5,
//         LocalName: "أشعة",
//         ForeignName: "X Ray",
//         Code: "X_RAY",
//         IsDoctorVisit: false,
//       },
//     ],
//   };
// };

const downloadClaimDetailsServiceLevelReport = (criteria) => {
  return apiClient.post(
    endpoint + rout_download_claim_details_service_level_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadClaimDetailsServiceLevelSystemUserReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_claim_details_service_level_system_user_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const getClaimForms = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getClaimForm = (claimFormID, includeInsuredPicture) =>
  apiClient.get(endpoint + "/" + claimFormID + "/" + includeInsuredPicture);

const getClaimFormComments = (claimFormID) => {
  return apiClient.get(
    endpoint + "/" + claimFormID + route_claim_form_comments
  );
};

const getClaimFormAdjustmentHistoryList = (claimFormID) => {
  return apiClient.get(
    endpoint + "/" + claimFormID + act_claim_form_adjustment_history
  );
};

const getClaim = (claimID) =>
  apiClient.get(endpoint + route_claim + "/" + claimID);

const getClaimInvoiceTaxes = (claimInvoiceID) =>
  apiClient.get(endpoint + "/" + claimInvoiceID + act_claim_invoice_taxes);

const getClaimFormAttachments = (criteria) => {
  return apiClient.post(
    endpoint + act_claim_form_attachments,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addClaimFormAttachment = (claimFormID, attachmentID) => {
  return apiClient.post(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_attachment +
      "/" +
      attachmentID
  );
};

const deleteClaimFormAttachment = (claimFormID, claimFormAttachmentID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_attachment +
      "/" +
      claimFormAttachmentID
  );
};

const getClaimInvoices = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_claim_invoices,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getClaimBookletProviderInfo = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_claim_booklet_provider_info,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const createNewClaimForm = (data) => {
  return apiClient.post(endpoint + act_create_new_claim_form, data);
};

const checkinClaimForm = (claimFormID, criteria) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_checkin,
    criteria && JSON.stringify({ ...criteria })
  );
};

const checkoutClaimForm = (claimFormID, criteria) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_checkout,
    criteria && JSON.stringify({ ...criteria })
  );
};

const setClaimFormProviderAmountAsPriceList = (
  claimFormID,
  providerAmountAsPerPriceList
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      act_set_provider_amount_as_price_list +
      "/" +
      providerAmountAsPerPriceList
  );
};

const setAccidentDate = (claimFormID, criteria) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_set_accident_date,
    criteria
  );
};

const setClaimFormPrescriptionCode = (claimFormID, prescriptionCode) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_set_claim_form_prescription_code,
    JSON.stringify({ PrescriptionCode: prescriptionCode })
  );
};

const clearClaimFormPrescriptionCode = (claimFormID) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_clear_claim_form_prescription_code
  );
};

const setReimbursement = (claimFormID, isReimbursement) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_set_reimbursement + "/" + isReimbursement
  );
};

const setIgnorePrescriptionCode = (claimFormID, ignorePrescriptionCode) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      act_set_ignore_prescription_code +
      "/" +
      ignorePrescriptionCode
  );
};

const setIgnoreOldClaims = (claimFormID, ignoreOldClaims) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      act_set_ignore_old_claims +
      "/" +
      ignoreOldClaims
  );
};

const setAdmissionDate = (claimFormID, admissionDate) => {
  return apiClient.put(endpoint + "/" + claimFormID + act_set_admission_date, {
    AdmissionDate: admissionDate,
  });
};

const setMainOperation = (claimFormID, serviceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      act_set_main_operation +
      "/" +
      (serviceID || "")
  );
};

const setOriginalsReceived = (claimFormID, originalsReceived) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      act_set_originals_received +
      "/" +
      originalsReceived
  );
};

const setOverrideOriginalsReceivedRule = (
  claimFormID,
  overrideOriginalsReceivedRule
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      act_set_override_originals_received_rule +
      "/" +
      overrideOriginalsReceivedRule
  );
};

const setIgnoreApprovalExpiryDate = (claimFormID, ignoreApprovalExpiryDate) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      act_set_ignore_approval_expiry_date +
      "/" +
      ignoreApprovalExpiryDate
  );
};

const setOverridePolicyExpirationPeriod = (
  claimFormID,
  overridePolicyExpirationPeriod
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      act_set_override_policy_expiration_period +
      "/" +
      overridePolicyExpirationPeriod
  );
};

const setProvider = (claimFormID, providerID) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_set_provider + "/" + providerID
  );
};

const setTreatmentDoctor = (claimFormID, treatmentDoctorID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      act_set_treatment_doctor +
      "/" +
      treatmentDoctorID
  );
};

const removeTreatmentDoctor = (claimFormID) => {
  return apiClient.put(endpoint + "/" + claimFormID + act_set_treatment_doctor);
};

const setConsultationDate = (claimFormID, consultationDate) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_set_consultation_date,
    {
      ConsultationDate: consultationDate,
    }
  );
};

const setPhysician = (claimFormID, physicianID) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_set_physician + "/" + physicianID
  );
};

const removePhysician = (claimFormID) => {
  return apiClient.put(endpoint + "/" + claimFormID + act_set_physician);
};

const addClaimFormDiagnosis = (claimFormID, diagnosisID) => {
  return apiClient.post(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_diagnosis +
      "/" +
      diagnosisID
  );
};

const removeClaimFormDiagnosis = (claimFormID, claimFormDiagnosisID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_diagnosis +
      "/" +
      claimFormDiagnosisID
  );
};

const setClaimFormDiagnosisPreexisting = (
  claimFormID,
  claimFormDiagnosisID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_diagnosis +
      "/" +
      claimFormDiagnosisID +
      act_is_preexisting,
    JSON.stringify({ ...criteria })
  );
};

const setClaimFormDiagnosisChronic = (
  claimFormID,
  claimFormDiagnosisID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_diagnosis +
      "/" +
      claimFormDiagnosisID +
      act_is_chronic,
    JSON.stringify({ ...criteria })
  );
};

const setPrimaryClaimFormDiagnosis = (claimFormID, claimFormDiagnosisID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_diagnosis +
      "/" +
      claimFormDiagnosisID +
      act_set_primary
  );
};

const addClaimFormService = (claimFormID, criteria) => {
  return apiClient.post(
    endpoint + "/" + claimFormID + route_claim_form_service,
    JSON.stringify({
      ...criteria,
    })
  );
};

const removeClaimFormService = (claimFormID, claimFormServiceID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID
  );
};

const attachClaimFormServiceToDiagnosis = (
  claimFormID,
  claimFormServiceID,
  claimFormDiagnosisID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      route_claim_form_diagnosis +
      "/" +
      claimFormDiagnosisID +
      act_attach
  );
};

const setClaimFormServiceRequestedQuantities = (
  claimFormID,
  claimFormServiceID,
  quantity,
  subPacksQuantity,
  dosePerDay,
  numberOfDays
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_set_claim_form_service_requested_quantities,
    JSON.stringify({
      Quantity: quantity,
      SubPacksQuantity: subPacksQuantity,
      DosePerDay: dosePerDay,
      NumberOfDays: numberOfDays,
    })
  );
};

const setClaimFormServiceAcceptedQuantities = (
  claimFormID,
  claimFormServiceID,
  quantity,
  subPacksQuantity
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_set_service_accepted_quantity,
    JSON.stringify({ Quantity: quantity, SubPacksQuantity: subPacksQuantity })
  );
};

const setClaimFormServiceRequestedAmount = (
  claimFormID,
  claimFormServiceID,
  amount
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_set_requested_amount,
    JSON.stringify({ Amount: amount })
  );
};

const setClaimFormServiceToothNumber = (
  claimFormID,
  claimFormServiceID,
  toothNumber
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_set_claim_form_service_Tooth_Number,
    JSON.stringify({ ToothNumber: toothNumber })
  );
};

const setClaimFormServiceProviderClaimedAmount = (
  claimFormID,
  claimFormServiceID,
  amount
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_set_provider_claimed_amount,
    JSON.stringify({ Amount: amount })
  );
};

const setClaimFormServiceUserPrice = (
  claimFormID,
  claimFormServiceID,
  price,
  currencyID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_set_user_price,
    JSON.stringify({ Price: price, CurrencyID: currencyID })
  );
};

const setOverrideServiceWaitingPeriod = (
  claimFormID,
  claimFormServiceID,
  overrideWaitingPeriod
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_override_waiting_period +
      "/" +
      overrideWaitingPeriod
  );
};

const getClaimFormServiceAlternateBenefits = (
  claimFormID,
  claimFormServiceID,
  excludePrimaryBenefit
) => {
  return apiClient.get(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_get_alternate_benefits +
      "/" +
      excludePrimaryBenefit
  );
};

const setClaimFormServicePrimaryBenefit = (
  claimFormID,
  claimFormServiceID,
  claimFormPolicyInsuredBenefitID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_set_primary_benefit +
      "/" +
      claimFormPolicyInsuredBenefitID
  );
};

const rejectClaimFormService = (
  claimFormID,
  claimFormServiceID,
  rejectionCommentID,
  rejectionReason
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_reject,
    JSON.stringify({
      CommentID: rejectionCommentID,
      AdditionalInfo: rejectionReason,
    })
  );
};

const acceptClaimFormService = (claimFormID, claimFormServiceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_accept
  );
};

const refreshClaimFormBenefits = (claimFormID) => {
  return apiClient.put(endpoint + "/" + claimFormID + act_refresh_benefits);
};

const deleteClaimForm = (claimFormID, commentID, commentText) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_delete_claim_form,
    JSON.stringify({ CommentID: commentID, AdditionalInfo: commentText })
  );
};

const postClaimForm = (claimFormID, criteria) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_post_claim_form,
    criteria && JSON.stringify({ ...criteria })
  );
};

const unpostClaimForm = (claimID) => {
  return apiClient.put(endpoint + "/" + claimID + act_unpost_claim_form);
};

const rejectClaimForm = (claimFormID, commentID, commentText) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_reject,
    JSON.stringify({ CommentID: commentID, AdditionalInfo: commentText })
  );
};

const rollbackRejectClaimForm = (claimFormID) => {
  return apiClient.put(endpoint + "/" + claimFormID + act_rollback_reject);
};

const recalculateClaimForm = (claimFormID) => {
  return apiClient.put(endpoint + "/" + claimFormID + act_recalculate);
};

const resetClaimFormServicesClaimedAmountAsRequested = (claimFormID) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_reset_services_claimed_as_requested
  );
};

const setClaimFormServicesAcceptedAmountAsRequested = (claimFormID) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_set_services_accepted_as_requested
  );
};

const resetClaimFormServiceRequestedAmount = (
  claimFormID,
  claimFormServiceID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_reset_requested_amount
  );
};

const deleteClaimInvoice = (claimID, claimInvoiceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_delete_claim_invoice
  );
};

const settleClaimInvoice = (claimID, claimInvoiceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_settle_claim_invoice
  );
};

const unsettleClaimInvoice = (claimID, claimInvoiceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_unsettle_claim_invoice
  );
};

const setClaimInvoiceDebit = (
  claimID,
  claimInvoiceID,
  isDebit,
  debitCommentID,
  additionalInfo
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_set_debit +
      "/" +
      isDebit,
    debitCommentID &&
      JSON.stringify({
        CommentID: debitCommentID,
        AdditionalInfo: additionalInfo,
      })
  );
};

const setClaimInvoiceAcceptPendingAmountAsDebitAutomatically = (
  claimID,
  claimInvoiceID,
  acceptPendingAsDebitAutomatically
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_accept_pending_amount_as_debit_automatically +
      "/" +
      acceptPendingAsDebitAutomatically
  );
};

const setClaimInvoiceDeductClaimDifferenceCopayment = (
  claimID,
  claimInvoiceID,
  deductClaimDifferenceCopayment
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_deduct_claim_difference_copayment +
      "/" +
      deductClaimDifferenceCopayment
  );
};

const setClaimInvoiceDiscountOnCoverageAmount = (
  claimID,
  claimInvoiceID,
  discountOnCoverageAmount
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_discount_on_coverage_amount +
      "/" +
      discountOnCoverageAmount
  );
};

const setClaimInvoiceOverrideReimbursementBenchmark = (
  claimID,
  claimInvoiceID,
  overrideReimbursementBenchmark
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_override_reimbursement_benchmark +
      "/" +
      overrideReimbursementBenchmark
  );
};

const setClaimInvoiceWaivedDebitAdminCost = (claimID, claimInvoiceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_set_waived_debit_admin_cost
  );
};

const removeClaimInvoiceWaivedDebitAdminCost = (claimID, claimInvoiceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_remove_waived_debit_admin_cost
  );
};

const setClaimInvoiceAcceptAllPendingAsDebit = (
  claimFormID,
  claimID,
  claimInvoiceID,
  commentID,
  additionalInfo
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_accept_pending_as_debit,
    JSON.stringify({ CommentID: commentID, AdditionalInfo: additionalInfo })
  );
};

const setClaimInvoiceHoldProviderPayments = (
  claimID,
  claimInvoiceID,
  commentID,
  additionalInfo
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_set_hold_provider_payments,
    JSON.stringify({ CommentID: commentID, AdditionalInfo: additionalInfo })
  );
};

const unsetClaimInvoiceHoldProviderPayments = (claimID, claimInvoiceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_unset_hold_provider_payments
  );
};

const setClaimInvoiceDisregardDeductibleAsDebit = (claimID, claimInvoiceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_set_disregard_deductible_as_debit
  );
};

const unsetClaimInvoiceDisregardDeductibleAsDebit = (
  claimID,
  claimInvoiceID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_unset_disregard_deductible_as_debit
  );
};

const setClaimInvoiceAcceptedAsRequested = (claimID, claimInvoiceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_accepted_as_requested
  );
};

const calculateClaimInvoiceAcceptedAmount = (claimID, claimInvoiceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_calculate_accepted_amount
  );
};

const acceptClaimInvoiceAsPerApproval = (claimID, claimInvoiceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_accept_as_approval
  );
};

const adjustClaimInvoice = (claimID, claimInvoiceID) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_adjust_claim_invoice
  );
};

const relinkClaimFormServicesToDiagnosis = (
  claimFormID,
  claimDiagnosisID,
  claimFormServices
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_diagnosis +
      "/" +
      claimDiagnosisID +
      act_relink_claim_form_services,
    JSON.stringify({ ClaimFormServiceIDs: claimFormServices })
  );
};

const setClaimFormServiceDetailRequestedQuantity = (
  claimFormID,
  claimFormServiceID,
  claimFormServiceDetailID,
  quantity
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      route_claim_form_service_detail +
      "/" +
      claimFormServiceDetailID +
      act_requested_quantity,
    JSON.stringify({
      Quantity: quantity,
    })
  );
};

const setClaimFormServiceDetailAcceptedQuantity = (
  claimFormID,
  claimFormServiceID,
  claimFormServiceDetailID,
  quantity
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      route_claim_form_service_detail +
      "/" +
      claimFormServiceDetailID +
      act_accepted_quantity,
    JSON.stringify({
      Quantity: quantity,
    })
  );
};

const setClaimFormServiceDetailRequestedAmount = (
  claimFormID,
  claimFormServiceID,
  claimFormServiceDetailID,
  amount
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      route_claim_form_service_detail +
      "/" +
      claimFormServiceDetailID +
      act_set_requested_amount,
    JSON.stringify({ Amount: amount })
  );
};

const setClaimFormServiceDetailProviderClaimedAmount = (
  claimFormID,
  claimFormServiceID,
  claimFormServiceDetailID,
  amount
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      route_claim_form_service_detail +
      "/" +
      claimFormServiceDetailID +
      act_set_provider_claimed_amount,
    JSON.stringify({ Amount: amount })
  );
};

const setClaimFormServiceDetailFollowsMainServicePriceRatio = (
  claimFormID,
  claimFormServiceID,
  claimFormServiceDetailID,
  followsMainServicePriceRatio
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      route_claim_form_service_detail +
      "/" +
      claimFormServiceDetailID +
      act_follows_main_service_price_ratio +
      "/" +
      followsMainServicePriceRatio
  );
};

const rejectClaimFormServiceDetail = (
  claimFormID,
  claimFormServiceID,
  claimFormServiceDetailID,
  rejectionCommentID,
  rejectionReason
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      route_claim_form_service_detail +
      "/" +
      claimFormServiceDetailID +
      act_reject,
    JSON.stringify({
      CommentID: rejectionCommentID,
      AdditionalInfo: rejectionReason,
    })
  );
};

const acceptClaimFormServiceDetail = (
  claimFormID,
  claimFormServiceID,
  claimFormServiceDetailID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      route_claim_form_service_detail +
      "/" +
      claimFormServiceDetailID +
      act_accept
  );
};

const resetClaimFormServiceDetailRequestedAmount = (
  claimFormID,
  claimFormServiceID,
  claimFormServiceDetailID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      route_claim_form_service_detail +
      "/" +
      claimFormServiceDetailID +
      act_reset_requested_amount
  );
};

const setClaimFormServicePriceRatio = (
  claimFormID,
  claimFormServiceID,
  priceRatio
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_price_ratio,
    JSON.stringify({ Amount: priceRatio })
  );
};

const setClaimInvoiceServiceAcceptedQuantities = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  quantity,
  subPacksQuantity
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      act_set_service_accepted_quantity,
    JSON.stringify({ Quantity: quantity, SubPacksQuantity: subPacksQuantity })
  );
};

const setClaimInvoiceServiceAcceptedAmount = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  amount
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      act_accepted_amount,
    JSON.stringify({ Amount: amount })
  );
};

const setClaimInvoiceServiceDebit = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  isDebit,
  debitCommentID,
  additionalInfo
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      act_set_debit +
      "/" +
      isDebit,
    debitCommentID &&
      JSON.stringify({
        CommentID: debitCommentID,
        AdditionalInfo: additionalInfo,
      })
  );
};

const setClaimInvoiceCoveragePercentage = (
  claimID,
  claimInvoiceID,
  coveragePercentage
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_set_coverage_percentage,
    JSON.stringify({ CoveragePercentage: coveragePercentage })
  );
};

const setClaimInvoiceDiscountPercentage = (
  claimID,
  claimInvoiceID,
  discountPercentage
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      act_set_discount_percentage,
    JSON.stringify({ DiscountPercentage: discountPercentage })
  );
};

const setClaimInvoiceServiceCoveragePercentage = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  coveragePercentage
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      act_set_coverage_percentage,
    JSON.stringify({ CoveragePercentage: coveragePercentage })
  );
};

const setClaimInvoiceServiceDiscountPercentage = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  discountPercentage
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      act_set_discount_percentage,
    JSON.stringify({ DiscountPercentage: discountPercentage })
  );
};

const setClaimInvoiceServiceWithholdPayment = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  withholdPayment
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      act_withhold_payment +
      "/" +
      withholdPayment
  );
};

const setClaimInvoiceServiceWithheldQuantities = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  quantity,
  subPacksQuantity
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      act_set_withheld_quantity,
    JSON.stringify({ Quantity: quantity, SubPacksQuantity: subPacksQuantity })
  );
};

const setClaimInvoiceServiceWithheldAmount = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  withheldAmount
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      act_set_withheld_amount,
    JSON.stringify({ Amount: withheldAmount })
  );
};

const setClaimInvoiceServiceDetailAcceptedQuantities = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  claimInvoiceServiceDetailID,
  quantity
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      route_claim_invoice_service_detail +
      "/" +
      claimInvoiceServiceDetailID +
      act_accepted_quantity,
    JSON.stringify({ Quantity: quantity })
  );
};

const setClaimInvoiceServiceDetailAcceptedAmount = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  claimInvoiceServiceDetailID,
  amount
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      route_claim_invoice_service_detail +
      "/" +
      claimInvoiceServiceDetailID +
      act_accepted_amount,
    JSON.stringify({ Amount: amount })
  );
};

const setClaimInvoiceServiceDetailDebit = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  claimInvoiceServiceDetailID,
  isDebit,
  debitCommentID,
  additionalInfo
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      route_claim_invoice_service_detail +
      "/" +
      claimInvoiceServiceDetailID +
      act_set_debit +
      "/" +
      isDebit,
    debitCommentID &&
      JSON.stringify({
        CommentID: debitCommentID,
        AdditionalInfo: additionalInfo,
      })
  );
};

const setClaimInvoiceServiceDetailCoveragePercentage = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  claimInvoiceServiceDetailID,
  coveragePercentage
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      route_claim_invoice_service_detail +
      "/" +
      claimInvoiceServiceDetailID +
      act_set_coverage_percentage,
    JSON.stringify({ CoveragePercentage: coveragePercentage })
  );
};

const setClaimInvoiceServiceDetailDiscountPercentage = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  claimInvoiceServiceDetailID,
  discountPercentage
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      route_claim_invoice_service_detail +
      "/" +
      claimInvoiceServiceDetailID +
      act_set_discount_percentage,
    JSON.stringify({ DiscountPercentage: discountPercentage })
  );
};

const getClaimInvoiceCompatibleAlternatePolicyInsuredBenefits = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  claimInvoiceServiceDetailID
) => {
  return apiClient.get(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      route_claim_invoice_service_detail +
      "/" +
      claimInvoiceServiceDetailID +
      act_get_alternate_benefits
  );
};

const acceptClaimInvoiceServiceDetailPendingAmount = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  claimInvoiceServiceDetailID,
  criteria
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      route_claim_invoice_service_detail +
      "/" +
      claimInvoiceServiceDetailID +
      act_accept_pending_amount,
    JSON.stringify({ ...criteria })
  );
};

const acceptClaimInvoiceServiceDetailPendingAmountAsDebit = (
  claimFormID,
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  claimInvoiceServiceDetailID,
  commentID,
  additionalInfo
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      route_claim_invoice_service_detail +
      "/" +
      claimInvoiceServiceDetailID +
      act_accept_pending_as_debit,
    JSON.stringify({ CommentID: commentID, AdditionalInfo: additionalInfo })
  );
};

const autoApplyClaimFormBatchExamination = (claimFormID) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_auto_apply_examination
  );
};

const getClaimFormDetails = (claimFormID) => {
  return apiClient.get(endpoint + "/" + claimFormID + act_claim_form_details);
};

const forceCheckinClaimForm = (claimFormID, criteria) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_force_checkin,
    criteria && JSON.stringify({ ...criteria })
  );
};

const downloadClaimsExtractedFromFundReport = (criteria) => {
  return apiClient.post(
    endpoint + route_download_claims_extracted_from_fund_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const setClaimFormClaimReason = (claimFormID, data) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_set_claim_reason,
    data
  );
};

const getClaiminvoiceServiceExclusionDetails = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID
) => {
  return apiClient.get(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      act_get_exclusion_Details
  );
};

const getClaiminvoiceServiceDetailExclusionDetails = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  claimInvoiceServiceDetailID
) => {
  return apiClient.get(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      route_claim_invoice_service_detail +
      "/" +
      claimInvoiceServiceDetailID +
      act_get_exclusion_Details
  );
};

const updateClaimFormProviderEstimateAmount = (claimFormID, criteria) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_update_provider_estimate_amount,
    JSON.stringify({ ...criteria })
  );
};

const setOverrideReferralAlert = (claimFormID, overrideReferralAlert) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      act_set_override_referral_alert +
      "/" +
      overrideReferralAlert
  );
};

const getTransmittalClaimRelatedServices = (claimFormID) => {
  return apiClient.get(
    endpoint + "/" + claimFormID + act_transmittal_claim_services
  );
};

const importTransmittalClaimFormService = (
  claimFormID,
  transmittalClaimServicesType,
  itemID
) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_import_transmittal_claim_services,
    JSON.stringify({
      ServicesType: transmittalClaimServicesType,
      ItemID: itemID,
    })
  );
};

const downloadClaimInvoiceSettlementReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_claim_invoice_settlement_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadClaimDetailsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_claim_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadClaimDetailsBenefitLevelReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_claim_details_benefit_level_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadClaimPaymentDetailsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_claim_payment_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadClaimStateDetailsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_claim_state_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const overrideClaimFormServicePriceUpdateRequest = (
  claimFormID,
  claimFormServiceID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_override_price_update_request
  );
};

const addClaimFormType = (criteria) => {
  return apiClient.post(
    endpoint + route_claim_form_type,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getClaimCategories = (searchCriteria) => {
  return apiClient.post(
    endpoint + route_claim_categories,
    JSON.stringify({
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const deleteClaimFormType = (claimFormTypeID) => {
  return apiClient.delete(
    endpoint + act_delete_claim_form_type + "/" + claimFormTypeID
  );
};

const updateClaimFormType = (claimFormTypeID, criteria) => {
  return apiClient.put(
    endpoint + act_update_claim_form_type + "/" + claimFormTypeID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const deleteClaimType = (claimTypeID) => {
  return apiClient.delete(endpoint + act_delete_claim_type + "/" + claimTypeID);
};

const addClaimType = (claimFormTypeID, criteria) => {
  return apiClient.post(
    endpoint +
      route_claim_form_type +
      "/" +
      claimFormTypeID +
      "/" +
      route_claim_type,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateClaimType = (claimTypeID, criteria) => {
  return apiClient.put(
    endpoint + act_update_claim_type + "/" + claimTypeID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const setClaimTypeEnforceIsPreliminary = (
  claimTypeID,
  enforceIsPreliminary
) => {
  return apiClient.put(
    endpoint +
      route_claim_type +
      "/" +
      claimTypeID +
      route_enforce_is_preliminary +
      "/" +
      enforceIsPreliminary
  );
};

const setClaimTypeEnforceMainOperation = (
  claimTypeID,
  enforceMainOperation
) => {
  return apiClient.put(
    endpoint +
      route_claim_type +
      "/" +
      claimTypeID +
      route_enforce_main_operation +
      "/" +
      enforceMainOperation
  );
};

const setClaimTypeEnableReimbursementRequest = (
  claimTypeID,
  enableReimbursementRequest
) => {
  return apiClient.put(
    endpoint +
      route_claim_type +
      "/" +
      claimTypeID +
      route_enable_reimbursement_request +
      "/" +
      enableReimbursementRequest
  );
};

const getClaimType = (claimTypeID) => {
  return apiClient.get(endpoint + route_claim_type + "/" + claimTypeID);
};

const getBookletTypes = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_booklet_types,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};
const deleteBookletType = (bookletTypeID) => {
  return apiClient.delete(
    endpoint + act_delete_booklet_type + "/" + bookletTypeID
  );
};

const addBookletType = (criteria) => {
  return apiClient.post(
    endpoint + route_booklet_type,
    JSON.stringify({
      ...criteria,
    })
  );
};

const updateBookletType = (bookletTypeID, criteria) => {
  return apiClient.put(
    endpoint + act_update_booklet_type + "/" + bookletTypeID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const getClaimTypeServices = (searchCriteria, claimTypeID, paging) => {
  return apiClient.post(
    endpoint + route_claim_type + "/" + claimTypeID + route_claim_type_services,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};
const deleteClaimTypeService = (claimTypeID, claimTypeServiceID) => {
  return apiClient.delete(
    endpoint +
      route_claim_type +
      "/" +
      claimTypeID +
      act_delete_claim_type_service +
      "/" +
      claimTypeServiceID
  );
};

const addClaimTypeService = (claimTypeID, serviceID) => {
  return apiClient.post(
    endpoint +
      route_claim_type +
      "/" +
      claimTypeID +
      route_claim_type_service +
      "/" +
      serviceID
  );
};

const getClaimBooklets = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_claim_booklets,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const getClaimBooklet = (claimBookletID) => {
  return apiClient.get(endpoint + route_claim_booklet + "/" + claimBookletID);
};

const addClaimBooklet = (criteria) => {
  return apiClient.post(
    endpoint + route_claim_booklet,
    JSON.stringify({
      ...criteria,
    })
  );
};
const deleteClaimBooklet = (claimBookletID) => {
  return apiClient.delete(
    endpoint + act_delete_claim_booklet + "/" + claimBookletID
  );
};
const checkoutClaimBooklet = (claimBookletID) => {
  return apiClient.put(
    endpoint + act_checkout_claim_booklet + "/" + claimBookletID
  );
};
const checkinClaimBooklet = (claimBookletID) => {
  return apiClient.put(
    endpoint + act_checkin_claim_booklet + "/" + claimBookletID
  );
};

const forceCheckinClaimBooklet = (claimBookletID) => {
  return apiClient.put(
    endpoint + act_force_checkin_claim_booklet + "/" + claimBookletID
  );
};
const deliverClaimBooklet = (claimBookletID, criteria) => {
  return apiClient.put(
    endpoint + act_deliver_claim_booklet + "/" + claimBookletID,
    JSON.stringify({
      ...criteria,
    })
  );
};
const printClaimBooklet = (claimBookletID, criteria) => {
  return apiClient.put(
    endpoint + act_print_claim_booklet + "/" + claimBookletID,
    JSON.stringify({
      ...criteria,
    })
  );
};
const removePrintClaimBooklet = (claimBookletID) => {
  return apiClient.put(
    endpoint + act_remove_print_claim_booklet + "/" + claimBookletID
  );
};
const removeDeliveryClaimBooklet = (claimBookletID) => {
  return apiClient.put(
    endpoint + act_remove_delivery_claim_booklet + "/" + claimBookletID
  );
};

const assignClaimBookletToProvider = (claimBookletID, providerID) => {
  return apiClient.put(
    endpoint +
      route_claim_booklet +
      "/" +
      claimBookletID +
      act_assign_claim_booklet_to_provider +
      "/" +
      providerID
  );
};
const getSyndicateShares = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_syndicate_shares,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addSyndicateShare = (criteria) => {
  return apiClient.post(
    endpoint + route_syndicate_share,
    JSON.stringify({
      ...criteria,
    })
  );
};
const deleteSyndicateShare = (syndicateShareID) => {
  return apiClient.delete(
    endpoint + act_delete_syndicate_share + "/" + syndicateShareID
  );
};

const cancelSyndicateShare = (syndicateShareID, criteria) => {
  return apiClient.put(
    endpoint + act_cancel_syndicate_share + "/" + syndicateShareID,
    JSON.stringify({
      ...criteria,
    })
  );
};
const reinstateSyndicateShare = (syndicateShareID) => {
  return apiClient.put(
    endpoint + act_reinstate_syndicate_share + "/" + syndicateShareID
  );
};
const updateSyndicateShare = (syndicateShareID, criteria) => {
  return apiClient.put(
    endpoint + act_update_syndicate_share + "/" + syndicateShareID,
    JSON.stringify({
      ...criteria,
    })
  );
};

const downloadClaimBookletReceiptReport = (claimBookletID, criteria) => {
  return apiClient.post(
    endpoint + act_download_claim_booklet_receipt_report + "/" + claimBookletID,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const setClaimInvoiceServiceUserComment = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  userComment
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      act_set_user_comment,
    JSON.stringify({ Comment: userComment })
  );
};

const setClaimInvoiceServiceDetailUserComment = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  claimInvoiceServiceDetailID,
  userComment
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      route_claim_invoice_service_detail +
      "/" +
      claimInvoiceServiceDetailID +
      act_set_user_comment,
    JSON.stringify({ Comment: userComment })
  );
};

const checkConsultationEligibility = (criteria) => {
  return apiClient.post(
    endpoint + route_check_consultation_eligibility,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};
const downloadClaimSummaryReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_claim_summary_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadPolicyUtilizationReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_policy_utilization_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadUtilizationReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_utilization_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadUtilizationClaimLevelReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_utilization_claim_level_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadOutstandingClaimsPaymentReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_outstanding_claims_payment_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadOutstandingClaimsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_outstanding_claims_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadClaimFormDetailsReportCompact = (criteria) => {
  return apiClient.post(
    endpoint + act_download_claim_form_details_report_compact,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadClaimFormDetailsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_claim_form_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadDiagnosticTestClaimsSummaryReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_diagnostic_test_claims_summary_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadDiagnosticTestClaimsDetailsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_diagnostic_test_claims_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadClaimsPerformanceReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_claims_performance_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addClaimFormComment = (claimFormID, data) => {
  return apiClient.post(
    endpoint + "/" + claimFormID + route_claim_form_comments,
    data
  );
};

const deleteClaimFormComment = (claimFormID, claimFormCommentID) => {
  return apiClient.delete(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_comments +
      "/" +
      claimFormCommentID
  );
};

const setOverrideThirdPartyApprovalRequirements = (
  transactionRequiredApprovalID,
  overrideApproval
) => {
  return apiClient.put(
    endpoint +
      route_transaction_required_approvals +
      "/" +
      transactionRequiredApprovalID +
      act_set_override_third_party_approval +
      "/" +
      overrideApproval
  );
};

const setThirdPartyApprovalReceived = (
  transactionRequiredApprovalID,
  approvalReceived
) => {
  return apiClient.put(
    endpoint +
      route_transaction_required_approvals +
      "/" +
      transactionRequiredApprovalID +
      act_set_third_party_approval_received +
      "/" +
      approvalReceived
  );
};

const setClaimFormClaimFormType = (claimFormID, data) => {
  return apiClient.put(
    endpoint + "/" + claimFormID + act_set_claim_form_type,
    data
  );
};

const getTransactionRequiredApprovals = (data) => {
  return apiClient.post(
    endpoint + route_transaction_required_approvals,
    JSON.stringify({
      ...data,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const setClaimInvoiceServiceOverrideExclusion = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  overrideExclusion
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      act_set_override_exclusion +
      "/" +
      overrideExclusion
  );
};

const setClaimInvoiceServiceDetailOverrideExclusion = (
  claimID,
  claimInvoiceID,
  claimInvoiceServiceID,
  claimInvoiceServiceDetailID,
  overrideExclusion
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimID +
      route_claim_invoice +
      "/" +
      claimInvoiceID +
      route_claim_invoice_service +
      "/" +
      claimInvoiceServiceID +
      route_claim_invoice_service_detail +
      "/" +
      claimInvoiceServiceDetailID +
      act_set_override_exclusion +
      "/" +
      overrideExclusion
  );
};

const getTransactionRequiresApprovalReasons = (searchCriteria, paging) => {
  return apiClient.post(
    endpoint + route_transaction_requires_approval_reasons,
    JSON.stringify({
      ...paging,
      ...searchCriteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const addTransactionRequiresApprovalReason = (data) => {
  return apiClient.post(
    endpoint + route_transaction_requires_approval_reasons,
    JSON.stringify({
      ...data,
    })
  );
};

const updateTransactionRequiresApprovalReason = (
  transactionRequiresApprovalReasonID,
  data
) => {
  return apiClient.put(
    endpoint +
      route_transaction_requires_approval_reasons +
      "/" +
      transactionRequiresApprovalReasonID,
    JSON.stringify({
      ...data,
    })
  );
};

const deleteTransactionRequiresApprovalReason = (
  transactionRequiresApprovalReasonID
) => {
  return apiClient.delete(
    endpoint +
      route_transaction_requires_approval_reasons +
      "/" +
      transactionRequiresApprovalReasonID
  );
};

const getScannedClaims = (criteria) => {
  return apiClient.post(
    endpoint + act_get_scanned_claims_attachments,
    JSON.stringify({
      ...criteria,
    }),
    {
      headers: { "X-HTTP-Method-Override": "GET" },
    }
  );
};

const downloadClaimDistributionReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_claim_distribution_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadClaimFormDetailsClientCopyReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_claim_form_details_client_copy_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadMedicalTransactionsServiceLevelReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_medical_transactions_service_level_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const addClaimFormServiceAuditComment = (
  claimFormID,
  claimFormServiceID,
  comment
) => {
  return apiClient.post(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      act_claim_form_service_audit_comment,
    JSON.stringify({
      Comment: comment,
    })
  );
};

const updateClaimFormServiceAuditComment = (
  claimFormID,
  claimFormServiceID,
  claimFormServiceAuditCommentID,
  comment
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      route_claim_form_service_audit_comment +
      "/" +
      claimFormServiceAuditCommentID +
      act_update_claim_form_service_audit_comment,
    JSON.stringify({
      Comment: comment,
    })
  );
};

const deleteClaimFormServiceAuditComment = (
  claimFormID,
  claimFormServiceID,
  claimFormServiceAuditCommentID
) => {
  return apiClient.put(
    endpoint +
      "/" +
      claimFormID +
      route_claim_form_service +
      "/" +
      claimFormServiceID +
      route_claim_form_service_audit_comment +
      "/" +
      claimFormServiceAuditCommentID +
      act_delete_claim_form_service_audit_comment
  );
};

const downloadMedicalTransactionsReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_medical_transactions_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadUtilizationPayerLevelReport = (criteria) => {
  return apiClient.post(
    endpoint + act_download_utilization_payer_level_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      "Content-Type": "text/html",
      Accept: "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const claimsApi = {
  getClaimTypes,
  getInsuredReimbursementClaimFacts,
  getProviderClaimFacts,
  getClaimFact,
  getClaimFormTypes,
  downloadClaimDetailsServiceLevelReport,
  downloadClaimDetailsServiceLevelSystemUserReport,
  getClaimForms,
  getClaimForm,
  getClaimFormComments,
  getClaimFormAdjustmentHistoryList,
  getClaim,
  getClaimInvoiceTaxes,
  getClaimFormAttachments,
  addClaimFormAttachment,
  deleteClaimFormAttachment,
  getClaimInvoices,
  getClaimBookletProviderInfo,
  createNewClaimForm,
  checkinClaimForm,
  checkoutClaimForm,
  setClaimFormProviderAmountAsPriceList,
  setAccidentDate,
  setClaimFormPrescriptionCode,
  clearClaimFormPrescriptionCode,
  setReimbursement,
  setIgnorePrescriptionCode,
  setIgnoreOldClaims,
  setAdmissionDate,
  setMainOperation,
  setOriginalsReceived,
  setOverrideOriginalsReceivedRule,
  setIgnoreApprovalExpiryDate,
  setOverridePolicyExpirationPeriod,
  setProvider,
  setTreatmentDoctor,
  removeTreatmentDoctor,
  setConsultationDate,
  setPhysician,
  removePhysician,
  addClaimFormDiagnosis,
  removeClaimFormDiagnosis,
  setClaimFormDiagnosisPreexisting,
  setClaimFormDiagnosisChronic,
  setPrimaryClaimFormDiagnosis,
  addClaimFormService,
  removeClaimFormService,
  attachClaimFormServiceToDiagnosis,
  setClaimFormServiceRequestedQuantities,
  setClaimFormServiceAcceptedQuantities,
  setClaimFormServiceRequestedAmount,
  setClaimFormServiceToothNumber,
  setClaimFormServiceProviderClaimedAmount,
  setClaimFormServiceUserPrice,
  setOverrideServiceWaitingPeriod,
  getClaimFormServiceAlternateBenefits,
  setClaimFormServicePrimaryBenefit,
  rejectClaimFormService,
  acceptClaimFormService,
  refreshClaimFormBenefits,
  deleteClaimForm,
  postClaimForm,
  unpostClaimForm,
  rejectClaimForm,
  rollbackRejectClaimForm,
  recalculateClaimForm,
  resetClaimFormServicesClaimedAmountAsRequested,
  setClaimFormServicesAcceptedAmountAsRequested,
  resetClaimFormServiceRequestedAmount,
  deleteClaimInvoice,
  settleClaimInvoice,
  unsettleClaimInvoice,
  setClaimInvoiceDebit,
  setClaimInvoiceAcceptPendingAmountAsDebitAutomatically,
  setClaimInvoiceDeductClaimDifferenceCopayment,
  setClaimInvoiceDiscountOnCoverageAmount,
  setClaimInvoiceOverrideReimbursementBenchmark,
  setClaimInvoiceWaivedDebitAdminCost,
  removeClaimInvoiceWaivedDebitAdminCost,
  setClaimInvoiceAcceptAllPendingAsDebit,
  setClaimInvoiceHoldProviderPayments,
  unsetClaimInvoiceHoldProviderPayments,
  setClaimInvoiceDisregardDeductibleAsDebit,
  unsetClaimInvoiceDisregardDeductibleAsDebit,
  setClaimInvoiceAcceptedAsRequested,
  calculateClaimInvoiceAcceptedAmount,
  acceptClaimInvoiceAsPerApproval,
  adjustClaimInvoice,
  relinkClaimFormServicesToDiagnosis,
  setClaimFormServiceDetailRequestedQuantity,
  setClaimFormServiceDetailAcceptedQuantity,
  setClaimFormServiceDetailRequestedAmount,
  setClaimFormServiceDetailProviderClaimedAmount,
  setClaimFormServiceDetailFollowsMainServicePriceRatio,
  rejectClaimFormServiceDetail,
  acceptClaimFormServiceDetail,
  resetClaimFormServiceDetailRequestedAmount,
  setClaimFormServicePriceRatio,
  setClaimInvoiceServiceAcceptedQuantities,
  setClaimInvoiceServiceAcceptedAmount,
  setClaimInvoiceServiceDebit,
  setClaimInvoiceCoveragePercentage,
  setClaimInvoiceDiscountPercentage,
  setClaimInvoiceServiceCoveragePercentage,
  setClaimInvoiceServiceDiscountPercentage,
  setClaimInvoiceServiceWithholdPayment,
  setClaimInvoiceServiceWithheldQuantities,
  setClaimInvoiceServiceWithheldAmount,
  setClaimInvoiceServiceDetailAcceptedQuantities,
  setClaimInvoiceServiceDetailAcceptedAmount,
  setClaimInvoiceServiceDetailDebit,
  setClaimInvoiceServiceDetailCoveragePercentage,
  setClaimInvoiceServiceDetailDiscountPercentage,
  getClaimInvoiceCompatibleAlternatePolicyInsuredBenefits,
  acceptClaimInvoiceServiceDetailPendingAmount,
  acceptClaimInvoiceServiceDetailPendingAmountAsDebit,
  autoApplyClaimFormBatchExamination,
  getClaimFormDetails,
  forceCheckinClaimForm,
  downloadClaimsExtractedFromFundReport,
  setClaimFormClaimReason,
  getClaiminvoiceServiceExclusionDetails,
  getClaiminvoiceServiceDetailExclusionDetails,
  updateClaimFormProviderEstimateAmount,
  setOverrideReferralAlert,
  getTransmittalClaimRelatedServices,
  importTransmittalClaimFormService,
  downloadClaimInvoiceSettlementReport,
  downloadClaimDetailsReport,
  downloadClaimDetailsBenefitLevelReport,
  downloadClaimPaymentDetailsReport,
  downloadClaimStateDetailsReport,
  overrideClaimFormServicePriceUpdateRequest,
  addClaimFormType,
  getClaimCategories,
  deleteClaimFormType,
  updateClaimFormType,
  deleteClaimType,
  addClaimType,
  updateClaimType,
  getClaimType,
  getBookletTypes,
  addBookletType,
  deleteBookletType,
  updateBookletType,
  getClaimTypeServices,
  addClaimTypeService,
  deleteClaimTypeService,
  setClaimTypeEnforceIsPreliminary,
  setClaimTypeEnforceMainOperation,
  setClaimTypeEnableReimbursementRequest,
  getClaimBooklets,
  getClaimBooklet,
  addClaimBooklet,
  deleteClaimBooklet,
  checkoutClaimBooklet,
  checkinClaimBooklet,
  forceCheckinClaimBooklet,
  deliverClaimBooklet,
  printClaimBooklet,
  removePrintClaimBooklet,
  removeDeliveryClaimBooklet,
  downloadClaimBookletReceiptReport,
  assignClaimBookletToProvider,
  getSyndicateShares,
  addSyndicateShare,
  deleteSyndicateShare,
  cancelSyndicateShare,
  reinstateSyndicateShare,
  updateSyndicateShare,
  setClaimInvoiceServiceUserComment,
  setClaimInvoiceServiceDetailUserComment,
  checkConsultationEligibility,
  downloadClaimSummaryReport,
  downloadPolicyUtilizationReport,
  downloadUtilizationReport,
  downloadUtilizationClaimLevelReport,
  downloadOutstandingClaimsPaymentReport,
  downloadOutstandingClaimsReport,
  downloadClaimFormDetailsReportCompact,
  downloadClaimFormDetailsReport,
  downloadDiagnosticTestClaimsSummaryReport,
  downloadDiagnosticTestClaimsDetailsReport,
  downloadClaimsPerformanceReport,
  addClaimFormComment,
  deleteClaimFormComment,
  setOverrideThirdPartyApprovalRequirements,
  setThirdPartyApprovalReceived,
  setClaimFormClaimFormType,
  getTransactionRequiredApprovals,
  setClaimInvoiceServiceOverrideExclusion,
  setClaimInvoiceServiceDetailOverrideExclusion,
  getTransactionRequiresApprovalReasons,
  addTransactionRequiresApprovalReason,
  updateTransactionRequiresApprovalReason,
  deleteTransactionRequiresApprovalReason,
  getScannedClaims,
  downloadClaimDistributionReport,
  downloadClaimFormDetailsClientCopyReport,
  downloadMedicalTransactionsServiceLevelReport,
  addClaimFormServiceAuditComment,
  updateClaimFormServiceAuditComment,
  deleteClaimFormServiceAuditComment,
  downloadMedicalTransactionsReport,
  downloadUtilizationPayerLevelReport,
};

export default claimsApi;
