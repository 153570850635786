/** @format */

import baseStrings from "../common/base/baseStrings";

const CPresentationConstantsHandler = {
  ...baseStrings,
  PROVIDER: "PROVIDER",
  PROVIDER_SPECIALTY: "PROVIDER_SPECIALTY",
  PROVIDER_TYPE: "PROVIDER_TYPE",
  TREATMENT_DOCTOR: "TREATMENT_DOCTOR",
  DOCTOR_SPECIALTY: "DOCTOR_SPECIALTY",
  INSURED: "INSURED",
  CARD_NUMBER: "CARD_NUMBER",
  APPROVAL_STATUS: "APPROVAL_STATUS",
  APPROVAL_DATE: "APPROVAL_DATE",
  REQUEST_DATE: "REQUEST_DATE",
  ACCIDENT_DATE: "ACCIDENT_DATE",
  PROCESSED_BY: "PROCESSED_BY",
  APPROVAL_TYPE: "APPROVAL_TYPE",
  APPROVAL_CATEGORY: "APPROVAL_CATEGORY",
  REJECTION_REASON: "REJECTION_REASON",
  REJECTION_COMMENT: "REJECTION_COMMENT",
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  LATEST: "LATEST",
  CHRONIC: "CHRONIC",
  REGULAR: "REGULAR",
  ACUTE: "ACUTE",
  PREEXISTING: "PREEXISTING",
  CLAIM_FORM_STATUS: "CLAIM_FORM_STATUS",
  CLAIM_FORM_TYPE: "CLAIM_FORM_TYPE",
  POLICY_HOLDER: "POLICY_HOLDER",
  POLICY_NUMBER: "POLICY_NUMBER",
  POLICY_END_DATE: "POLICY_END_DATE",
  ALIAS_POLICY_NUMBER: "ALIAS_POLICY_NUMBER",
  INSURED_BRANCH: "INSURED_BRANCH",
  REQUESTED_AMOUNT: "REQUESTED_AMOUNT",
  REQUESTED_QUANTITY: "REQUESTED_QUANTITY",
  ACCEPTED_AMOUNT: "ACCEPTED_AMOUNT",
  ACCEPTED_QUANTITY: "ACCEPTED_QUANTITY",
  PROCESSING_TIME: "PROCESSING_TIME",
  SEARCH_APPROVALS: "SEARCH_APPROVALS",
  SERVICE_NAME: "SERVICE_NAME",
  SERVICE_CODE: "SERVICE_CODE",
  APPROVALS: "APPROVALS",
  POTENCY: "POTENCY",
  PACK_SIZE: "PACK_SIZE",
  SERVICE_PRICE: "SERVICE_PRICE",
  DIAGNOSIS_NAME: "DIAGNOSIS_NAME",
  DIAGNOSIS_CODE: "DIAGNOSIS_CODE",
  SERVICES: "SERVICES",
  DIAGNOSES: "DIAGNOSES",
  TPA_REFERENCE_NO: "TPA_REFERENCE_NO",
  INSURANCE_COMPANIES: "INSURANCE_COMPANIES",
  IS_ACTIVE: "IS_ACTIVE",
  IS_RETENTION: "IS_RETENTION",
  GENERATE_INVOICE_BY_CLIENT: "GENERATE_INVOICE_BY_CLIENT",
  APPROVAL_REQUEST: "APPROVAL_REQUEST",
  CLAIM_TYPE: "CLAIM_TYPE",
  CITY: "CITY",
  LOCATION: "LOCATION",
  EXPECTED_SERVICE_DATE: "EXPECTED_SERVICE_DATE",
  DESCRIPTION: "DESCRIPTION",
  PLAN_COVERAGE: "PLAN_COVERAGE",
  DELIVERY_DATE: "DELIVERY_DATE",
  EXPECTED_SERVICE_DELIVERY_DATE: "EXPECTED_SERVICE_DELIVERY_DATE",
  APPROVAL_REQUESTS: "APPROVAL_REQUESTS",
  REVISION_DETAILS: "REVISION_DETAILS",
  ADD_REQUEST: "ADD_REQUEST",
  VIEW: "VIEW",
  LOG_IN: "LOG_IN",
  DETAILS: "DETAILS",
  PENDING_APPROVALS: "PENDING_APPROVALS",
  ACCEPT_PENDING_AS_DEBIT: "ACCEPT_PENDING_AS_DEBIT",
  USER_TYPE: "USER_TYPE",
  ACTIVATION_PASSWORD: "ACTIVATION_PASSWORD",
  AUTHENTICATION_PASSWORD: "AUTHENTICATION_PASSWORD",
  CONFIRMATION_PASSWORD: "CONFIRMATION_PASSWORD",
  CARD_NO: "CARD_NO",
  PROVIDER_NAME: "PROVIDER_NAME",
  PRESCRIPTION_CODE: "PRESCRIPTION_CODE",
  REIMBURSEMENT: "REIMBURSEMENT",
  DEBIT: "DEBIT",
  PRE_AUTHORIZATION: "PRE_AUTHORIZATION",
  APPROVAL: "APPROVAL",
  NEW_APPROVAL: "NEW_APPROVAL",
  ACTIVATION_CODE: "ACTIVATION_CODE",
  USE_DEFAULT_SETTINGS: "USE_DEFAULT_SETTINGS",
  CHRONIC_FORM: "CHRONIC_FORM",
  BASE_APPROVAL: "BASE_APPROVAL",
  OVERRIDE_PRE_AUTHORIZATION: "OVERRIDE_PRE_AUTHORIZATION",
  CARD_INFO: "CARD_INFO",
  MISSING_EIDT_FORM: "MISSING_EIDT_FORM",
  MISSING_ADD_FORM: "MISSING_ADD_FORM",
  HOME: "HOME",
  ONLINE_APPROVALS: "ONLINE_APPROVALS",
  REQUESTS: "REQUESTS",
  CHRONIC_FORMS: "CHRONIC_FORMS",
  ISSUANCE: "ISSUANCE",
  ISSUANCE_ITEMS: "ISSUANCE_ITEMS",
  BILLING: "BILLING",
  REPORTS: "REPORTS",
  BENEFICIARY: "BENEFICIARY",
  Name: "NAME",
  MOBILE: "MOBILE",
  POLICY: "POLICY",
  ISSUANCE_DATE: "ISSUANCE_DATE",
  TYPE: "TYPE",
  MEDICAL_CASE: "MEDICAL_CASE",
  QAPASSED: "QAPASSED",
  REVIEWED: "REVIEWED",
  APPROVED: "APPROVED",
  IS_VIP: "IS_VIP",
  SUPERVISION: "SUPERVISION",
  IDENTITY: "IDENTITY",
  GENDER: "GENDER",
  DATE_OF_BIRTH: "DATE_OF_BIRTH",
  AGE: "AGE",
  JOIN_DATE: "JOIN_DATE",
  RENEWAL_JOIN_DATE: "RENEWAL_JOIN_DATE",
  INITIAL_JOIN_DATE: "INITIAL_JOIN_DATE",
  START_DATE: "START_DATE",
  END_DATE: "END_DATE",
  PAYER: "PAYER",
  POLICY_EXPIRED: "POLICY_EXPIRED",
  OVERRIDE_POLICY_EXPIRATION: "OVERRIDE_POLICY_EXPIRATION",
  OVERRIDE_EXPIRATION: "OVERRIDE_EXPIRATION",
  OVERRIDDEN_BY: "OVERRIDDEN_BY",
  EXPIRY_OVERRIDDEN_BY: "EXPIRY_OVERRIDDEN_BY",
  VIP: "VIP",
  UNDER_SUPERVISION: "UNDER_SUPERVISION",
  EXPIRED: "EXPIRED",
  PROVIDER_NUMBER: "PROVIDER_NUMBER",
  PROVIDER_CODE: "PROVIDER_CODE",
  TELEPHONE: "TELEPHONE",
  DETAILED_ADDRESS: "DETAILED_ADDRESS",
  MAIN_BRANCH: "MAIN_BRANCH",
  IS_VIRTUAL: "IS_VIRTUAL",
  IN_PREAUTHORIZATION_NETWORK: "IN_PREAUTHORIZATION_NETWORK",
  HOTLINE: "HOTLINE",
  VIRTUAL: "VIRTUAL",
  POLICY_ID: "POLICY_ID",
  PHYSICIAN: "PHYSICIAN",
  SPECIALTY: "SPECIALTY",
  NUMBER: "NUMBER",
  PRICING_SCHEME: "PRICING_SCHEME",

  CATEGORY: "CATEGORY",
  SEGREGATION_CODE: "SEGREGATION_CODE",
  EXTERNAL_REQUEST_ID: "EXTERNAL_REQUEST_ID",
  ADMISSION_DATE: "ADMISSION_DATE",
  DISCHARGE_DATE: "DISCHARGE_DATE",
  DAYS_IN: "DAYS_IN",
  MAIN_OPERATION: "MAIN_OPERATION",
  CHRONIC_FORM_ID: "CHRONIC_FORM_ID",
  CHRONIC_DISPENSATION: "CHRONIC_DISPENSATION",
  DISPENSATION_PERIOD: "DISPENSATION_PERIOD",
  CHRONIC_DISPENSATION_FROM: "CHRONIC_DISPENSATION_FROM",
  CHRONIC_DISPENSATION_TO: "CHRONIC_DISPENSATION_TO",
  AUTHORIZE_ALL_BRANCHES: "AUTHORIZE_ALL_BRANCHES",
  OVERRIDE_PREAUTHORIZATION_NETWORK: "OVERRIDE_PREAUTHORIZATION_NETWORK",
  HAS_DEBIT: "HAS_DEBIT",
  DEBIT_REASON: "DEBIT_REASON",
  CASE_TYPE: "CASE_TYPE",
  PRE_EXISTING: "PRE_EXISTING",
  PRESCRIPTION: "PRESCRIPTION",
  EPRESCRIPTION_ID: "EPRESCRIPTION_ID",
  CONSULTATION_DATE: "CONSULTATION_DATE",
  PRESCRIPTION_MANDATORY: "PRESCRIPTION_MANDATORY",
  PROCESSING_INFO: "PROCESSING_INFO",
  ADDED_BY: "ADDED_BY",
  PROCESSING_DATE: "PROCESSING_DATE",
  CLAIM_FORM_ID: "CLAIM_FORM_ID",
  AUTHORIZATION_DATE: "AUTHORIZATION_DATE",
  AUTHORIZED_BY: "AUTHORIZED_BY",
  VALID_TILL: "VALID_TILL",
  CANCELLATION_DATE: "CANCELLATION_DATE",
  CANCELLED_BY: "CANCELLED_BY",
  REPRICE_ON_DISPENSATION: "REPRICE_ON_DISPENSATION",
  IS_PRELIMINARY: "IS_PRELIMINARY",
  DISCOUNT_ON_COVERAGE: "DISCOUNT_ON_COVERAGE",
  HR_APPROVAL_REQUIRED: "HR_APPROVAL_REQUIRED",
  SEND_SMS: "SEND_SMS",
  FOLLOWUP_REASON: "FOLLOWUP_REASON",
  INPATIENT: "INPATIENT",
  HR_APPROVAL_RECEIVED: "HR_APPROVAL_RECEIVED",
  SETTLE: "SETTLE",
  ACCEPT: "ACCEPT",
  REJECT: "REJECT",
  AUTHORIZE: "AUTHORIZE",
  CLAIM: "CLAIM",
  INVOICE: "INVOICE",
  CASE: "CASE",
  ATTACHMENTS: "ATTACHMENTS",

  DECLARED_AS_PREEXISTING: "DECLARED_AS_PREEXISTING",
  PRIMARY: "PRIMARY",
  SYSTEM_COMMENT: "SYSTEM_COMMENT",
  LOCAL_NAME: "LOCAL_NAME",
  FOREIGN_NAME: "FOREIGN_NAME",
  IS_ONLINE: "IS_ONLINE",
  SUB_PRODUCT: "SUB_PRODUCT",
  BENEFIT: "BENEFIT",
  DIAGNOSIS: "DIAGNOSIS",
  DOSE_PER_DAY: "DOSE_PER_DAY",
  DOSE_COUNT_PER_DAY: "DOSE_COUNT_PER_DAY",
  NUMBER_OF_DAYS: "NUMBER_OF_DAYS",
  REQUESTED_SUB_PACKS_QUANTITY: "REQUESTED_SUB_PACKS_QUANTITY",
  ACCEPTED_SUB_PACKS_QUANTITY: "ACCEPTED_SUB_PACKS_QUANTITY",
  TOOTH_NUMBER: "TOOTH_NUMBER",
  COVERAGE_PERCENTAGE: "COVERAGE_PERCENTAGE",
  IS_MEDICINE: "IS_MEDICINE",
  BARCODE: "BARCODE",
  DOSE_UNIT: "DOSE_UNIT",
  COMMENTS: "COMMENTS",
  IN_PATIENT: "IN_PATIENT",
  EVALUATION: "EVALUATION",
  MEDICAL_FILE: "MEDICAL_FILE",
  INSURED_HISTORY: "INSURED_HISTORY",
  DISCOUNT_PERCENTAGE: "DISCOUNT_PERCENTAGE",
  APPROVAL_IS_REIMBURSEMENT: "APPROVAL_IS_REIMBURSEMENT",
  APPROVAL_IS_SET_AS_DEBIT: "APPROVAL_IS_SET_AS_DEBIT",
  DELETED_APPROVAL_CAN_NOT_BE_EDITED: "DELETED_APPROVAL_CAN_NOT_BE_EDITED",
  DIAGNOSIS_IS_CANCELED: "DIAGNOSIS_IS_CANCELED",
  RE_ACCEPT: "RE_ACCEPT",
  REINSTATE: "REINSTATE",
  REPLACE: "REPLACE",
  OVERRIDE_WAITING_PERIOD: "OVERRIDE_WAITING_PERIOD",
  OVERRIDE_REJECTION_COMMENT: "OVERRIDE_REJECTION_COMMENT",
  UNSET_OVERRIDE_REJECTION_COMMENT: "UNSET_OVERRIDE_REJECTION_COMMENT",
  DUE_AMOUNT: "DUE_AMOUNT",
  INSURANCE_SHARE_AMOUNT: "INSURANCE_SHARE_AMOUNT",
  INSURED_SHARE_AMOUNT: "INSURED_SHARE_AMOUNT",
  COVERAGE_AMOUNT: "COVERAGE_AMOUNT",
  DEDUCTIBLE_AMOUNT: "DEDUCTIBLE_AMOUNT",
  DEBIT_AMOUNT: "DEBIT_AMOUNT",
  EXCLUSION_AMOUNT: "EXCLUSION_AMOUNT",
  AMOUNTS: "AMOUNTS",
  BENEFITS: "BENEFITS",
  HISTORY: "HISTORY",
  SMS_NOTIFICATIONS: "SMS_NOTIFICATIONS",
  CREATED_BY: "CREATED_BY",
  CREATION_DATE: "CREATION_DATE",
  ATTACHMENT_TYPE: "ATTACHMENT_TYPE",

  DISTANCE_TO_USER_LOCATION: "DISTANCE_TO_USER_LOCATION",
  SPECIALIZED_CODE: "SPECIALIZED_CODE",
  UNSETTLE: "UNSETTLE",
  RECALCULATE: "RECALCULATE",
  RECALL: "RECALL",
  RETURN: "RETURN",
  OVERRIDE_SUPERVISION_COMMENT: "OVERRIDE_SUPERVISION_COMMENT",
  ACCEPT_APPROVAL: "ACCEPT_APPROVAL",
  RECALCULATE_APPROVAL: "RECALCULATE_APPROVAL",
  PENDING_AMOUNT_ALERT: "PENDING_AMOUNT_ALERT",
  REJECT_APPROVAL: "REJECT_APPROVAL",
  SURE_TO_AUTHORIZE: "SURE_TO_AUTHORIZE",
  APPROVAL_ACCIDENT_DATE_IS: "APPROVAL_ACCIDENT_DATE_IS",
  RETURN_APPROVAL: "RETURN_APPROVAL",
  RECALL_APPROVAL: "RECALL_APPROVAL",
  REASON: "REASON",
  CANCEL_APPROVAL: "CANCEL_APPROVAL",
  SET_TREATMENT_DOCTOR: "SET_TREATMENT_DOCTOR",
  SET_PHYSICIAN: "SET_PHYSICIAN",
  FOLLOWUP: "FOLLOWUP",
  FOLLOWUP_APPROVAL: "FOLLOWUP_APPROVAL",
  REFRESH_PRICING_SCHEME: "REFRESH_PRICING_SCHEME",
  SET_ACCEPTED_TO_REQUESTED_QUANTITY: "SET_ACCEPTED_TO_REQUESTED_QUANTITY",
  EXTEND_VALIDITY: "EXTEND_VALIDITY",
  OVERRIDE_REIMBURSEMENT_BENCHMARK: "OVERRIDE_REIMBURSEMENT_BENCHMARK",
  PRICING: "PRICING",
  PRINT: "PRINT",
  SYSTEM_RECOMMENDATION: "SYSTEM_RECOMMENDATION",

  REQUIRES_CHRONIC_FORM: "REQUIRES_CHRONIC_FORM",
  REQUIRES_APPROVAL: "REQUIRES_APPROVAL",
  REQUIRES_APPROVAL_REASON: "REQUIRES_APPROVAL_REASON",

  RECURRENCE_ALERT: "RECURRENCE_ALERT",
  PERIOD_SINCE_LAST_EPISODE: "PERIOD_SINCE_LAST_EPISODE",
  NUMBER_OF_EPISODES_PER_PERIOD: "NUMBER_OF_EPISODES_PER_PERIOD",
  NUMBER_OF_EPISODES_LAST_YEAR: "NUMBER_OF_EPISODES_LAST_YEAR",

  DIAGNOSIS_CATEGORY: "DIAGNOSIS_CATEGORY",
  DIAGNOSIS_CATEGORY_RECURRENCE_ALERT: "DIAGNOSIS_CATEGORY_RECURRENCE_ALERT",

  ALERT_INFO: "ALERT_INFO",
  RECURRENCE_INFO: "RECURRENCE_INFO",

  EXCLUSION_INFO: "EXCLUSION_INFO",
  EXCLUSION: "EXCLUSION",
  FULL_EXCLUSION: "FULL_EXCLUSION",
  DIAGNOSIS_CHAPTER: "DIAGNOSIS_CHAPTER",
  DIAGNOSIS_BLOCK: "DIAGNOSIS_BLOCK",
  SERVICE: "SERVICE",
  SERVICE_TYPE: "SERVICE_TYPE",
  SERVICE_CATEGORY: "SERVICE_CATEGORY",
  SERVICE_GROUP: "SERVICE_GROUP",
  SERVICE_CHAPTER: "SERVICE_CHAPTER",
  EXCLUDED_FROM_MONTH: "EXCLUDED_FROM_MONTH",
  EXCLUDED_TO_MONTH: "EXCLUDED_TO_MONTH",

  POLICY_EXCLUSION: "POLICY_EXCLUSION",
  PRODUCT: "PRODUCT",
  COVERAGE_PLAN: "COVERAGE_PLAN",
  PROVIDER_GROUP: "PROVIDER_GROUP",
  CLASS: "CLASS",
  NUMBER_OF_BENEFICIARIES: "NUMBER_OF_BENEFICIARIES",
  INSURED_JOINED_AFTER_ISSUANCE: "INSURED_JOINED_AFTER_ISSUANCE",

  MAXIMUM_COVERAGE_AMOUNT_PER_CLAIM: "MAXIMUM_COVERAGE_AMOUNT_PER_CLAIM",
  NUMBER_OF_CLAIMS_PER_INSURED: "NUMBER_OF_CLAIMS_PER_INSURED",
  MAXIMUM_COVERAGE_AMOUNT_PER_INSURED: "MAXIMUM_COVERAGE_AMOUNT_PER_INSURED",
  CUSTOM_DEDUCTIBLE: "CUSTOM_DEDUCTIBLE",
  DEDUCTIBLE_PERCENTAGE: "DEDUCTIBLE_PERCENTAGE",
  COMMENT_TO_PRINT: "COMMENT_TO_PRINT",
  APPROVAL_COMMENT: "APPROVAL_COMMENT",

  IS_LATEST: "IS_LATEST",
  IS_LATEST_WITHIN_RENEWAL: "IS_LATEST_WITHIN_RENEWAL",
  GENERATED_DURING_CURRENT_REVISION: "GENERATED_DURING_CURRENT_REVISION",
  VALID_FROM: "VALID_FROM",
  VALID_TO: "VALID_TO",
  INSERTED_BY: "INSERTED_BY",
  INSERTION_DATE: "INSERTION_DATE",
  POLICY_EXCLUSION_INFO: "POLICY_EXCLUSION_INFO",
  POLICY_INSURED_EXCLUSION_INFO: "POLICY_INSURED_EXCLUSION_INFO",
  FROM_AGE: "FROM_AGE",
  TO_AGE: "TO_AGE",
  SERVICE_EXCLUSION: "SERVICE_EXCLUSION",
  DIAGNOSIS_EXCLUSION: "DIAGNOSIS_EXCLUSION",
  IS_PRIMARY: "IS_PRIMARY",
  FUND: "FUND",
  ALTERNATE_BENEFITS: "ALTERNATE_BENEFITS",
  AMOUNT_LIMIT: "AMOUNT_LIMIT",
  EPISODE_UTILIZATION: "EPISODE_UTILIZATION",
  COUNT_LIMIT: "COUNT_LIMIT",
  IS_COVERED: "IS_COVERED",
  COVERED: "COVERED",
  IS_EXCEPTION: "IS_EXCEPTION",
  EXCEPTION_REASON: "EXCEPTION_REASON",
  RESET_AMOUNTS: "RESET_AMOUNTS",
  PRESC_CODE: "PRESC_CODE",
  PREEXISTING_ALERT: "PREEXISTING_ALERT",
  WAITING_PERIOD: "WAITING_PERIOD",
  WAITING_PERIOD_SHORT: "WAITING_PERIOD_SHORT",
  INSURED_NAME: "INSURED_NAME",
  RETURN_COMMENT: "RETURN_COMMENT",
  REQUESTED_DATE: "REQUESTED_DATE",
  AWAITING_PROVIDER_CHANGES: "AWAITING_PROVIDER_CHANGES",
  ASSIGNED_TO: "ASSIGNED_TO",
  APPROVAL_DIAGNOSIS_RECURRENCE: "APPROVAL_DIAGNOSIS_RECURRENCE",
  EVALUATIONS: "EVALUATIONS",
  VIP_REASON: "VIP_REASON",
  SUPERVISION_REASON: "SUPERVISION_REASON",
  SERVICE_DETAILS: "SERVICE_DETAILS",
  LAST_OCCURRENCE_DATE: "LAST_OCCURRENCE_DATE",

  FLAGGED_FOR_FULL_REJECTION: "FLAGGED_FOR_FULL_REJECTION",
  FLAGGED_FOR_PARTIAL_REJECTION: "FLAGGED_FOR_PARTIAL_REJECTION",
  REJECTION_INFO: "REJECTION_INFO",
  QUANTITY: "QUANTITY",
  SERVICE_QUANTITY_ALERT: "SERVICE_QUANTITY_ALERT",
  MAXIMUM_QUANTITY_PER_EPISODE: "MAXIMUM_QUANTITY_PER_EPISODE",
  MAXIMUM_QUANTITY_WITHIN_CONTROL_PERIOD:
    "MAXIMUM_QUANTITY_WITHIN_CONTROL_PERIOD",
  PREVIOUSLY_DISPENSED_QUANTITY: "PREVIOUSLY_DISPENSED_QUANTITY",
  MAXIMUM_QUANTITY_PER_YEAR: "MAXIMUM_QUANTITY_PER_YEAR",
  DISPENSED_QUANTITY_PER_YEAR: "DISPENSED_QUANTITY_PER_YEAR",
  REMAINING_QUANTITY: "REMAINING_QUANTITY",
  CONTROL_PERIOD_COMMENT: "CONTROL_PERIOD_COMMENT",

  NUMBER_OF_EPISODES_WITHIN_WARNING_PERIOD:
    "NUMBER_OF_EPISODES_WITHIN_WARNING_PERIOD",
  SERVICE_RECURRENCE_ALERT: "SERVICE_RECURRENCE_ALERT",
  SERVICE_TYPE_DETAILS: "SERVICE_TYPE_DETAILS",
  SERVICE_TYPE_RECURRENCE_ALERT: "SERVICE_TYPE_RECURRENCE_ALERT",
  SERVICE_CATEGORY_RECURRENCE_ALERT: "SERVICE_CATEGORY_RECURRENCE_ALERT",
  APPROVAL_SERVICE_RECURRENCE: "APPROVAL_SERVICE_RECURRENCE",
  SEGMENT: "SEGMENT",
  TOTAL_COUNT: "TOTAL_COUNT",
  ACCEPTED_COUNT: "ACCEPTED_COUNT",
  AUTHORIZED_COUNT: "AUTHORIZED_COUNT",
  REJECTED_COUNT: "REJECTED_COUNT",
  AVERAGE_REQUESTED_AMOUNT: "AVERAGE_REQUESTED_AMOUNT",
  REJECTED_AMOUNT: "REJECTED_AMOUNT",
  REJECTION_RATE: "REJECTION_RATE",
  NOT_LINKED_TO_CHRONIC_FORMS: "NOT_LINKED_TO_CHRONIC_FORMS",
  LINKED_TO_CHRONIC_FORMS: "LINKED_TO_CHRONIC_FORMS",
  MONTHLY_APPROVAL_COUNT: "MONTHLY_APPROVAL_COUNT",
  MONTHLY_APPROVAL_REJECTION_RATE: "MONTHLY_APPROVAL_REJECTION_RATE",
  MONTH: "MONTH",
  OVERVIEW: "OVERVIEW",
  OVERVIEW_DETAILS: "OVERVIEW_DETAILS",
  INSURANCE_TYPE: "INSURANCE_TYPE",
  REVISION_ID: "REVISION_ID",
  REVISION_NUMBER: "REVISION_NUMBER",
  INSURANCE_COMPANY: "INSURANCE_COMPANY",
  SECTOR_TYPE: "SECTOR_TYPE",
  ENDORSEMENT_TYPE: "ENDORSEMENT_TYPE",
  ENDORSEMENT_DATE: "ENDORSEMENT_DATE",
  TPA_COMPANY: "TPA_COMPANY",
  BRANCH: "BRANCH",
  UNDERWRITING_YEAR: "UNDERWRITING_YEAR",
  IS_GR: "IS_GR",
  CANCELLATION_REASON: "CANCELLATION_REASON",
  CANCELLATION_COMMENT: "CANCELLATION_COMMENT",
  POLICIES: "POLICIES",
  REMAINING_AMOUNT: "REMAINING_AMOUNT",
  SELECT: "SELECT",
  DIAGNOSIS_DISPENSATION_ALERT: "DIAGNOSIS_DISPENSATION_ALERT",
  DISPENSATION_RULE: "DISPENSATION_RULE",
  NEEDS_APPROVAL: "NEEDS_APPROVAL",
  SERVICE_DISPENSATION_ALERT: "SERVICE_DISPENSATION_ALERT",
  SUPERVISION_COMMENT: "SUPERVISION_COMMENT",
  REVIEW_RESULT: "REVIEW_RESULT",
  MONTHLY_APPROVAL_AMOUNTS: "MONTHLY_APPROVAL_AMOUNTS",
  DENY_AUTOMATIC_APPROVALS: "DENY_AUTOMATIC_APPROVALS",
  EVALUATE: "EVALUATE",
  COMMENT_TYPE: "COMMENT_TYPE",
  COMMENT_DATE: "COMMENT_DATE",
  USER: "USER",
  REGULAR_COUNT: "REGULAR_COUNT",
  CHRONIC_COUNT: "CHRONIC_COUNT",
  PREEXISTING_COUNT: "PREEXISTING_COUNT",
  CLAIM_TYPE_APPROVAL_FIGURES: "CLAIM_TYPE_APPROVAL_FIGURES",
  CLAIM_TYPE_MONTHLY_APPROVAL_FIGURES: "CLAIM_TYPE_MONTHLY_APPROVAL_FIGURES",
  TD_SPECIALTY: "TD_SPECIALTY",
  TD: "TD",
  REJECTED: "REJECTED",
  TRANSACTION_CODE: "TRANSACTION_CODE",
  TN: "TN",

  DISCOUNT: "DISCOUNT",
  DUE: "DUE",
  INSURANCE_SHARE: "INSURANCE_SHARE",
  INSURED_SHARE: "INSURED_SHARE",
  PENDING_AMOUNT: "PENDING_AMOUNT",
  CANCELATION: "CANCELATION",
  BENCHMARK_PRICE: "BENCHMARK_PRICE",
  BENCHMARK_AMOUNT: "BENCHMARK_AMOUNT",
  FOLLOWS_MAIN_SERVICE_PRICE_RATIO: "FOLLOWS_MAIN_SERVICE_PRICE_RATIO",
  CLAIMED_AMOUNT: "CLAIMED_AMOUNT",
  PROVIDER_SERVICE_PRICE: "PROVIDER_SERVICE_PRICE",
  PRIVATE: "PRIVATE",
  PROTOCOLS: "PROTOCOLS",
  EXCEEDING_LIMIT: "EXCEEDING_LIMIT",
  COMING_SOON: "COMING_SOON",
  WELCOME: "WELCOME",
  ACTIVATE_YOUR_ACCOUNT: "ACTIVATE_YOUR_ACCOUNT",
  DRUGS: "DRUGS",
  FIRST_PRESCRIBED: "FIRST_PRESCRIBED",
  LAST_PRESCRIBED: "LAST_PRESCRIBED",
  DRUG_TYPES: "DRUG_TYPES",
  DRUG_DETAILS: "DRUG_DETAILS",
  FIRST_DIAGNOSED: "FIRST_DIAGNOSED",
  LAST_DIAGNOSED: "LAST_DIAGNOSED",
  MEDICAL_CONDITIONS: "MEDICAL_CONDITIONS",
  SERVICE_NATURES: "SERVICE_NATURES",
  MEDICAL_EPISODES: "MEDICAL_EPISODES",
  YES_ALL: "YES_ALL",
  ONLY_THIS: "ONLY_THIS",
  MESSAGE: "MESSAGE",
  SEND_DATE: "SEND_DATE",
  TRIAL_COUNT: "TRIAL_COUNT",
  RESPONSE_MESSAGE: "RESPONSE_MESSAGE",
  SENT: "SENT",
  MEDICINE_INFORMATION: "MEDICINE_INFORMATION",
  PACKAGING_TYPE: "PACKAGING_TYPE",
  DOSAGE_FORM: "DOSAGE_FORM",
  DEFAULT_PRICE: "DEFAULT_PRICE",
  IMPORTED: "IMPORTED",
  TEST_RESULTS: "TEST_RESULTS",
  TEST_TYPE: "TEST_TYPE",
  TEST_DATE: "TEST_DATE",
  RECEIPT_DATE: "RECEIPT_DATE",
  RECEIVED_DATE: "RECEIVED_DATE",
  RESULT: "RESULT",
  PROVIDER_COMMENT: "PROVIDER_COMMENT",
  DEBIT_COMMENT: "DEBIT_COMMENT",
  CANCELLATION: "CANCELLATION",
  REQUIRES_HR_APPROVAL: "REQUIRES_HR_APPROVAL",
  RETURN_INFO: "RETURN_INFO",
  TRANSACTION_COMMENTS: "TRANSACTION_COMMENTS",
  APPROVAL_SERVICE: "APPROVAL_SERVICE",
  SUB_PACKS: "SUB_PACKS",
  DOSE: "DOSE",
  TRACK_APPROVALS: "TRACK_APPROVALS",
  PROCESSED_APPROVALS: "PROCESSED_APPROVALS",
  READY_PRE_AUTHORIZATIONS: "READY_PRE_AUTHORIZATIONS",
  REQUESTED: "REQUESTED",
  ACCEPTED: "ACCEPTED",
  COVERAGE: "COVERAGE",
  DEDUCTIBLE: "DEDUCTIBLE",
  CHRONIC_FORM_NOT_FOUND: "CHRONIC_FORM_NOT_FOUND",
  AVAILABLE_CHRONIC_FORMS: "AVAILABLE_CHRONIC_FORMS",
  TRACKED_APPROVAL_REQUESTS: "TRACKED_APPROVAL_REQUESTS",
  SEARCH_APPROVAL_REQUESTS: "SEARCH_APPROVAL_REQUESTS",
  REQUEST_REVISION_STATUS: "REQUEST_REVISION_STATUS",
  PRE_AUTHORIZATION_NOT_FOUND: "PRE_AUTHORIZATION_NOT_FOUND",
  DISCARD: "DISCARD",
  NEW_APPROVAL_REQUEST: "NEW_APPROVAL_REQUEST",
  APPLICATION: "APPLICATION",
  REQUEST_MEMO_NUMBER: "REQUEST_MEMO_NUMBER",
  AFFECTED_INSURED: "AFFECTED_INSURED",
  REVISION: "REVISION",
  RESPONSE_RECEIVED_BY_SENDER: "RESPONSE_RECEIVED_BY_SENDER",
  RESPONSE_SENT_TO_SENDER: "RESPONSE_SENT_TO_SENDER",
  ISSUANCE_ITEM: "ISSUANCE_ITEM",
  PLAN: "PLAN",
  NEED_SUBMISSION: "NEED_SUBMISSION",
  PROCESSED_REQUESTS: "PROCESSED_REQUESTS",
  NO_SPECIFIC_PROVIDER: "NO_SPECIFIC_PROVIDER",
  ADD_SERVICE: "ADD_SERVICE",
  CURRENCY: "CURRENCY",
  REQUESTED_BY: "REQUESTED_BY",
  SUBMISSION_DATE: "SUBMISSION_DATE",
  SUBMITTED_BY: "SUBMITTED_BY",
  RESOLUTION_DATE: "RESOLUTION_DATE",
  RESOLVED_BY: "RESOLVED_BY",
  RESPONSE_INFO: "RESPONSE_INFO",
  RESPONSE_MEMO_NUMBER: "RESPONSE_MEMO_NUMBER",
  RESPONSE_DATE: "RESPONSE_DATE",
  SERIAL: "SERIAL",
  FULL_NAME_FOREIGN: "FULL_NAME_FOREIGN",
  FULL_NAME_LOCAL: "FULL_NAME_LOCAL",
  RELATION: "RELATION",
  PRIMARY_CARD: "PRIMARY_CARD",
  WORK_ID: "WORK_ID",
  IMPORT_DETAILS: "IMPORT_DETAILS",
  UPLOAD_PICTURES: "UPLOAD_PICTURES",
  CARD_SUSPENSION_DATE: "CARD_SUSPENSION_DATE",
  CORRECTION_REASON: "CORRECTION_REASON",
  CORRECTED_INFO: "CORRECTED_INFO",
  TRANSFER_TO_BRANCH: "TRANSFER_TO_BRANCH",
  TRANSFER_TO_COVERAGE_PLAN: "TRANSFER_TO_COVERAGE_PLAN",
  REJECTION_TYPE: "REJECTION_TYPE",
  PROCESSING: "PROCESSING",
  RESPONSE: "RESPONSE",
  EXPECTED_EXECUTION_DATE: "EXPECTED_EXECUTION_DATE",
  APPROVAL_REQUEST_CLAIM_TYPE: "APPROVAL_REQUEST_CLAIM_TYPE",
  SEARCH_FREQUENTLY_USED_ONLY: "SEARCH_FREQUENTLY_USED_ONLY",
  FORM_CODE: "FORM_CODE",
  COUNTRY: "COUNTRY",
  UNSUPPORTED_USER_TYPE: "UNSUPPORTED_USER_TYPE",
  CONTACT_ADMIN: "CONTACT_ADMIN",
  CHOOSE: "CHOOSE",
  ENTER: "ENTER",
  CLIENT_NAME: "CLIENT_NAME",
  REQUESTED_SERVICES: "REQUESTED_SERVICES",
  REQUEST_SERVICE: "REQUEST_SERVICE",
  REQUEST_CLAIM_TYPE: "REQUEST_CLAIM_TYPE",
  ISSUANCE_ITEM_DETAIL: "ISSUANCE_ITEM_DETAIL",
  FEEDBACK: "FEEDBACK",
  ERRORS: "ERRORS",
  ORDER: "ORDER",
  INSURED_PICTURE: "INSURED_PICTURE",
  REQUESTED_SERVICE: "REQUESTED_SERVICE",
  REIMBURSEMENT_BATCH: "REIMBURSEMENT_BATCH",
  REIMBURSEMENT_BATCHES: "REIMBURSEMENT_BATCHES",
  REIMBURSEMENT_REQUESTS: "REIMBURSEMENT_REQUESTS",
  REIMBURSEMENT_REQUEST: "REIMBURSEMENT_REQUEST",
  PENDING: "PENDING",
  NEEDS_SUBMISSION: "NEEDS_SUBMISSION",
  TRACK_REIMBURSEMENT_BATCHES: "TRACK_REIMBURSEMENT_BATCHES",
  BATCHES: "BATCHES",
  VIEW_AS: "VIEW_AS",
  EXPECTED_PAYMENT_DATE: "EXPECTED_PAYMENT_DATE",
  N_A: "N_A",
  RECALL_APPROVAL_REQUEST: "RECALL_APPROVAL_REQUEST",
  ROLLBACK_FOLLOW_UP_APPROVAL_REQUEST: "ROLLBACK_FOLLOW_UP_APPROVAL_REQUEST",
  ISSUANCE_ITEM_REVIEW: "ISSUANCE_ITEM_REVIEW",
  ROLLBACK: "ROLLBACK",
  SENDER_COMMENT: "SENDER_COMMENT",
  PROCESSED: "PROCESSED",
  ARE_YOU_SURE_YOU_WANT_TO_RECALL_THIS_ITEM:
    "ARE_YOU_SURE_YOU_WANT_TO_RECALL_THIS_ITEM",
  LATEST_INVOICES: "LATEST_INVOICES",
  INVOICES: "INVOICES",
  ORGANIZATION: "ORGANIZATION",
  INVOICE_TYPE: "INVOICE_TYPE",
  INVOICE_NUMBER: "INVOICE_NUMBER",
  INVOICE_DATE: "INVOICE_DATE",
  INVOICE_DUE_DATE: "INVOICE_DUE_DATE",
  PAID_AMOUNT: "PAID_AMOUNT",
  BALANCE: "BALANCE",
  INVOICE_STATUS: "INVOICE_STATUS",
  IS_MAIN_INVOICE: "IS_MAIN_INVOICE",
  TOTAL_AMOUNT: "TOTAL_AMOUNT",
  TEMPLATE: "TEMPLATE",
  EXPIRY_DATE: "EXPIRY_DATE",
  MEDICAL_CASES: "MEDICAL_CASES",
  DETAILS_COUNT: "DETAILS_COUNT",
  CORRECTION_TYPE: "CORRECTION_TYPE",
  ACCEPTED_DOSE_COUNT: "ACCEPTED_DOSE_COUNT",
  ACCEPTED_DOSE_DAYS_COUNT: "ACCEPTED_DOSE_DAYS_COUNT",
  FIXED_PACK_COUNT_PER_MONTH: "FIXED_PACK_COUNT_PER_MONTH",
  DURATION_MONTHS: "DURATION_MONTHS",
  SESSION_EXPIRED: "SESSION_EXPIRED",
  TICKETS: "TICKETS",
  SUBMITTED_TICKETS: "SUBMITTED_TICKETS",
  AMOUNT_TO_PAY: "AMOUNT_TO_PAY",
  PAYMENT_DATE: "PAYMENT_DATE",
  ACCEPTED_QUANTITY_AND_SUB_PACKS: "ACCEPTED_QUANTITY_AND_SUB_PACKS",
  REQUESTED_QUANTITY_AND_SUB_PACKS: "REQUESTED_QUANTITY_AND_SUB_PACKS",
  TICKET: "TICKET",
  CLIENT: "CLIENT",
  CUSTOMER_SERVICE: "CUSTOMER_SERVICE",
  TRANSFER_DATE: "TRANSFER_DATE",
  SUSPENSION_DATE: "SUSPENSION_DATE",
  RESOLUTION_COMMENT: "RESOLUTION_COMMENT",
  CANCELLED: "CANCELLED",
  ACTIVE: "ACTIVE",
  TRACK_ISSUANCE_ITEMS: "TRACK_ISSUANCE_ITEMS",
  ACCOUNT_SUSPENDED: "ACCOUNT_SUSPENDED",
  TRACKED: "TRACKED",
  PORTAL_REQUESTS: "PORTAL_REQUESTS",
  REQUEST_TYPE: "REQUEST_TYPE",
  REQUEST_RESOLUTION: "REQUEST_RESOLUTION",
  RESOLUTION_DESCRIPTION: "RESOLUTION_DESCRIPTION",
  REQUEST_DESCRIPTION: "REQUEST_DESCRIPTION",
  PROCESSING_DETAILS: "PROCESSING_DETAILS",
  APPROVAL_RETURN_COMMENT: "APPROVAL_RETURN_COMMENT",
  APPROVAL_RETURN_INFO: "APPROVAL_RETURN_INFO",
  APPROVAL_AMOUNTS: "APPROVAL_AMOUNTS",
  APPROVAL_COUNT: "APPROVAL_COUNT",
  SYMPTOMS: "SYMPTOMS",
  PERFORMED_DATE: "PERFORMED_DATE",
  OCCURRENCE_NUMBER: "OCCURRENCE_NUMBER",
  PERFORMED_SERVICES: "PERFORMED_SERVICES",
  PRESCRIPTIONS: "PRESCRIPTIONS",
  TRACK_PRESCRIPTIONS: "TRACK_PRESCRIPTIONS",
  SUBMITTED_PRESCRIPTIONS: "SUBMITTED_PRESCRIPTIONS",
  ONLINE_SUBMISSION: "ONLINE_SUBMISSION",
  TRANSMITTAL: "TRANSMITTAL",
  LATEST_ENDORSEMENT: "LATEST_ENDORSEMENT",
  HAS_NEW_CARD_NUMBER: "HAS_NEW_CARD_NUMBER",
  EXCLUDE_RENEWED: "EXCLUDE_RENEWED",
  INSURED_ADDITION_CRITERIA: "INSURED_ADDITION_CRITERIA",
  ENDORSEMENT_DATE_FROM: "ENDORSEMENT_DATE_FROM",
  ENDORSEMENT_DATE_TO: "ENDORSEMENT_DATE_TO",
  MAIN_ORGANIZATION: "MAIN_ORGANIZATION",
  ORGANIZATION_BRANCH: "ORGANIZATION_BRANCH",
  INSURED_CITY: "INSURED_CITY",
  LANGUAGE: "LANGUAGE",
  ACTIVE_INSURED_COUNT: "ACTIVE_INSURED_COUNT",
  CARDS: "CARDS",
  INSTALLMENTS: "INSTALLMENTS",
  REVISIONS: "REVISIONS",
  YEAR_FIRST_ISSUED: "YEAR_FIRST_ISSUED",
  POLICY_HOLDER_TYPE: "POLICY_HOLDER_TYPE",
  ONLINE_SUBMISSIONS: "ONLINE_SUBMISSIONS",
  TRACK_ONLINE_SUBMISSIONS: "TRACK_ONLINE_SUBMISSIONS",
  SUBMITTED: "SUBMITTED",
  UNDER_PROCESSING_COUNT: "UNDER_PROCESSING_COUNT",
  UNPROCESSED_COUNT: "UNPROCESSED_COUNT",
  UNPROCESSED_AMOUNT: "UNPROCESSED_AMOUNT",
  POLICY_TYPE: "POLICY_TYPE",
  INSURED_TYPE: "INSURED_TYPE",
  TOTAL_ACTIVE_INSURED: "TOTAL_ACTIVE_INSURED",
  TOTAL_INSURED: "TOTAL_INSURED",
  TOTAL_CANCELLED_INSURED: "TOTAL_CANCELLED_INSURED",
  LATEST_REVISION: "LATEST_REVISION",
  PROVIDER_INFORMATION_VERIFICATION: "PROVIDER_INFORMATION_VERIFICATION",
  CHARGES: "CHARGES",
  REFUND_AMOUNT: "REFUND_AMOUNT",
  INSURED_CARDS_REVISION: "INSURED_CARDS_REVISION",
  MANAGEMENT_FEES: "MANAGEMENT_FEES",
  TOTAL_AMOUNT_BEFORE_ROUNDING: "TOTAL_AMOUNT_BEFORE_ROUNDING",
  NET_PREMIUM: "NET_PREMIUM",
  ADMIN_COST: "ADMIN_COST",
  BROKER_COMMISSION: "BROKER_COMMISSION",
  INSURED_CARDS_TOTAL: "INSURED_CARDS_TOTAL",
  MANAGEMENT_TAXES: "MANAGEMENT_TAXES",
  REINSURANCE_SHARE: "REINSURANCE_SHARE",
  TAXES: "TAXES",
  PREMIUM_FUNDER_SHARE: "PREMIUM_FUNDER_SHARE",
  ENROLLMENT_FEES: "ENROLLMENT_FEES",
  MAX_CLIENT_SHARE: "MAX_CLIENT_SHARE",
  PROVIDER_SERVICE_NAME: "PROVIDER_SERVICE_NAME",
  FOLLOWING_APPROVALS_NOT_ADDED: "FOLLOWING_APPROVALS_NOT_ADDED",
  BENEFICIARIES: "BENEFICIARIES",
  AFFECTED_INSURED_COUNT: "AFFECTED_INSURED_COUNT",
  TRANSMITTALS: "TRANSMITTALS",
  PROVIDER_CLAIM_COUNT: "PROVIDER_CLAIM_COUNT",
  PROVIDER_ESTIMATE_AMOUNT: "PROVIDER_ESTIMATE_AMOUNT",
  CLOSED: "CLOSED",
  PAYMENTS: "PAYMENTS",
  DUE_DATE: "DUE_DATE",
  PAID: "PAID",
  TRACK_BILLS: "TRACK_BILLS",
  BILLS: "BILLS",
  SEARCH_BILLS: "SEARCH_BILLS",
  PAID_DATE: "PAID_DATE",
  PAYMENT: "PAYMENT",
  BILL: "BILL",
  BILL_NUMBER: "BILL_NUMBER",
  ADVANCE_PAYMENT: "ADVANCE_PAYMENT",
  ADVANCE_PAYMENT_DISCOUNT: "ADVANCE_PAYMENT_DISCOUNT",
  FINAL_AMOUNT: "FINAL_AMOUNT",
  MEDICAL_NOTES: "MEDICAL_NOTES",
  GENERAL: "GENERAL",
  ADDRESS: "ADDRESS",
  PAYMENT_INFO: "PAYMENT_INFO",
  CONTACT_INFO: "CONTACT_INFO",
  REGISTRATION_NUMBER: "REGISTRATION_NUMBER",
  REGISTRATION_DATE: "REGISTRATION_DATE",
  COMMERCIAL_LOCAL_NAME: "COMMERCIAL_LOCAL_NAME",
  COMMERCIAL_FOREIGN_NAME: "COMMERCIAL_FOREIGN_NAME",
  REQUEST_UPDATE: "REQUEST_UPDATE",
  BANK: "BANK",
  BANK_BRANCH: "BANK_BRANCH",
  ACCOUNT_NUMBER: "ACCOUNT_NUMBER",
  EMAIL: "EMAIL",
  ATTACHMENTS_ARE_REQUIRED: "ATTACHMENTS_ARE_REQUIRED",
  SUNDAY: "SUNDAY",
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
  OFFICE_HOURS: "OFFICE_HOURS",
  DAYS: "DAYS",
  OPEN_24_HOURS: "OPEN_24_HOURS",
  AM: "AM",
  PM: "PM",
  FOLLOWING_HOURS_NOT_ADDED: "FOLLOWING_HOURS_NOT_ADDED",
  PREFERRED_CARD: "PREFERRED_CARD",
  PRICE: "PRICE",
  AMOUNT: "AMOUNT",
  ACTIVATION_DATE: "ACTIVATION_DATE",
  IS_DELIVERED: "IS_DELIVERED",
  DELIVERED_TO: "DELIVERED_TO",
  DELIVERY_COMMENT: "DELIVERY_COMMENT",
  IS_RETURNED: "IS_RETURNED",
  RETURN_DATE: "RETURN_DATE",
  IS_PRINTED: "IS_PRINTED",
  IS_CHRONIC_APPROVAL: "IS_CHRONIC_APPROVAL",
  PRE_AUTHORIZATION_ID: "PRE_AUTHORIZATION_ID",
  COVERAGES: "COVERAGES",
  NOTES_TO_PROVIDER: "NOTES_TO_PROVIDER",
  NOTIFICATION: "NOTIFICATION",
  RECEIVED_BY: "RECEIVED_BY",
  CLOSED_DATE: "CLOSED_DATE",
  CLOSED_BY: "CLOSED_BY",
  DIFFERENCE: "DIFFERENCE",
  COPAYMENT: "COPAYMENT",
  SERVICE_REJECTION: "SERVICE_REJECTION",
  CLAIM_MISCALCULATION: "CLAIM_MISCALCULATION",
  NUMBER_OF_ON_HOLD_CLAIMS: "NUMBER_OF_ON_HOLD_CLAIMS",
  PROVIDER_COPY: "PROVIDER_COPY",
  CLAIM_NET_REJECTED: "CLAIM_NET_REJECTED",
  ON_HOLD: "ON_HOLD",
  NET_REJECTED: "NET_REJECTED",
  COVER_MISCALCULATION: "COVER_MISCALCULATION",
  TRANSMITTAL_PROVIDER_ESTIMATE: "TRANSMITTAL_PROVIDER_ESTIMATE",
  CLAIM_PROVIDER_ESTIMATE: "CLAIM_PROVIDER_ESTIMATE",
  SUBMISSIONS: "SUBMISSIONS",
  CLAIMS_PVPS: "CLAIMS_PVPS",
  PREMIUM: "PREMIUM",
  CLIENT_SHARE: "CLIENT_SHARE",
  CARD_NO_OR_INSURED_NAME: "CARD_NO_OR_INSURED_NAME",
  GROSS_PREMIUM: "GROSS_PREMIUM",
  EFFECTIVE_DATE: "EFFECTIVE_DATE",
  PREMIUMS: "PREMIUMS",
  TPA_PREMIUM_SHARE: "TPA_PREMIUM_SHARE",
  PREMIUM_FUNDER: "PREMIUM_FUNDER",
  TOTAL_PREMIUM: "TOTAL_PREMIUM",
  NOT_LATEST: "NOT_LATEST",
  POLICY_REVISION: "POLICY_REVISION",
  RENEWAL_NUMBER: "RENEWAL_NUMBER",
  PREMIUM_DISTRIBUTION: "PREMIUM_DISTRIBUTION",
  REPORT_NOT_HANDLED: "REPORT_NOT_HANDLED",
  TRACK: "TRACK",
  QUICK_LINKS: "QUICK_LINKS",
  CLAIM_PROCESSING: "CLAIM_PROCESSING",
  STATS: "STATS",
  PAYMENTS_1: "PAYMENTS_1",
  UNDER_PROCESSING: "UNDER_PROCESSING",
  AVERAGE_PROCESSING_TIME: "AVERAGE_PROCESSING_TIME",
  PRODUCTION_REPORT: "PRODUCTION_REPORT",
  NEW_POLICIES_ONLY: "NEW_POLICIES_ONLY",
  RENEWALS_ONLY: "RENEWALS_ONLY",
  EXCLUDE_ALL_ENDORSEMENTS: "EXCLUDE_ALL_ENDORSEMENTS",
  FROM_CREATION_DATE: "FROM_CREATION_DATE",
  TO_CREATION_DATE: "TO_CREATION_DATE",
  FROM_EFFECTIVE_DATE: "FROM_EFFECTIVE_DATE",
  TO_EFFECTIVE_DATE: "TO_EFFECTIVE_DATE",
  REIMBURSEMENT_ITEM: "REIMBURSEMENT_ITEM",
  FROM_ACCIDENT_DATE: "FROM_ACCIDENT_DATE",
  TO_ACCIDENT_DATE: "TO_ACCIDENT_DATE",
  CLAIM_DETAILS_SERVICE_LEVEL: "CLAIM_DETAILS_SERVICE_LEVEL",
  REIMBURSEMENT_ITEMS: "REIMBURSEMENT_ITEMS",
  FROM_APPROVAL_DATE: "FROM_APPROVAL_DATE",
  TO_APPROVAL_DATE: "TO_APPROVAL_DATE",
  FROM_REQUEST_DATE: "FROM_REQUEST_DATE",
  TO_REQUEST_DATE: "TO_REQUEST_DATE",
  APPROVAL_DETAILS: "APPROVAL_DETAILS",
  AT_DATE: "AT_DATE",
  ACTIVE_INSURED_REPORT: "ACTIVE_INSURED_REPORT",
  CHOOSE_AT_LEAST_ONE_CRITERIA: "CHOOSE_AT_LEAST_ONE_CRITERIA",
  IMPORT_ITEMS: "IMPORT_ITEMS",
  ACCEPTED_STATUS: "ACCEPTED_STATUS",
  RENEWAL: "RENEWAL",
  AVERAGE_PROCESSING_TIME_IN_DAYS: "AVERAGE_PROCESSING_TIME_IN_DAYS",
  RETURNED_TO_SENDER_ITEMS: "RETURNED_TO_SENDER_ITEMS",
  OUTSTANDING_INVOICES: "OUTSTANDING_INVOICES",
  AVERAGE_PROCESSING_TIME_REGULAR: "AVERAGE_PROCESSING_TIME_REGULAR",
  NEEDS_SUBMISSION_BATCHES: "NEEDS_SUBMISSION_BATCHES",
  UNDER_PROCESSING_BATCHES: "UNDER_PROCESSING_BATCHES",
  PROCESSED_BATCHES: "PROCESSED_BATCHES",
  AVERAGE_PROCESSING_TIME_PREAUTHORIZATION:
    "AVERAGE_PROCESSING_TIME_PREAUTHORIZATION",
  TRACKED_1: "TRACKED_1",
  PENDING_1: "PENDING_1",
  FILE_NAME: "FILE_NAME",
  ACCOUNTING: "ACCOUNTING",
  INCLUDE_INSURED: "INCLUDE_INSURED",
  INCLUDE_EXCLUSIONS: "INCLUDE_EXCLUSIONS",
  RETURNED_TO_SENDER: "RETURNED_TO_SENDER",
  POLICY_CLASSIFICATION: "POLICY_CLASSIFICATION",
  PRE_AUTHORIZATION_REQUESTS: "PRE_AUTHORIZATION_REQUESTS",
  STAKEHOLDER: "STAKEHOLDER",
  CURRENT_REVISION: "CURRENT_REVISION",
  APPROVAL_ID: "APPROVAL_ID",
  PENDING_COMMENT: "PENDING_COMMENT",
  NOT_CLASSIFIED: "NOT_CLASSIFIED",
  PROCESSING_COMMENT: "PROCESSING_COMMENT",
  PRE_AUTHORIZATION_REQUEST: "PRE_AUTHORIZATION_REQUEST",
  MAIN_DIAGNOSIS: "MAIN_DIAGNOSIS",
  RETURN_TO_SENDER: "RETURN_TO_SENDER",
  APPROVE: "APPROVE",
  ATTACH_APPROVAL: "ATTACH_APPROVAL",
  DETACH_APPROVAL: "DETACH_APPROVAL",
  SUBPRODUCT: "SUBPRODUCT",
  PRE_AUTH_REQUEST: "PRE_AUTH_REQUEST",
  ISSUANCE_ITEM_TYPE: "ISSUANCE_ITEM_TYPE",
  IMPORT_ATTACHMENTS: "IMPORT_ATTACHMENTS",
  REQUEST: "REQUEST",
  TO_SUBMISSION_DATE: "TO_SUBMISSION_DATE",
  FROM_SUBMISSION_DATE: "FROM_SUBMISSION_DATE",
  PRE_AUTH_REQUESTS: "PRE_AUTH_REQUESTS",
  RECIPIENT: "RECIPIENT",
  NO_TRANSMITTALS_AVAILABLE: "NO_TRANSMITTALS_AVAILABLE",
  MEDICAL_NETWORK: "MEDICAL_NETWORK",
  PROVIDERS: "PROVIDERS",
  PROVIDER_CLASSIFICATION: "PROVIDER_CLASSIFICATION",
  IN_HOUSE_TYPE: "IN_HOUSE_TYPE",
  IN_HOUSE_JOINING_DATE: "IN_HOUSE_JOINING_DATE",
  PROVIDER_SPECIALTY_DATE: "PROVIDER_SPECIALTY_DATE",
  DOCTOR_NAME_LOCAL: "DOCTOR_NAME_LOCAL",
  DOCTOR_NAME_FORIEGN: "DOCTOR_NAME_FORIEGN",
  LICENSE_CODE: "LICENSE_CODE",
  LICENSE_DATE: "LICENSE_DATE",
  SUPPORTS_IN_HOUSE: "SUPPORTS_IN_HOUSE",
  OVERRIDE_PRESCRIPTION_CODE: "OVERRIDE_PRESCRIPTION_CODE",
  SECOND_OPINION_REFERRAL: "SECOND_OPINION_REFERRAL",
  TEL_1: "TEL_1",
  TEL_2: "TEL_2",
  FAX: "FAX",
  ADD_PROVIDER: "ADD_PROVIDER",
  ADDITIONAL: "ADDITIONAL",
  LICENSE: "LICENSE",
  CONTACTS: "CONTACTS",
  CONTACTS_INFO: "CONTACTS_INFO",
  PROVIDER_INFO: "PROVIDER_INFO",
  CONSULTATION_SERVICE: "CONSULTATION_SERVICE",
  PUBLISHED_TO_WEB: "PUBLISHED_TO_WEB",
  IS_VERIFIED: "IS_VERIFIED",
  VERIFICATION_DATE: "VERIFICATION_DATE",
  VERIFIED_BY: "VERIFIED_BY",
  SPECIALTY_DATE: "SPECIALTY_DATE",
  CONTRACTS: "CONTRACTS",
  WEB_ACCOUNTS: "WEB_ACCOUNTS",
  FACILITY_DOCTORS: "FACILITY_DOCTORS",
  TITLE: "TITLE",
  PROVIDER_CONTACTS: "PROVIDER_CONTACTS",
  ACTIVATION_NUMBER: "ACTIVATION_NUMBER",
  ACCOUNT_ACTIVATED: "ACCOUNT_ACTIVATED",
  LAST_LOGIN_DATE: "LAST_LOGIN_DATE",
  RESET_DATE: "RESET_DATE",
  RESET_BY: "RESET_BY",
  NOTIFICATION_SENT: "NOTIFICATION_SENT",
  AGREED_TO_TERMS: "AGREED_TO_TERMS",
  AGREEMENT_DATE: "AGREEMENT_DATE",
  BRANCHES: "BRANCHES",
  PROVIDER_BRANCHES: "PROVIDER_BRANCHES",
  SERVICE_TABLE_TYPE: "SERVICE_TABLE_TYPE",
  PAYMENT_PERCENTAGE: "PAYMENT_PERCENTAGE",
  IS_DEFAULT: "IS_DEFAULT",
  INVOICE_PAY_PERIOD: "INVOICE_PAY_PERIOD",
  NUMBER_TRANSMITTAL_PER_MONTH: "NUMBER_TRANSMITTAL_PER_MONTH",
  RECONCILIATION_PERIOD: "RECONCILIATION_PERIOD",
  PROVIDER_CONTRACTS: "PROVIDER_CONTRACTS",
  FROM_DATE: "FROM_DATE",
  TO_DATE: "TO_DATE",
  VISIT_COST: "VISIT_COST",
  HOURS_PER_VISIT: "HOURS_PER_VISIT",
  WEEKLY_VISITS: "WEEKLY_VISITS",
  MONTHLY_VISITS: "MONTHLY_VISITS",
  CLIENT_PRICING: "CLIENT_PRICING",
  INVOICE_CLIENT: "INVOICE_CLIENT",
  SUGGESTED_RENEWAL_PRICE: "SUGGESTED_RENEWAL_PRICE",
  DOCTOR_CONFIGURATIONS: "DOCTOR_CONFIGURATIONS",
  DOCTORS: "DOCTORS",
  TPA_DISCOUNT: "TPA_DISCOUNT",
  PRICING_SCHEMES: "PRICING_SCHEMES",
  REBATE_SCHEMES: "REBATE_SCHEMES",
  DISCOUNT_SCHEMES: "DISCOUNT_SCHEMES",
  OPERATION_CATEGORY_SCHEMES: "OPERATION_CATEGORY_SCHEMES",
  UNIT_TYPES: "UNIT_TYPES",
  EARLY_PAYMENT: "EARLY_PAYMENT",
  IN_HOUSE: "IN_HOUSE",
  IN_HOUSE_PROVIDER_CONFIGURATIONS: "IN_HOUSE_PROVIDER_CONFIGURATIONS",
  CLAIM_FORMS: "CLAIM_FORMS",
  COMMENT_TO_APPROVAL_DEPARTMENT: "COMMENT_TO_APPROVAL_DEPARTMENT",
  IGNORE_PRESCRIPTION_CODE: "IGNORE_PRESCRIPTION_CODE",
  IGNORE_OLD_CLAIMS: "IGNORE_OLD_CLAIMS",
  ORIGINAL_RECEIVED: "ORIGINAL_RECEIVED",
  OVERRIDE_ORIGINAL_RECEIVED_RULE: "OVERRIDE_ORIGINAL_RECEIVED_RULE",
  IGNORE_APPROVAL_EXPIRY_DATE: "IGNORE_APPROVAL_EXPIRY_DATE",
  PRE_AUTH_EXPIRED_ON_DISP: "PRE_AUTH_EXPIRED_ON_DISP",
  PROVIDER_AMOUNT_AS_PRICE_LIST: "PROVIDER_AMOUNT_AS_PRICE_LIST",
  INITIAL_CLAIM_FORM_ID: "INITIAL_CLAIM_FORM_ID",
  ADJUSTMENT_CLAIM_FORM_ID: "ADJUSTMENT_CLAIM_FORM_ID",
  ADJUSTMENT_ACCIDENT_DATE: "ADJUSTMENT_ACCIDENT_DATE",
  ADJUSTMENT_INSERT_DATE: "ADJUSTMENT_INSERT_DATE",
  ADJUSTMENT_BATCH_ID: "ADJUSTMENT_BATCH_ID",
  ADJUSTMENT_HISTORY: "ADJUSTMENT_HISTORY",
  ALL_BATCHES_SUBMITTED: "ALL_BATCHES_SUBMITTED",
  READY_FOR_CLOSING: "READY_FOR_CLOSING",
  CLAIM_COVER: "CLAIM_COVER",
  BATCH: "BATCH",
  READDED: "READDED",
  SETTLED_AMOUNT: "SETTLED_AMOUNT",
  IS_FUND_RELATED: "IS_FUND_RELATED",
  IS_ADJUSTED: "IS_ADJUSTED",
  IS_ADJUSTMENT: "IS_ADJUSTMENT",
  IS_DEBIT: "IS_DEBIT",
  DEDUCT_CLAIM_DIFFERENCE_COPAYMENT: "DEDUCT_CLAIM_DIFFERENCE_COPAYMENT",
  VOUCHERS: "VOUCHERS",
  PVI_GROUP: "PVI_GROUP",
  PVI: "PVI",
  PVP: "PVP",
  PVC: "PVC",
  CDV: "CDV",
  PVI_GROUP_INVOICE_ID: "PVI_GROUP_INVOICE_ID",
  PVI_GROUP_BILL_ID: "PVI_GROUP_BILL_ID",
  CDV_INVOICE_ID: "CDV_INVOICE_ID",
  PVP_BILL_ID: "PVP_BILL_ID",
  PVC_BILL_ID: "PVC_BILL_ID",
  SETTLEMENT_DATE: "SETTLEMENT_DATE",
  OUT_OF_NETWORK_PENALTY: "OUT_OF_NETWORK_PENALTY",
  CLAIM_DIFFERENCE_DEDUCTIBLE: "CLAIM_DIFFERENCE_DEDUCTIBLE",
  POLICY_COVERAGE: "POLICY_COVERAGE",
  COINSURER_COVERAGE_AMOUNT: "COINSURER_COVERAGE_AMOUNT",
  COINSURER_PENDING_AMOUNT: "COINSURER_PENDING_AMOUNT",
  PENALTY_PERCENTAGE: "PENALTY_PERCENTAGE",
  PENALTY: "PENALTY",
  SYNDICATE_SHARE_AMOUNT: "SYNDICATE_SHARE_AMOUNT",
  PRICE_RATIO: "PRICE_RATIO",
  TOTAL_TAX: "TOTAL_TAX",
  ADMIN_COST_TAX: "ADMIN_COST_TAX",
  ADMIN_COST_TAX_BALANCE: "ADMIN_COST_TAX_BALANCE",
  BROKER_COMMISSION_TAX: "BROKER_COMMISSION_TAX",
  BROKER_COMMISSION_TAX_BALANCE: "BROKER_COMMISSION_TAX_BALANCE",
  DEBIT_TAX: "DEBIT_TAX",
  DEBIT_TAX_BALANCE: "DEBIT_TAX_BALANCE",
  TOTAL_BALANCE: "TOTAL_BALANCE",
  REQUESTED_LOCAL: "REQUESTED_LOCAL",
  REQUESTED_FOREIGN: "REQUESTED_FOREIGN",
  TOTAL_AMOUNT_LOCAL: "TOTAL_AMOUNT_LOCAL",
  TOTAL_AMOUNT_FOREIGN: "TOTAL_AMOUNT_FOREIGN",
  NUMBER_OF_PVIS: "NUMBER_OF_PVIS",
  NUMBER_OF_INVOICES: "NUMBER_OF_INVOICES",
  EXCLUDED_FROM_GROUP: "EXCLUDED_FROM_GROUP",
  BROKERS: "BROKERS",
  AUDIT_DATE: "AUDIT_DATE",
  AUDITED_BY: "AUDITED_BY",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  OPERATIONS_PRICING_SCHEME: "OPERATIONS_PRICING_SCHEME",
  SERVICE_PRICING_SCHEME: "SERVICE_PRICING_SCHEME",
  SERVICE_DISCOUNT_SCHEME: "SERVICE_DISCOUNT_SCHEME",
  DISCOUNT_SCHEME: "DISCOUNT_SCHEME",
  OPERATION_CATEGORY_SCHEME: "OPERATION_CATEGORY_SCHEME",
  SPC_YEARS: "SPC_YEARS",
  IS_FIXED: "IS_FIXED",
  FIXED_AMOUNT: "FIXED_AMOUNT",
  PER_INVOICE: "PER_INVOICE",
  PER_PAYMENT: "PER_PAYMENT",
  APPLY_ON_CHECKUP: "APPLY_ON_CHECKUP",
  APPLY_ON_IN_HOUSE: "APPLY_ON_IN_HOUSE",
  LAST_UPDATED_BY: "LAST_UPDATED_BY",
  TPA_DISCOUNTS: "TPA_DISCOUNTS",
  RANGE_START: "RANGE_START",
  RANGE_END: "RANGE_END",
  RANGE_CURRENCY: "RANGE_CURRENCY",
  PERCENTAGE: "PERCENTAGE",
  TPA_DISCOUNT_DETAILS: "TPA_DISCOUNT_DETAILS",
  LAST_UPDATE_DATE: "LAST_UPDATE_DATE",
  APPLY_DISCOUNT_ON_EARLY_PAYMENT: "APPLY_DISCOUNT_ON_EARLY_PAYMENT",
  DUE_DAYS: "DUE_DAYS",
  DISCOUNT_ON_ADVANCE_PAYMENT: "DISCOUNT_ON_ADVANCE_PAYMENT",
  EARLY_PAYMENT_DETAILS: "EARLY_PAYMENT_DETAILS",
  EARNED: "EARNED",
  NET_EARNED: "NET_EARNED",
  GROSS_EARNED: "GROSS_EARNED",
  AMOUNT_DISTRIBUTION: "AMOUNT_DISTRIBUTION",
  NET_WRITTEN: "NET_WRITTEN",
  GROSS_WRITTEN: "GROSS_WRITTEN",
  CANCELLED_INSURED_COUNT: "CANCELLED_INSURED_COUNT",
  NET_DISTRIBUTION: "NET_DISTRIBUTION",
  GROSS_DISTRIBUTION: "GROSS_DISTRIBUTION",
  UNIT_TYPE: "UNIT_TYPE",
  UNIT_TYPE_MAPPINGS: "UNIT_TYPE_MAPPINGS",
  MAPPING_UNIT_TYPE: "MAPPING_UNIT_TYPE",
  SURGICAL_UNIT_TYPES: "SURGICAL_UNIT_TYPES",
  PROVIDER_CONTRACT_TAX_EXEMPTION: "PROVIDER_CONTRACT_TAX_EXEMPTION",
  PROVIDER_CONTRACT_TAXES: "PROVIDER_CONTRACT_TAXES",
  DELIVER_DRUGS: "DELIVER_DRUGS",
  APPOINTMENT_ENABLED: "APPOINTMENT_ENABLED",
  ONLINE_CONSULTATION: "ONLINE_CONSULTATION",
  ADDED: "ADDED",
  WRITTEN: "WRITTEN",
  UN_SET_TREATMENT_DOCTOR: "UN_SET_TREATMENT_DOCTOR",
  ADD_TO_PRE_AUTH_NETWORK: "ADD_TO_PRE_AUTH_NETWORK",
  REMOVE_FROM_PRE_AUTH_NETWORK: "REMOVE_FROM_PRE_AUTH_NETWORK",
  CLAIMED_AS_REQUESTED: "CLAIMED_AS_REQUESTED",
  ACCEPTED_AS_REQUESTED: "ACCEPTED_AS_REQUESTED",
  CLAIM_PREEXISTING_AND_CHRONIC: "CLAIM_PREEXISTING_AND_CHRONIC",
  CLAIM_PREEXISTING: "CLAIM_PREEXISTING",
  CLAIM_CHRONIC: "CLAIM_CHRONIC",
  CLAIM_ACUTE: "CLAIM_ACUTE",
  CLAIM_REGULAR: "CLAIM_REGULAR",
  PREAUTH_PREEXISTING_AND_CHRONIC: "PREAUTH_PREEXISTING_AND_CHRONIC",
  PREAUTH_PREEXISTING: "PREAUTH_PREEXISTING",
  PREAUTH_CHRONIC: "PREAUTH_CHRONIC",
  PREAUTH_ACUTE: "PREAUTH_ACUTE",
  PREAUTH_REGULAR: "PREAUTH_REGULAR",
  PREAUTH: "PREAUTH",
  PREMIUM_AND_CLAIMS: "PREMIUM_AND_CLAIMS",
  EXCLUDE_PREAUTHORIZATION: "EXCLUDE_PREAUTHORIZATION",
  LOSS_RATIO_ON_GROSS: "LOSS_RATIO_ON_GROSS",
  UN_PUBLISH: "UN_PUBLISH",
  PUBLISH: "PUBLISH",
  IS_PUBLISHED_TO_WEB: "IS_PUBLISHED_TO_WEB",
  DEBIT_ADMIN_COST: "DEBIT_ADMIN_COST",
  CLAIMS: "CLAIMS",
  VERIFY: "VERIFY",
  UN_VERIFY: "UN_VERIFY",
  VERIFIED: "VERIFIED",
  AUTHORIZED: "AUTHORIZED",
  CLAIM_INCLUDED_IN_LOSS_RATIO: "CLAIM_INCLUDED_IN_LOSS_RATIO",
  PREAUTH_INCLUDED_IN_LOSS_RATIO: "PREAUTH_INCLUDED_IN_LOSS_RATIO",
  LATITUDE: "LATITUDE",
  LONGITUDE: "LONGITUDE",
  WAIVED_ADMIN_COST: "WAIVED_ADMIN_COST",
  WAIVE_ADMIN_COST: "WAIVE_ADMIN_COST",
  REMOVE_WAIVED_ADMIN_COST: "REMOVE_WAIVED_ADMIN_COST",
  SUPPORTS_SPECIAL_DISCOUNT: "SUPPORTS_SPECIAL_DISCOUNT",
  HOLD_PROVIDER_PAYMENTS: "HOLD_PROVIDER_PAYMENTS",
  UNSET_HOLD_PROVIDER_PAYMENTS: "UNSET_HOLD_PROVIDER_PAYMENTS",
  DISREGARD_DEDUCTIBLE_AS_DEBIT: "DISREGARD_DEDUCTIBLE_AS_DEBIT",
  ACCEPTED_TO_DEFAULT: "ACCEPTED_TO_DEFAULT",
  ACCEPT_AS_PER_APPROVAL: "ACCEPT_AS_PER_APPROVAL",
  ADJUST: "ADJUST",
  RELINK_SERVICES: "RELINK_SERVICES",
  LOSS_RATIO_ON_NET: "LOSS_RATIO_ON_NET",
  PROVIDER_USER_FULL_NAME: "PROVIDER_USER_FULL_NAME",
  INCLUDED_IN_LOSS_RATIO: "INCLUDED_IN_LOSS_RATIO",
  NET_EARNED_INCLUDED_IN_LOSS_RATIO: "NET_EARNED_INCLUDED_IN_LOSS_RATIO",
  GROSS_EARNED_INCLUDED_IN_LOSS_RATIO: "GROSS_EARNED_INCLUDED_IN_LOSS_RATIO",
  CONTACT: "CONTACT",
  LR_ON_GROSS: "LR_ON_GROSS",
  LR_ON_NET: "LR_ON_NET",
  FACILITY_DOCTOR: "FACILITY_DOCTOR",
  SUB_PROVIDER: "SUB_PROVIDER",
  WITHHOLD_PAYMENT: "WITHHOLD_PAYMENT",
  WITHHELD_AMOUNT: "WITHHELD_AMOUNT",
  WITHHELD_QUANTITY: "WITHHELD_QUANTITY",
  ACCEPTED_WITHHOLD_AMOUNT: "ACCEPTED_WITHHOLD_AMOUNT",
  PENDING_COUNT: "PENDING_COUNT",
  SHOW_AGGREGATED_PREMIUMS: "SHOW_AGGREGATED_PREMIUMS",
  NOT_RENEWED_POLICIES_ONLY: "NOT_RENEWED_POLICIES_ONLY",
  ACCEPT_PENDING: "ACCEPT_PENDING",
  AMOUNT_TO_ACCEPT: "AMOUNT_TO_ACCEPT",
  OVERRIDE_BENEFIT_LIMIT: "OVERRIDE_BENEFIT_LIMIT",
  AUTO_APPLY_EXAMINATION: "AUTO_APPLY_EXAMINATION",
  TRANSMITTAL_COVER: "TRANSMITTAL_COVER",
  DEFAULT_CURRENCY: "DEFAULT_CURRENCY",
  DISTRIBUTE_BY: "DISTRIBUTE_BY",
  COVERAGE_PLANS: "COVERAGE_PLANS",
  PRICING_SCHEME_DATE: "PRICING_SCHEME_DATE",
  EDIT_SERVICE_PRICING_SCHEME: "EDIT_SERVICE_PRICING_SCHEME",
  OPERATION_CATEGORY_SCHEME_DATE: "OPERATION_CATEGORY_SCHEME_DATE",
  REBATE_SCHEME: "REBATE_SCHEME",
  TAX: "TAX",
  EDIT_PROVIDER_CONTRACT_TAX: "EDIT_PROVIDER_CONTRACT_TAX",
  PROVIDER_CONTRACT_TAX: "PROVIDER_CONTRACT_TAX",
  IS_PREPAID_TAX: "IS_PREPAID_TAX",
  EDIT_PROVIDER_CONTRACT_TAX_EXEMPTION: "EDIT_PROVIDER_CONTRACT_TAX_EXEMPTION",
  SURGICAL_UNIT_TYPE: "SURGICAL_UNIT_TYPE",
  MAPPED_UNIT_TYPE: "MAPPED_UNIT_TYPE",
  UNIT_TYPE_MAPPING: "UNIT_TYPE_MAPPING",
  IN_HOUSE_CONFIGURATION: "IN_HOUSE_CONFIGURATION",
  EARLY_PAYMENT_DETAIL: "EARLY_PAYMENT_DETAIL",
  CLAIMED_COUNT: "CLAIMED_COUNT",
  USER_CLAIM_COUNT: "USER_CLAIM_COUNT",
  CLAIM_CATEGORY: "CLAIM_CATEGORY",
  VALIDATE_ATTACHMENTS: "VALIDATE_ATTACHMENTS",
  VALIDATE_COUNTS_AND_AMOUNTS: "VALIDATE_COUNTS_AND_AMOUNTS",
  FAST_LIFE_CYCLE: "FAST_LIFE_CYCLE",
  IS_URGENT: "IS_URGENT",
  READY_FOR_CLOSING_DATE: "READY_FOR_CLOSING_DATE",
  ISSUED_BY: "ISSUED_BY",
  RECEIVE: "RECEIVE",
  GENERAL_COMMENT: "GENERAL_COMMENT",
  LINKED_TO_CLAIM_FORM: "LINKED_TO_CLAIM_FORM",
  UPLOAD_DATE: "UPLOAD_DATE",
  UPLOADS: "UPLOADS",
  EXAMINATION: "EXAMINATION",
  IS_OLD_CLAIM: "IS_OLD_CLAIM",
  ADD_NEW_CLAIM: "ADD_NEW_CLAIM",
  HAS_CARD_NO: "HAS_CARD_NO",
  CURRENT_PROVIDER_ONLY: "CURRENT_PROVIDER_ONLY",
  ADVANCE_SEARCH: "ADVANCE_SEARCH",
  REMOVE: "REMOVE",
  SHIFT_SERIAL_UP: "SHIFT_SERIAL_UP",
  SHIFT_SERIAL_DOWN: "SHIFT_SERIAL_DOWN",
  RELINK: "RELINK",
  SWITCH: "SWITCH",
  SYNC_FAST_CYCLE: "SYNC_FAST_CYCLE",
  PACK_NAME: "PACK_NAME",
  EXTERNAL_BATCH_ID: "EXTERNAL_BATCH_ID",
  GENERATE: "GENERATE",
  UNPROCESSED_CLAIMS: "UNPROCESSED_CLAIMS",
  PACK: "PACK",
  FORCE_CHECKIN: "FORCE_CHECKIN",
  COLLECTION_AMOUNT: "COLLECTION_AMOUNT",
  COLLECTED_AMOUNT: "COLLECTED_AMOUNT",
  REBATE: "REBATE",
  EXPEDITE_PAYMENT: "EXPEDITE_PAYMENT",
  BILL_GENERATION: "BILL_GENERATION",
  GENERATE_PVC: "GENERATE_PVC",
  GENERATE_PVP: "GENERATE_PVP",
  SHOW_ON_TRANSMITTAL_REPORT: "SHOW_ON_TRANSMITTAL_REPORT",
  SHOW_ON_RECEIPT: "SHOW_ON_RECEIPT",
  ASSIGNED_BY_USER: "ASSIGNED_BY_USER",
  ASSIGNMENT_DATE: "ASSIGNMENT_DATE",
  READY_FOR_CLOSING_BY: "READY_FOR_CLOSING_BY",
  LOCKED_DATE: "LOCKED_DATE",
  ASSIGNMENT_COMMENT: "ASSIGNMENT_COMMENT",
  LOCK: "LOCK",
  UNLOCK: "UNLOCK",
  FIRST_NAME_LOCAL: "FIRST_NAME_LOCAL",
  MIDDLE_NAME_LOCAL: "MIDDLE_NAME_LOCAL",
  LAST_NAME_LOCAL: "LAST_NAME_LOCAL",
  FIRST_NAME_FOREIGN: "FIRST_NAME_FOREIGN",
  MIDDLE_NAME_FOREIGN: "MIDDLE_NAME_FOREIGN",
  LAST_NAME_FOREIGN: "LAST_NAME_FOREIGN",
  NEW_CONTRACT: "NEW_CONTRACT",
  PLANS: "PLANS",
  DEFINITION_DATE: "DEFINITION_DATE",
  BENEFIT_MAPPING_TEMPLATE: "BENEFIT_MAPPING_TEMPLATE",
  PLAN_TYPE: "PLAN_TYPE",
  PLAN_DURATION_MONTH: "PLAN_DURATION_MONTH",
  USE_PAYER_CLIENT_BRANCH_CODE: "USE_PAYER_CLIENT_BRANCH_CODE",
  REVERSED_CALCULATION: "REVERSED_CALCULATION",
  GUARANTEED_RENEWABLE: "GUARANTEED_RENEWABLE",
  APPLY_RENEW_GUARANTEE_WAITING_PERIOD_YEARS:
    "APPLY_RENEW_GUARANTEE_WAITING_PERIOD_YEARS",
  RENEW_GUARANTEED: "RENEW_GUARANTEED",
  CONFIGURATIONS: "CONFIGURATIONS",
  BENEFITS_AND_PREMIUMS: "BENEFITS_AND_PREMIUMS",
  FINANCIAL_PROVISIONS: "FINANCIAL_PROVISIONS",
  EXCLUSIONS: "EXCLUSIONS",
  PLAN_INFO: "PLAN_INFO",
  ROUND_GROSS_NEAREST: "ROUND_GROSS_NEAREST",
  GR_WAITING_PERIOD_YEARS: "GR_WAITING_PERIOD_YEARS",
  FEES_PER_MONTH: "FEES_PER_MONTH",
  APPLY_FULL_MONTH_RULE: "APPLY_FULL_MONTH_RULE",
  INSURED_SELF_COVERAGE: "INSURED_SELF_COVERAGE",
  INSURED_AGE_CONFIGURATION: "INSURED_AGE_CONFIGURATION",
  INSURED_RANGE_CONFIGURATION: "INSURED_RANGE_CONFIGURATION",
  BENEFIT_OPTIONS: "BENEFIT_OPTIONS",
  ALLOWED_ENDORSEMENT: "ALLOWED_ENDORSEMENT",
  CARD_NOTES: "CARD_NOTES",
  RANGE: "RANGE",
  OVERAGE: "OVERAGE",
  ADDITIONAL_PREMIUM_PERCENTAGE_ON_ENDORSEMENT:
    "ADDITIONAL_PREMIUM_PERCENTAGE_ON_ENDORSEMENT",
  ADDITIONAL_PREMIUM_PERCENTAGE_ON_ISSUANCE:
    "ADDITIONAL_PREMIUM_PERCENTAGE_ON_ISSUANCE",
  BASE_AGE_RANGE: "BASE_AGE_RANGE",
  INCLUDE_ALL_CITIES: "INCLUDE_ALL_CITIES",
  AGE_RANGES: "AGE_RANGES",
  INSURED_RELATIONS: "INSURED_RELATIONS",
  PROVIDER_GROUPS: "PROVIDER_GROUPS",
  INSURED_RANGES: "INSURED_RANGES",
  CLASSES: "CLASSES",
  COUNTRIES: "COUNTRIES",
  CITIES: "CITIES",
  UNDERWRITING: "UNDERWRITING",

  EFFECTIVE: "EFFECTIVE",
  AVAILABLE_ON_MOBILE: "AVAILABLE_ON_MOBILE",
  MOBILE_FORMAT: "MOBILE_FORMAT",
  PHONE_FORMAT: "PHONE_FORMAT",
  NATIONALITY: "NATIONALITY",
  GOVERNORATE: "GOVERNORATE",
  GEOGRAPHICAL_SCOPE: "GEOGRAPHICAL_SCOPE",
  PHONE_CODE: "PHONE_CODE",
  ALWAYS_PROPORTIONAL: "ALWAYS_PROPORTIONAL",
  NOT_PROPORTIONAL: "NOT_PROPORTIONAL",
  CARD_ABBREVIATION_FOREIGN: "CARD_ABBREVIATION_FOREIGN",
  CARD_ABBREVIATION_LOCAL: "CARD_ABBREVIATION_LOCAL",
  INCLUDE_IN_ECARD_SUMMARY: "INCLUDE_IN_ECARD_SUMMARY",
  PLAN_PRODUCTS: "PLAN_PRODUCTS",
  PLAN_BENEFITS: "PLAN_BENEFITS",
  PLAN_BENEFIT: "PLAN_BENEFIT",
  AGE_RANGE: "AGE_RANGE",
  INSURED_RANGE: "INSURED_RANGE",
  COLLAR: "COLLAR",
  PLAN_BENEFIT_PREMIUMS: "PLAN_BENEFIT_PREMIUMS",
  TAXES_AND_CHARGES: "TAXES_AND_CHARGES",
  BROKER_COMMISSIONS: "BROKER_COMMISSIONS",
  PREMIUM_FUNDERS: "PREMIUM_FUNDERS",
  CHARGE: "CHARGE",
  LEVEL: "LEVEL",
  POLICY_TAX_TYPE: "POLICY_TAX_TYPE",
  TEX_DEPENDENT: "TEX_DEPENDENT",
  SUBTRACT_ADMIN_COST_TAXES: "SUBTRACT_ADMIN_COST_TAXES",
  PREMIUM_ADDITIONS: "PREMIUM_ADDITIONS",
  PREMIUM_REFUNDS: "PREMIUM_REFUNDS",
  BASED_ON_ACTIVE_PERIOD: "BASED_ON_ACTIVE_PERIOD",
  BASED_ON_ELAPSED_PERIOD: "BASED_ON_ELAPSED_PERIOD",
  PROPORTIONAL_ADDITION: "PROPORTIONAL_ADDITION",
  PERIOD_IN_MONTH: "PERIOD_IN_MONTH",
  PREMIUM_ADDITION: "PREMIUM_ADDITION",
  MUST_REFUND_ON_DELETION: "MUST_REFUND_ON_DELETION",
  SUBTRACT_CLAIM_FROM_REFUND_AMOUNT: "SUBTRACT_CLAIM_FROM_REFUND_AMOUNT",
  SUBTRACT_CLAIMS_PROPORTIONALLY: "SUBTRACT_CLAIMS_PROPORTIONALLY",
  NO_REFUND_WHEN_INSURED_HAS_CLAIMS: "NO_REFUND_WHEN_INSURED_HAS_CLAIMS",
  PROPORTIONAL_REFUND: "PROPORTIONAL_REFUND",
  ADMINISTRATION_COST: "ADMINISTRATION_COST",
  GENERATE_INSTALLMENT_BY_ORGANIZATION: "GENERATE_INSTALLMENT_BY_ORGANIZATION",
  PER_PREMIUM: "PER_PREMIUM",
  ON_BENEFIT: "ON_BENEFIT",
  ON_INSURED: "ON_INSURED",
  PER_MONTH: "PER_MONTH",
  INCLUDE_NET: "INCLUDE_NET",
  INCLUDE_CHARGES: "INCLUDE_CHARGES",
  HAS_NON_REFUND_RULE: "HAS_NON_REFUND_RULE",
  FROM_ORIGINAL_PREMIUM: "FROM_ORIGINAL_PREMIUM",
  NON_REFUNDABLE_PERCENTAGE: "NON_REFUNDABLE_PERCENTAGE",
  NON_REFUNDABLE_AMOUNT: "NON_REFUNDABLE_AMOUNT",
  SHORT_PERIOD_REFUND: "SHORT_PERIOD_REFUND",
  SHORT_PERIOD_IN_DAYS: "SHORT_PERIOD_IN_DAYS",
  BENEFIT_SHARES: "BENEFIT_SHARES",
  ADMINISTRATION_COST_INSTALLMENTS: "ADMINISTRATION_COST_INSTALLMENTS",
  SHARE: "SHARE",
  MINIMUM_AMOUNT: "MINIMUM_AMOUNT",
  FOLLOWS_POLICY_INSTALLMENT: "FOLLOWS_POLICY_INSTALLMENT",
  PERIOD_TYPE: "PERIOD_TYPE",
  PER_CALENDAR_YEAR: "PER_CALENDAR_YEAR",
  PER_UNDERWRITING_YEAR: "PER_UNDERWRITING_YEAR",
  ROUNDING_LEVEL: "ROUNDING_LEVEL",
  ADDED_TO_TOTAL_PREMIUM: "ADDED_TO_TOTAL_PREMIUM",
  ON_POLICY: "ON_POLICY",
  INCLUDE_COSTS: "INCLUDE_COSTS",
  BROKER_COMMISSION_INSTALLMENTS: "BROKER_COMMISSION_INSTALLMENTS",
  HAS_PREMIUM_FUNDER: "HAS_PREMIUM_FUNDER",
  HAS_EXCEEDING_LIMIT_PREMIUM_FUNDER: "HAS_EXCEEDING_LIMIT_PREMIUM_FUNDER",
  PROPORTIONAL_TO_CALENDAR_YEAR: "PROPORTIONAL_TO_CALENDAR_YEAR",
  PREMIUM_FUNDER_INSTALLMENTS: "PREMIUM_FUNDER_INSTALLMENTS",

  PLAN_COVERAGES: "PLAN_COVERAGES",
  PLAN_PRODUCT_COVERAGES: "PLAN_PRODUCT_COVERAGES",
  PLAN_BENEFIT_COVERAGES: "PLAN_BENEFIT_COVERAGES",

  PRODUCT_COVERAGES: "PRODUCT_COVERAGES",
  BENEFIT_COVERAGES: "BENEFIT_COVERAGES",
  COVERAGE_PLAN_ID: "COVERAGE_PLAN_ID",
  LIMIT_PER_POLICY: "LIMIT_PER_POLICY",
  LIMIT_PER_INSURED: "LIMIT_PER_INSURED",
  PREEXISTING_LIMIT_PER_POLICY: "PREEXISTING_LIMIT_PER_POLICY",
  PREEXISTING_LIMIT_PER_INSURED: "PREEXISTING_LIMIT_PER_INSURED",
  PREEXISTING_AND_CHRONIC_LIMIT_PER_POLICY:
    "PREEXISTING_AND_CHRONIC_LIMIT_PER_POLICY",
  PREEXISTING_AND_CHRONIC_LIMIT_PER_INSURED:
    "PREEXISTING_AND_CHRONIC_LIMIT_PER_INSURED",
  CLASS_WITH_RELATION: "CLASS_WITH_RELATION",
  ADDITIONAL_CHRONIC_LIMIT_PER_INSURED: "ADDITIONAL_CHRONIC_LIMIT_PER_INSURED",
  BENEFICIARY_PERCENTAGE: "BENEFICIARY_PERCENTAGE",
  COVERAGE_SHARE_PERCENTAGE: "COVERAGE_SHARE_PERCENTAGE",
  REIMBURSEMENT_PRICING_SCHEME: "REIMBURSEMENT_PRICING_SCHEME",

  CHAPTER: "CHAPTER",
  BLOCK: "BLOCK",
  GROUP: "GROUP",
  EXCLUSION_CATEGORY: "EXCLUSION_CATEGORY",
  EXCLUSION_TYPE: "EXCLUSION_TYPE",
  PERIOD: "PERIOD",

  USE_DEFAULT_MEDICAL_NETWORK_FOR_CHRONIC:
    "USE_DEFAULT_MEDICAL_NETWORK_FOR_CHRONIC",
  ALLOWS_PREEXISTING: "ALLOWS_PREEXISTING",
  ALLOWS_REIMBURSEMENT: "ALLOWS_REIMBURSEMENT",
  ALLOWS_CHRONIC: "ALLOWS_CHRONIC",
  ALLOWS_NEW_CASES: "ALLOWS_NEW_CASES",
  ALLOWS_DIRECT_BILLING: "ALLOWS_DIRECT_BILLING",
  ALLOW_INSURED_TO_SUBMIT_REIMBURSEMENT_REQUEST:
    "ALLOW_INSURED_TO_SUBMIT_REIMBURSEMENT_REQUEST",
  ALLOW_MOBILE_APPOINTMENT_REQUEST: "ALLOW_MOBILE_APPOINTMENT_REQUEST",
  CHRONIC_IS_ADDITIONAL: "CHRONIC_IS_ADDITIONAL",
  PREEXISTING_IS_ADDITIONAL: "PREEXISTING_IS_ADDITIONAL",
  REQUIRE_INHOUSE_PRESCRIPTION: "REQUIRE_INHOUSE_PRESCRIPTION",
  REQUIRE_COMPANY_PRESCRIPTION: "REQUIRE_COMPANY_PRESCRIPTION",
  IS_DEBIT_ONLY: "IS_DEBIT_ONLY",
  DEFAULT_REIMBURSEMENT_PRICING_SCHEME: "DEFAULT_REIMBURSEMENT_PRICING_SCHEME",
  CHRONIC_MEDICAL_NETWORK: "CHRONIC_MEDICAL_NETWORK",
  LIMIT_PER_COVERAGE_PLAN: "LIMIT_PER_COVERAGE_PLAN",
  LIMIT_PER_FAMILY: "LIMIT_PER_FAMILY",
  CHRONIC_LIMIT_PER_INSURED: "CHRONIC_LIMIT_PER_INSURED",
  CHRONIC_LIMIT_PER_POLICY: "CHRONIC_LIMIT_PER_POLICY",
  HAS_ADMIN_COST_PER_CLAIM: "HAS_ADMIN_COST_PER_CLAIM",
  CLAIM_AMOUNT: "CLAIM_AMOUNT",
  CLAIM_PERCENTAGE: "CLAIM_PERCENTAGE",
  DEBIT_CLAIM_AMOUNT: "DEBIT_CLAIM_AMOUNT",
  DEBIT_CLAIM_PERCENTAGE: "DEBIT_CLAIM_PERCENTAGE",
  HAS_BROKER_COMMISSION_PER_CLAIM: "HAS_BROKER_COMMISSION_PER_CLAIM",
  REIMBURSEMENT_PAYMENT_CYCLE: "REIMBURSEMENT_PAYMENT_CYCLE",
  REIMBURSEMENT_REQUEST_RESUBMISSION_GRACE_PERIOD:
    "REIMBURSEMENT_REQUEST_RESUBMISSION_GRACE_PERIOD",
  EMPTY_STRING: "EMPTY_STRING",
  LAST_COMPLETED_STEP: "LAST_COMPLETED_STEP",
  TASK_INFORMATION: "TASK_INFORMATION",
  TASK: "TASK",
  CHANGE_PACK: "CHANGE_PACK",
  CHANGE: "CHANGE",
  SUBMIT_BATCHES: "SUBMIT_BATCHES",
  ROLLBACK_BATCHES: "ROLLBACK_BATCHES",
  CHECKIN_BATCHES: "CHECKIN_BATCHES",
  CHECKOUT_BATCHES: "CHECKOUT_BATCHES",
  BATCH_ID: "BATCH_ID",
  CHECKIN_CLAIMS: "CHECKIN_CLAIMS",
  CHECKOUT_CLAIMS: "CHECKOUT_CLAIMS",
  REMOVE_CLAIMS: "REMOVE_CLAIMS",
  DELETE_CLAIMS: "DELETE_CLAIMS",
  ACCEPT_CLAIMS: "ACCEPT_CLAIMS",
  ROLLBACK_CLAIMS: "ROLLBACK_CLAIMS",
  REJECT_CLAIMS: "REJECT_CLAIMS",
  FOLLOWING_ITEMS_NOT_ADDED: "FOLLOWING_ITEMS_NOT_ADDED",
  DELETE_MULTIPLE: "DELETE_MULTIPLE",
  DEPARTMENT: "DEPARTMENT",
  ASSIGN_TO: "ASSIGN_TO",
  DEPARTMENT_TEAM: "DEPARTMENT_TEAM",
  FROM_SPECIALTY_YEARS: "FROM_SPECIALTY_YEARS",
  TO_SPECIALTY_YEARS: "TO_SPECIALTY_YEARS",
  PROVIDER_CONTRACT: "PROVIDER_CONTRACT",
  PAYMENT_LIFE_CYCLE: "PAYMENT_LIFE_CYCLE",
  FOLLOW_MAIN_BRANCH_CONTRACT: "FOLLOW_MAIN_BRANCH_CONTRACT",
  UN_FOLLOW_MAIN_BRANCH_CONTRACT: "UN_FOLLOW_MAIN_BRANCH_CONTRACT",
  BROKER: "BROKER",
  DECLARATIONS: "DECLARATIONS",
  DECLARED_PROCEDURES: "DECLARED_PROCEDURES",
  MEDICAL_DECLARATION_COMMENTS: "MEDICAL_DECLARATION_COMMENTS",
  MEDICAL_DECLARATION_ATTACHMENTS: "MEDICAL_DECLARATION_ATTACHMENTS",
  PROCEDURES: "PROCEDURES",
  REMOVE_ASSIGNMENT: "REMOVE_ASSIGNMENT",
  ME: "ME",
  CHECKIN_ALL: "CHECKIN_ALL",
  CHECKOUT_ALL: "CHECKOUT_ALL",
  RELINK_ALL: "RELINK_ALL",
  SYNC_FAST_CYCLE_ALL: "SYNC_FAST_CYCLE_ALL",
  MULTI_SELECT_ACTIONS: "MULTI_SELECT_ACTIONS",
  TRANSMITTAL_STATUS: "TRANSMITTAL_STATUS",
  CLAIM_STATUS: "CLAIM_STATUS",
  START: "START",
  PAUSE: "PAUSE",
  FAILED_STEPS: "FAILED_STEPS",
  FOLLOWING_CLAIMS_NOT_ADDED: "FOLLOWING_CLAIMS_NOT_ADDED",
  REGULAR_SEARCH: "REGULAR_SEARCH",
  SHOW_IN_LOCAL_LANGUAGE: "SHOW_IN_LOCAL_LANGUAGE",
  PENDING_TRANSMITTALS: "PENDING_TRANSMITTALS",
  COVERS: "COVERS",
  CLAIM_COVERS: "CLAIM_COVERS",
  INCLUDE_BENEFITS: "INCLUDE_BENEFITS",
  INCLUDE_CITIES: "INCLUDE_CITIES",
  GROUP_BY_BROKERS: "GROUP_BY_BROKERS",
  GROUP_BY_BENEFITS: "GROUP_BY_BENEFITS",
  GROUP_BY_CITIES: "GROUP_BY_CITIES",
  RESULT_STATUS: "RESULT_STATUS",
  ACTION: "ACTION",
  SANITY_CHECK: "SANITY_CHECK",
  PROVIDER_NO: "PROVIDER_NO",
  CLAIM_FORMS_COUNT: "CLAIM_FORMS_COUNT",
  TPA_REFERENCE: "TPA_REFERENCE",
  PVI_NUMBER: "PVI_NUMBER",
  BASE_SERVICES: "BASE_SERVICES",
  APPROVAL_IS_ONLINE: "APPROVAL_IS_ONLINE",
  TRANSMITTAL_ID: "TRANSMITTAL_ID",
  TPA_REF_NO: "TPA_REF_NO",
  BATCH_STATUS: "BATCH_STATUS",
  BATCH_CREATION_DATE: "BATCH_CREATION_DATE",
  TPA_REFERENCE_NUMBER: "TPA_REFERENCE_NUMBER",
  EXAMINATION_STATUS: "EXAMINATION_STATUS",
  PVI_ID: "PVI_ID",
  SUBMITTED_DATE: "SUBMITTED_DATE",
  SPECIALIZED: "SPECIALIZED",
  CURRENT_PRICE: "CURRENT_PRICE",
  ACTUAL_AMOUNT: "ACTUAL_AMOUNT",
  CLAIM_ACCEPTED_AMOUNT: "CLAIM_ACCEPTED_AMOUNT",
  APPROVED_AMOUNT: "APPROVED_AMOUNT",
  APPROVAL_INSURANCE_SHARE_AMOUNT: "APPROVAL_INSURANCE_SHARE_AMOUNT",
  CLAIM_TOTAL_AMOUNT_TO_PAY: "CLAIM_TOTAL_AMOUNT_TO_PAY",
  PROVIDER_REQUESTED_AMOUNT: "PROVIDER_REQUESTED_AMOUNT",
  SYSTEM_CLAIMED_AMOUNT: "SYSTEM_CLAIMED_AMOUNT",
  EMAIL_RECIPIENTS: "EMAIL_RECIPIENTS",
  EMAIL_ADDRESS: "EMAIL_ADDRESS",
  CLASSIFICATION: "CLASSIFICATION",
  MEMBERS: "MEMBERS",
  PROVIDER_GROUP_MEMBER: "PROVIDER_GROUP_MEMBER",
  CANCEL_CONTRACT: "CANCEL_CONTRACT",
  WORK_SPACE: "WORK_SPACE",
  PROVIDER_GROUP_MEMBER_WORK_SPACE: "PROVIDER_GROUP_MEMBER_WORK_SPACE",
  COMMIT: "COMMIT",
  SOLVE_DUPLICATES: "SOLVE_DUPLICATES",
  PLAN_RELATIONS: "PLAN_RELATIONS",
  RELATIONS: "RELATIONS",
  PLAN_CLASSES: "PLAN_CLASSES",
  PLAN_AGE_RANGES: "PLAN_AGE_RANGES",
  YEAR: "YEAR",
  DAY: "DAY",
  UNIT: "UNIT",
  PLAN_COUNTRIES: "PLAN_COUNTRIES",
  PLAN_CITY: "PLAN_CITY",
  WITHOUT_HEADER: "WITHOUT_HEADER",
  INCLUDE_INSURED_LIST: "INCLUDE_INSURED_LIST",
  INCLUDE_COVER_LIST: "INCLUDE_COVER_LIST",
  INCLUDE_REJECTION_SECTION: "INCLUDE_REJECTION_SECTION",
  INCLUDE_CLAIMS: "INCLUDE_CLAIMS",
  SHOW_SERVICES: "SHOW_SERVICES",
  REJECTED_CLAIMS_ONLY: "REJECTED_CLAIMS_ONLY",
  GROUP_BY: "GROUP_BY",
  HAS_ATTACHMENT: "HAS_ATTACHMENT",
  REJECTION_ONLY: "REJECTION_ONLY",
  FULLY_REJECTION_ONLY: "FULLY_REJECTION_ONLY",
  DETACH: "DETACH",
  PENDING_BATCHES: "PENDING_BATCHES",
  PREEXISTING_AND_CHRONIC: "PREEXISTING_AND_CHRONIC",
  LIMITS: "LIMITS",
  POLICY_TYPES: "POLICY_TYPES",
  RESOLVED: "RESOLVED",
  PROVIDER_NUMBER_DUPLICATES: "PROVIDER_NUMBER_DUPLICATES",
  PROVIDER_NUMBER_DUPLICATES_DETAILS: "PROVIDER_NUMBER_DUPLICATES_DETAILS",
  RESUME: "RESUME",
  RESOLVE: "RESOLVE",
  USE_TRANSMITTAL_COVER: "USE_TRANSMITTAL_COVER",
  ENDORSEMENTS: "ENDORSEMENTS",
  IN_PREFRERED_NETWORK: "IN_PREFRERED_NETWORK",
  ALLOW_CHRONIC_DISPENSATION: "ALLOW_CHRONIC_DISPENSATION",
  CHECKUP_DAYS: "CHECKUP_DAYS",
  SET_BY: "SET_BY",
  REMOVED_BY: "REMOVED_BY",
  REMOVAL_COMMENT: "REMOVAL_COMMENT",
  REMOVAL_REASON: "REMOVAL_REASON",
  UNDER_INVESTIGATION: "UNDER_INVESTIGATION",
  HIDE_AMOUNTS: "HIDE_AMOUNTS",
  HAS_CHRONIC_FORM: "HAS_CHRONIC_FORM",
  REQUIRES_EXTERNAL_REQUEST: "REQUIRES_EXTERNAL_REQUEST",
  DISABLE_THIRD_PARTY_PORTAL: "DISABLE_THIRD_PARTY_PORTAL",
  HIERARCHY: "HIERARCHY",
  MEDICINE: "MEDICINE",
  SERVICE_NATURE: "SERVICE_NATURE",
  SERVICE_SOURCE: "SERVICE_SOURCE",
  OPERATION: "OPERATION",
  UNAVAILABLE: "UNAVAILABLE",
  CONSULTATION: "CONSULTATION",
  REQUIRES_TOOTH_NUMBER: "REQUIRES_TOOTH_NUMBER",
  PRICED_ON_DISPENSATION: "PRICED_ON_DISPENSATION",
  SHOW_IN_ONLINE_PORTAL: "SHOW_IN_ONLINE_PORTAL",
  REQUIRES_RESULTS: "REQUIRES_RESULTS",
  REQUIRES_RESULT_ATTACHMENT: "REQUIRES_RESULT_ATTACHMENT",
  REQUIRES_RESULT_COMMENT: "REQUIRES_RESULT_COMMENT",
  FREQUENTLY_USED: "FREQUENTLY_USED",
  DRUG_MANUFACTURER: "DRUG_MANUFACTURER",
  PACK_UNIT_SIZE: "PACK_UNIT_SIZE",
  ALLOW_PARTIAL_DISPENSE: "ALLOW_PARTIAL_DISPENSE",
  SUB_PACKS_NUMBER: "SUB_PACKS_NUMBER",
  DISPENSATION_RULES: "DISPENSATION_RULES",
  SERVICE_PRICING: "SERVICE_PRICING",
  IS_GENERAL_EXCLUSION: "IS_GENERAL_EXCLUSION",
  IS_DETAIL: "IS_DETAIL",
  IS_ROOM_AND_BORED: "IS_ROOM_AND_BORED",
  SERVICE_TABLE_TYPE_CODE: "SERVICE_TABLE_TYPE_CODE",
  IS_MANDATORY: "IS_MANDATORY",
  IS_PRINTABLE: "IS_PRINTABLE",
  RULES: "RULES",
  WARNINGS: "WARNINGS",
  EPISODE_WARNING: "EPISODE_WARNING",
  RECURRENCE_WARNING: "RECURRENCE_WARNING",
  AUTOMATIC_REJECTION: "AUTOMATIC_REJECTION",
  CAN_BE_ADDED_TO_CHRONIC_FORM: "CAN_BE_ADDED_TO_CHRONIC_FORM",
  MAXIMUM_ACCEPTED_QUANTITY_PER_EPISODE:
    "MAXIMUM_ACCEPTED_QUANTITY_PER_EPISODE",
  MAXIMUM_ACCEPTED_SUB_PACKS_QUANTITY_PER_EPISODE:
    "MAXIMUM_ACCEPTED_SUB_PACKS_QUANTITY_PER_EPISODE",
  MAXIMUM_RECURRENCE: "MAXIMUM_RECURRENCE",
  MAXIMUM_RECURRENCE_PERIOD_DAYS: "MAXIMUM_RECURRENCE_PERIOD_DAYS",
  ENABLE_AUTOMATIC_REJECTION: "ENABLE_AUTOMATIC_REJECTION",
  CONTROL_PERIOD_DAYS: "CONTROL_PERIOD_DAYS",
  ALLOW_MULTIPLE_EPISODES_WITHIN_CONTROL_PERIOD:
    "ALLOW_MULTIPLE_EPISODES_WITHIN_CONTROL_PERIOD",
  MAXIMUM_SUB_PACKS_QUANTITY_WITHIN_CONTROL_PERIOD:
    "MAXIMUM_SUB_PACKS_QUANTITY_WITHIN_CONTROL_PERIOD",
  MAXIMUM_SUB_PACKS_QUANTITY_PER_YEAR: "MAXIMUM_SUB_PACKS_QUANTITY_PER_YEAR",
  AUTOMATICALLY_REJECT_IN_ACUTE_APPROVALS:
    "AUTOMATICALLY_REJECT_IN_ACUTE_APPROVALS",
  SERVICE_PROTOCOL_PRICING: "SERVICE_PROTOCOL_PRICING",
  OPERATION_CATEGORY: "OPERATION_CATEGORY",
  PARENT_PRICING_SERVICE: "PARENT_PRICING_SERVICE",
  PARENT_PRICING_SERVICE_CODE: "PARENT_PRICING_SERVICE_CODE",
  NUMBER_OF_UNITS: "NUMBER_OF_UNITS",
  PRICED_AS_PACKAGE: "PRICED_AS_PACKAGE",
  IS_DEFAULT_PRICING: "IS_DEFAULT_PRICING",
  PROVIDER_CITY: "PROVIDER_CITY",
  UNIT_BASED: "UNIT_BASED",
  SERVICE_PROTOCOL: "SERVICE_PROTOCOL",
  INGREDIENTS: "INGREDIENTS",
  SERVICE_CHEMICAL_COMPOUND_NAME: "SERVICE_CHEMICAL_COMPOUND_NAME",
  MEDICINE_INGREDIENT: "MEDICINE_INGREDIENT",
  INGREDIENT: "INGREDIENT",
  CHEMICAL_COMPOUND: "CHEMICAL_COMPOUND",
  PLAN_PRODUCT: "PLAN_PRODUCT",
  INSURANCE_PRODUCT: "INSURANCE_PRODUCT",
  IS_FOR_EXCEPTIONS: "IS_FOR_EXCEPTIONS",
  IS_FOR_EXCLUSIONS: "IS_FOR_EXCLUSION",
  INSURANCE_SUB_PRODUCTS: "INSURANCE_SUB_PRODUCTS",
  IS_FUND: "IS_FUND",
  INCLUDE_IN_LOSS_RATIO: "INCLUDE_IN_LOSS_RATIO",
  BENEFIT_PREMIUMS: "BENEFIT_PREMIUMS",
  FORCE_UPDATE: "FORCE_UPDATE",
  PREMIUM_REFUND: "PREMIUM_REFUND",
  REFUND_RULE: "REFUND_RULE",
  PLAN_CHARGE: "PLAN_CHARGE",
  ROUNDING_NUMBER: "ROUNDING_NUMBER",
  PLAN_TAX: "PLAN_TAX",

  TAX_DEPENDENT: "TAX_DEPENDENT",
  APPLY_ON_POLICY: "APPLY_ON_POLICY",
  APPLY_ON_ISSUANCE: "APPLY_ON_ISSUANCE",
  CALCULATE_TAX_ON_REFUND: "CALCULATE_TAX_ON_REFUND",
  REPEAT_ON_ENDORSEMENT: "REPEAT_ON_ENDORSEMENT",
  INCLUDE_BROKER_COMMISSION: "INCLUDE_BROKER_COMMISSION",
  TAX_DEPENDENCY: "TAX_DEPENDENCY",
  ENDORSEMENT: "ENDORSEMENT",
  APPLY_ON_POLICY_ISSUANCE: "APPLY_ON_POLICY_ISSUANCE",
  PLAN_TAX_DETAIL: "PLAN_TAX_DETAIL",
  NON_REFUNDABLE_AMOUNT_BRIEF: "NON_REFUNDABLE_AMOUNT_BRIEF",
  NON_REFUNDABLE_PERCENTAGE_BRIEF: "NON_REFUNDABLE_PERCENTAGE_BRIEF",
  NUMBER_MUST_BE_GREATER_THAN_ZERO: "NUMBER_MUST_BE_GREATER_THAN_ZERO",
  INHOUSE_ADMIN_COST_AMOUNT: "INHOUSE_ADMIN_COST_AMOUNT",
  INHOUSE_ADMIN_COST: "INHOUSE_ADMIN_COST",
  INHOUSE_ADMIN_COST_PERCENTAGE: "INHOUSE_ADMIN_COST_PERCENTAGE",
  TPA_RULE: "TPA_RULE",
  ADMINISTRATION_COST_INSTALLMENT: "ADMINISTRATION_COST_INSTALLMENT",
  BROKER_COMMISSION_INSTALLMENT: "BROKER_COMMISSION_INSTALLMENT",
  PREMIUM_FUNDER_INSTALLMENT: "PREMIUM_FUNDER_INSTALLMENT",
  ACTIVATE: "ACTIVATE",
  DEACTIVATE: "DEACTIVATE",
  PREMIUM_FUNDER_OPTIONS: "PREMIUM_FUNDER_OPTIONS",
  COPY_DETAILS: "COPY_DETAILS",
  COPY: "COPY",
  PRODUCT_COVERAGE: "PRODUCT_COVERAGE",
  PREEXISTING_LIMIT_PER_POLICY_BRIEF: "PREEXISTING_LIMIT_PER_POLICY_BRIEF",
  PREEXISTING_LIMIT_PER_INSURED_BRIEF: "PREEXISTING_LIMIT_PER_INSURED_BRIEF",
  PREEXISTING_AND_CHRONIC_LIMIT_PER_POLICY_BRIEF:
    "PREEXISTING_AND_CHRONIC_LIMIT_PER_POLICY_BRIEF",
  PREEXISTING_AND_CHRONIC_LIMIT_PER_INSURED_BRIEF:
    "PREEXISTING_AND_CHRONIC_LIMIT_PER_INSURED_BRIEF",

  NOT_COVERED: "NOT_COVERED",
  PLAN_BENEFIT_COVERAGE: "PLAN_BENEFIT_COVERAGE",
  TREAT_AS_ACUTE: "TREAT_AS_ACUTE",
  TREAT_AS_NEW: "TREAT_AS_NEW",
  WAITING_PERIOD_PER_MONTHS: "WAITING_PERIOD_PER_MONTHS",
  WAITING_PERIOD_JOIN_AFTER_ISSUANCE: "WAITING_PERIOD_JOIN_AFTER_ISSUANCE",
  CHRONIC_WAITING_PERIOD_PER_MONTHS: "CHRONIC_WAITING_PERIOD_PER_MONTHS",
  CHRONIC_WAITING_PERIOD_JOIN_AFTER_ISSUANCE:
    "CHRONIC_WAITING_PERIOD_JOIN_AFTER_ISSUANCE",
  CHRONIC_WAITING_PERIOD_FOR_NON_DECLARED:
    "CHRONIC_WAITING_PERIOD_FOR_NON_DECLARED",
  PREEXISTING_WAITING_PERIOD_PER_MONTHS:
    "PREEXISTING_WAITING_PERIOD_PER_MONTHS",
  PREEXISTING_WAITING_PERIOD_JOIN_AFTER_ISSUANCE:
    "PREEXISTING_WAITING_PERIOD_JOIN_AFTER_ISSUANCE",
  PREEXISTING_WAITING_PERIOD_FOR_NON_DECLARED:
    "PREEXISTING_WAITING_PERIOD_FOR_NON_DECLARED",
  UTILIZATION_RESET_PER_MONTHS: "UTILIZATION_RESET_PER_MONTHS",
  HOLDER_CITY_ONLY: "HOLDER_CITY_ONLY",
  HOLDER_COUNTRY_ONLY: "HOLDER_COUNTRY_ONLY",

  MAIN: "MAIN",
  ACUTE_LIMIT: "ACUTE_LIMIT",
  CHRONIC_LIMIT: "CHRONIC_LIMIT",
  PRODUCT_LIMITS: "PRODUCT_LIMITS",
  BENEFIT_LIMITS: "BENEFIT_LIMITS",
  FOLLOW_DEFAULT_LIMIT: "FOLLOW_DEFAULT_LIMIT",
  NUMBER_OF_ACUTE_CLAIMS_PER_INSURED: "NUMBER_OF_ACUTE_CLAIMS_PER_INSURED",
  LIMIT_PER_CLAIM: "LIMIT_PER_CLAIM",
  NUMBER_OF_CHRONIC_CLAIMS_PER_INSURED: "NUMBER_OF_CHRONIC_CLAIMS_PER_INSURED",
  PREEXISTING_AND_CHRONIC_LIMIT: "PREEXISTING_AND_CHRONIC_LIMIT",
  PREEXISTING_LIMIT: "PREEXISTING_LIMIT",

  DIRECT_BILLING: "DIRECT_BILLING",
  ACUTE_COVERAGE: "ACUTE_COVERAGE",
  ACUTE_COVERAGE_PERCENTAGE: "ACUTE_COVERAGE_PERCENTAGE",
  ACUTE_COPAYMENT: "ACUTE_COPAYMENT",
  ACUTE_ADDITIONAL_COPAYMENT: "ACUTE_ADDITIONAL_COPAYMENT",
  ACUTE_COPAYMENT_AS_DEBIT: "ACUTE_COPAYMENT_AS_DEBIT",
  CHRONIC_COVERAGE: "CHRONIC_COVERAGE",
  CHRONIC_COVERAGE_PERCENTAGE: "CHRONIC_COVERAGE_PERCENTAGE",
  CHRONIC_COPAYMENT: "CHRONIC_COPAYMENT",
  CHRONIC_ADDITIONAL_COPAYMENT: "CHRONIC_ADDITIONAL_COPAYMENT",
  CHRONIC_COPAYMENT_AS_DEBIT: "CHRONIC_COPAYMENT_AS_DEBIT",
  PREEXISTING_COVERAGE: "PREEXISTING_COVERAGE",
  PREEXISTING_PERCENTAGE: "PREEXISTING_PERCENTAGE",
  PREEXISTING_COPAYMENT: "PREEXISTING_COPAYMENT",
  PREEXISTING_ADDITIONAL_COPAYMENT: "PREEXISTING_ADDITIONAL_COPAYMENT",
  PREEXISTING_COPAYMENT_AS_DEBIT: "PREEXISTING_COPAYMENT_AS_DEBIT",

  REIMBURSEMENT_IN_TERRITORY: "REIMBURSEMENT_IN_TERRITORY",
  REIMBURSEMENT_OUT_TERRITORY: "REIMBURSEMENT_OUT_TERRITORY",
  REIMBURSEMENT_BENCHMARK: "REIMBURSEMENT_BENCHMARK",
  OTHER: "OTHER",
  PRIORITY: "PRIORITY",
  PROVIDER_CATEGORY: "PROVIDER_CATEGORY",
  BENEFIT_DETAILS: "BENEFIT_DETAILS",
  BENEFIT_DETAIL: "BENEFIT_DETAIL",
  POLICY_INSTALLMENTS: "POLICY_INSTALLMENTS",
  POLICY_INSTALLMENT: "POLICY_INSTALLMENT",

  EXCLUSION_CODE: "EXCLUSION_CODE",
  EXCLUSION_FROM_MONTH: "EXCLUSION_FROM_MONTH",
  EXCLUSION_TO_MONTH: "EXCLUSION_TO_MONTH",
  MAXIMUM_COVERAGE_PER_POLICY: "MAXIMUM_COVERAGE_PER_POLICY",
  DAYS_FROM_ORIGINAL_ACCIDENT: "DAYS_FROM_ORIGINAL_ACCIDENT",
  ORIGINAL_ACCIDENT_COVERED: "ORIGINAL_ACCIDENT_COVERED",
  FROM_ACCIDENT: "FROM_ACCIDENT",
  FROM_ADMISSION: "FROM_ADMISSION",
  FROM_DISCHARGE: "FROM_DISCHARGE",
  PLAN_EXCLUSION: "PLAN_EXCLUSION",
  EXCLUSION_ITEM: "EXCLUSION_ITEM",
  APPLY_ON_ACTIVE_POLICIES: "APPLY_ON_ACTIVE_POLICIES",
  DELETE_PLAN_EXCLUSION: "DELETE_PLAN_EXCLUSION",
  COPY_PLAN: "COPY_PLAN",
  PLAN_NAME: "PLAN_NAME",
  POST_ALL_PLAN_EXCLUSIONS: "POST_ALL_PLAN_EXCLUSIONS",
  POST: "POST",
  INHERIT_PLAN_EXCLUSION: "Inherit Plan Exclusion",
  INHERIT: "Inherit",
  COPY_DIAGNOSIS_EXCLUSIONS: "COPY_DIAGNOSIS_EXCLUSIONS",
  COPY_SERVICE_EXCLUSIONS: "COPY_SERVICE_EXCLUSIONS",
  INHERIT_EXCLUSIONS: "INHERIT_EXCLUSIONS",
  ADD_BULK: "ADD_BULK",
  CHECK_SUMMARY: "CHECK_SUMMARY",
  SUMMARY: "SUMMARY",
  REMOVE_ALL: "REMOVE_ALL",
  REMOVE_APPROVAL: "REMOVE_APPROVAL",
  SCANNED_COPIES: "SCANNED_COPIES",
  DELETE_ALL: "DELETE_ALL",
  DELETE_DIAGNOSIS_EXCLUSIONS: "DELETE_DIAGNOSIS_EXCLUSIONS",
  DELETE_SERVICE_EXCLUSIONS: "DELETE_SERVICE_EXCLUSIONS",
  SYNC: "SYNC",
  OVERRIDE_SENT_INVOICES_RULE: "OVERRIDE_SENT_INVOICES_RULE",
  SYNC_CONFIGURATION: "SYNC_CONFIGURATION",
  SYNC_TAXES: "SYNC_TAXES",
  SYNC_CHARGES: "SYNC_CHARGES",
  SYNC_ADMIN_COST: "SYNC_ADMIN_COST",
  SYNC_COVERAGES: "SYNC_COVERAGES",
  STEP: "STEP",
  SYNC_PLAN_CONFIGURATION: "SYNC_PLAN_CONFIGURATION",
  ROW_NUMBER: "ROW_NUMBER",
  INVALID_DATA: "INVALID_DATA",
  SERVICE_HIERARCHY: "SERVICE_HIERARCHY",
  SERVICE_CHAPTERS: "SERVICE_CHAPTERS",
  SERVICE_CATEGORIES: "SERVICE_CATEGORIES",
  OVERRIDE_GENERAL_RECURRENCE_RULE: "OVERRIDE_GENERAL_RECURRENCE_RULE",
  SERVICE_TYPES: "SERVICE_TYPES",
  CLAIM_REASON: "CLAIM_REASON",
  EXCLUSION_DETAILS: "EXCLUSION_DETAILS",
  EXCLUSION_LIMITS: "EXCLUSION_LIMITS",
  REMAINING_COUNT: "REMAINING_COUNT",

  COVERAGE_PER_INSURED: "COVERAGE_PER_INSURED",
  COVERAGE_PER_POLICY: "COVERAGE_PER_POLICY",
  COVERAGE_PER_CLAIM: "COVERAGE_PER_CLAIM",
  COVERAGE_PER_UNIT: "COVERAGE_PER_UNIT",
  QUANTITY_PER_INSURED: "QUANTITY_PER_INSURED",
  QUANTITY_PER_CLAIM: "QUANTITY_PER_CLAIM",
  QUANTITY_PER_SERVICE: "QUANTITY_PER_SERVICE",
  CLAIMS_PER_INSURED: "CLAIMS_PER_INSURED",
  CLAIMS_PER_POLICY: "CLAIMS_PER_POLICY",
  PARTIAL_EXCLUSIONS: "PARTIAL_EXCLUSIONS",
  TRANSMITTAL_CLAIM_WORKSPACE: "TRANSMITTAL_CLAIM_WORKSPACE",
  AUTHORIZATION_NUMBER: "AUTHORIZATION_NUMBER",
  INVOICE_NUMBER_DUPLICATES: "INVOICE_NUMBER_DUPLICATES",
  INVOICE_NUMBER_DUPLICATES_DETAILS: "INVOICE_NUMBER_DUPLICATES_DETAILS",
  SERIAL_DUPLICATES: "SERIAL_DUPLICATES",
  SERIAL_DUPLICATES_DETAILS: "SERIAL_DUPLICATES_DETAILS",
  AUTHORIZATION_NUMBER_DUPLICATES: "AUTHORIZATION_NUMBER_DUPLICATES",
  AUTHORIZATION_NUMBER_DUPLICATES_DETAILS:
    "AUTHORIZATION_NUMBER_DUPLICATES_DETAILS",
  SERVICE_DUPLICATES: "SERVICE_DUPLICATES",
  SERVICE_DUPLICATES_DETAILS: "SERVICE_DUPLICATES_DETAILS",
  APPROVAL_LEVEL: "APPROVAL_LEVEL",
  NEW_CLAIM_LEVEL: "NEW_CLAIM_LEVEL",
  SERVICE_LEVEL: "SERVICE_LEVEL",
  IS_IMPORTED_IN_TRANSMITTAL: "IS_IMPORTED_IN_TRANSMITTAL",
  EDI_ITEM: "EDI_ITEM",
  PROVIDER_SERVICE_CODE: "PROVIDER_SERVICE_CODE",
  MANUAL: "MANUAL",
  MAX_QUANTITY_PER_SERVICE: "MAX_QUANTITY_PER_SERVICE",
  MAX_QUANTITY_PER_CLAIM: "MAX_QUANTITY_PER_CLAIM",
  MAX_QUANTITY_PER_INSURED: "MAX_QUANTITY_PER_INSURED",
  MAX_NUMBER_OF_CLAIMS_PER_POLICY: "MAX_NUMBER_OF_CLAIMS_PER_POLICY",
  MAXIMUM_COVERAGE_PER_UNIT: "MAXIMUM_COVERAGE_PER_UNIT",
  APPLY_WAITING_PERIOD_ON_CONTINUING_INSURED:
    "APPLY_WAITING_PERIOD_ON_CONTINUING_INSURED",
  OPERATION_CATEGORIES: "OPERATION_CATEGORIES",
  SERVICE_TABLE_TYPES: "SERVICE_TABLE_TYPES",
  INCLUDE_DRAFTS: "INCLUDE_DRAFTS",
  INCLUDE_DRAFTS_STATUS: "INCLUDE_DRAFTS_STATUS",
  COMMITTED: "COMMITTED",
  COMMIT_DATE: "COMMIT_DATE",
  DELIVERY_ADDRESS: "DELIVERY_ADDRESS",
  AMOUNT_ESTIMATE: "AMOUNT_ESTIMATE",
  APPROVE_START_OF_MONTH_ONLY: "APPROVE_START_OF_MONTH_ONLY",
  PENDING_EVALUATION: "PENDING_EVALUATION",
  TO_BE_DELIVERED: "TO_BE_DELIVERED",
  LOCKED: "LOCKED",
  VALIDATE_DISPENSATION_AS_OF: "VALIDATE_DISPENSATION_AS_OF",
  DOSE_PER_PACK: "DOSE_PER_PACK",
  REMAINING_DOSE: "REMAINING_DOSE",
  REQUIRED_DOSE: "REQUIRED_DOSE",
  APPROVED_QUANTITY: "APPROVED_QUANTITY",
  APPROVED_DOSE: "APPROVED_DOSE",
  POLICY_START_DATE: "POLICY_START_DATE",
  APPLICATIONS: "APPLICATIONS",
  INSURANCE_COMPANY_BRANCH: "INSURANCE_COMPANY_BRANCH",
  APPLICATION_DATE: "APPLICATION_DATE",
  NEXT_BIRTHDAY_RULE_APPLIED: "NEXT_BIRTHDAY_RULE_APPLIED",
  EXPECTED_INSURED_COUNT: "EXPECTED_INSURED_COUNT",
  CURRENCY_LOCAL: "CURRENCY_LOCAL",
  CURRENCY_FOREIGN: "CURRENCY_FOREIGN",
  POLICY_GENERATED: "POLICY_GENERATED",
  CLIENT_INDIVIDUAL: "CLIENT_INDIVIDUAL",
  CLIENT_GROUP: "CLIENT_GROUP",
  PLAN_ID: "PLAN_ID",
  SYNC_BROKERS: "SYNC_BROKERS",
  APPLICATION_PLAN: "APPLICATION_PLAN",
  APPLICATION_BROKER: "APPLICATION_BROKER",
  BROKER_CONTRACT: "BROKER_CONTRACT",
  EXCEEDING_CLIENT_SHARE_LIMIT: "EXCEEDING_CLIENT_SHARE_LIMIT",
  PRIMARY_INSURED_ONLY: "PRIMARY_INSURED_ONLY",
  APPLICATION_PREMIUM_FUNDER: "APPLICATION_PREMIUM_FUNDER",
  SYSTEM_RECOMMENDED_NRP: "SYSTEM_RECOMMENDED_NRP",
  ACCEPTED_NRP: "ACCEPTED_NRP",
  ADDITIONAL_PREMIUMS: "ADDITIONAL_PREMIUMS",
  ADDITIONAL_PREMIUM_DETAILS: "ADDITIONAL_PREMIUM_DETAILS",
  POLICY_HEADER: "POLICY_HEADER",
  CAN_SUBMIT_APPROVAL_REQUEST: "CAN_SUBMIT_APPROVAL_REQUEST",
  CAN_SUBMIT_REIMBURSEMENT_REQUEST: "CAN_SUBMIT_REIMBURSEMENT_REQUEST",
  CAN_SUBMIT_APPOINTMENT: "CAN_SUBMIT_APPOINTMENT",
  INSURED_MOBILE_PERMISSIONS: "INSURED_MOBILE_PERMISSIONS",
  INSURED_MOBILE_PERMISSION: "INSURED_MOBILE_PERMISSION",
  IMPORT: "IMPORT",
  REQUIRED_DOSE_DAYS: "REQUIRED_DOSE_DAYS",
  DURATION: "DURATION",
  FOLLOWUP_BY: "FOLLOWUP_BY",
  REJECTION_DATE: "REJECTION_DATE",
  APPLICATION_INSURED: "APPLICATION_INSURED",

  PIN_CODE: "PIN_CODE",
  FULL_NAME: "FULL_NAME",
  PRIMARY_INSURED: "PRIMARY_INSURED",
  PAYER_INSURED_ID: "PAYER_INSURED_ID",
  FIRST_GENERATION_DATE: "FIRST_GENERATION_DATE",
  CARD_TO_ACTIVATE: "CARD_TO_ACTIVATE",
  GENERATED: "GENERATED",
  TO_READD: "TO_READD",
  MAXIMUM_CLIENT_SHARE: "MAXIMUM_CLIENT_SHARE",
  DEPENDANT_INSURED: "DEPENDANT_INSURED",
  APPLICATION_WORKSPACE: "APPLICATION_WORKSPACE",
  MATCH_IN_SAME_APPLICATION_ONLY: "MATCH_IN_SAME_APPLICATION_ONLY",
  MOBILE_PERMISSIONS: "MOBILE_PERMISSIONS",
  MOBILE_PERMISSION: "MOBILE_PERMISSION",
  BROKER_ID: "BROKER_ID",
  PLACE_OF_BIRTH: "PLACE_OF_BIRTH",
  STOP_SMS_NOTIFICATION: "STOP_SMS_NOTIFICATION",
  BLOOD_TYPE: "BLOOD_TYPE",
  REQUESTED_JOIN_DATE: "REQUESTED_JOIN_DATE",
  PAYER_JOIN_DATE: "PAYER_JOIN_DATE",
  HEIGHT: "HEIGHT",
  WEIGHT: "WEIGHT",
  BMI: "BMI",
  MARITAL_STATUS: "MARITAL_STATUS",
  PROFESSION: "PROFESSION",
  EVALUATION_VALID: "EVALUATION_VALID",
  OVERRIDE_AGE: "OVERRIDE_AGE",
  NUMBER_OF_COVERED_FAMILY_MEMBERS: "NUMBER_OF_COVERED_FAMILY_MEMBERS",
  ALLOW_MOBILE_REIMBURSEMENT_REQUEST: "ALLOW_MOBILE_REIMBURSEMENT_REQUEST",
  UNDERWRITING_COMMENT: "UNDERWRITING_COMMENT",
  PREEX_MEDICAL_CASES: "PREEX_MEDICAL_CASES",
  PICTURES: "PICTURES",
  IS_CHECKUP: "IS_CHECKUP",
  REFERRAL_ALERT: "REFERRAL_ALERT",
  OVERRIDE_REFERRAL_ALERT: "OVERRIDE_REFERRAL_ALERT",
  DEPENDENTS: "DEPENDENTS",
  UNSET_VIP: "UNSET_VIP",
  SET_PRIMARY: "SET_PRIMARY",
  ASSIGN_PRIMARY: "ASSIGN_PRIMARY",
  UNDO_READD: "UNDO_READD",
  UPLOAD_PICTURE: "UPLOAD_PICTURE",
  EXISTING_PICTURE: "EXISTING_PICTURE",
  UPLOAD_NEW: "UPLOAD_NEW",
  PICTURE_ID: "PICTURE_ID",
  INSURED_PREEXISTING_MEDICAL_CASE: "INSURED_PREEXISTING_MEDICAL_CASE",
  DECLARED_PROCEDURE: "DECLARED_PROCEDURE",
  CONTRACT: "CONTRACT",
  READD: "READD",
  APPLICATION_WORKSPACE_ADDITION: "APPLICATION_WORKSPACE_ADDITION",
  APPLICATION_WORKSPACE_INSURED_INFORMATION_UPDATE:
    "APPLICATION_WORKSPACE_INSURED_INFORMATION_UPDATE",
  APPLICATION_WORKSPACE_INSURED_MATCH: "APPLICATION_WORKSPACE_INSURED_MATCH",
  IDENTITY_DUPLICATES: "IDENTITY_DUPLICATES",
  CARD_NUMBER_DUPLICATES: "CARD_NUMBER_DUPLICATES",
  NAME_DUPLICATES: "NAME_DUPLICATES",
  MATCH_DUPLICATES: "MATCH_DUPLICATES",
  IDENTITY_DUPLICATES_DETAILS: "IDENTITY_DUPLICATES_DETAILS",
  CARD_NUMBER_DUPLICATES_DETAILS: "CARD_NUMBER_DUPLICATES_DETAILS",
  NAME_DUPLICATES_DETAILS: "NAME_DUPLICATES_DETAILS",
  MATCH_DUPLICATES_DETAILS: "MATCH_DUPLICATES_DETAILS",
  MAP: "MAP",
  MAPPING: "MAPPING",
  SUPPORT_REQUESTS: "SUPPORT_REQUESTS",
  STAKEHOLDER_TYPE: "STAKEHOLDER_TYPE",
  MEDIUM: "MEDIUM",
  MEDIUM_DETAILS: "MEDIUM_DETAILS",
  REQUEST_SOURCE: "REQUEST_SOURCE",
  PARENT_ID: "PARENT_ID",
  EMERGENCY: "EMERGENCY",
  HAS_MAIN_REQUEST: "HAS_MAIN_REQUEST",
  NOTIFY_VIA_EMAIL: "NOTIFY_VIA_EMAIL",
  NOTIFY_VIA_SMS: "NOTIFY_VIA_SMS",
  REFERENCE_NO: "REFERENCE_NO",
  PHONE: "PHONE",
  RESOLUTION: "RESOLUTION",
  STAKEHOLDERS: "STAKEHOLDERS",
  ACTION_HISTORY: "ACTION_HISTORY",
  RELATED_REQUEST: "RELATED_REQUEST",
  ATTACHED_ENTITIES: "ATTACHED_ENTITIES",
  TAGS: "TAGS",
  RECIPIENT_TYPE: "RECIPIENT_TYPE",
  VISIBLE_TO_USER: "VISIBLE_TO_USER",
  EMAIL_KEY: "EMAIL_KEY",
  FROM_EMAIL_ADDRESS: "FROM_EMAIL_ADDRESS",
  SUBJECT: "SUBJECT",
  RELATED_EMAILS: "RELATED_EMAILS",
  PENDING_REQUESTS: "PENDING_REQUESTS",
  PHYSICIAN_SPECIALTY: "PHYSICIAN_SPECIALTY",
  ELAPSED_PERIOD: "ELAPSED_PERIOD",
  CONFIRMED: "CONFIRMED",
  CONFIRMED_BY: "CONFIRMED_BY",
  APPOINTMENTS: "APPOINTMENTS",
  ADD_DEFAULT_TREATMENT_DOCTOR: "ADD_DEFAULT_TREATMENT_DOCTOR",
  RECEIVED_FROM: "RECEIVED_FROM",
  REQUEST_DOCUMENT_NUMBER: "REQUEST_DOCUMENT_NUMBER",
  PACKS: "PACKS",
  LOCKED_BY: "LOCKED_BY",
  PVI_GROUPS: "PVI_GROUPS",
  PVIS: "PVIS",
  CDV_GROUPS: "CDV_GROUPS",
  CDVS: "CDVS",
  DEBIT_INVOICES: "DEBIT_INVOICES",
  OPEN_PACK: "OPEN_PACK",
  PVP_GROUPS: "PVP_GROUPS",
  PVPS: "PVPS",
  GENERATE_PVI: "GENERATE_PVI",
  GENERATE_CDV: "GENERATE_CDV",
  DELIVER: "DELIVER",
  SYNC_MOBILE: "SYNC_MOBILE",
  DOCUMENTS: "DOCUMENTS",
  SERVICE_BALANCE: "SERVICE_BALANCE",
  DATE_FIRST_NOTICED: "DATE_FIRST_NOTICED",
  NON_DEFINED_MEDICAL_CASE: "NON_DEFINED_MEDICAL_CASE",
  NEW_ATTACHMENT: "NEW_ATTACHMENT",
  EXISTING_ATTACHMENT: "EXISTING_ATTACHMENT",
  ATTACHMENT: "ATTACHMENT",
  DOCUMENT: "DOCUMENT",
  LINK: "LINK",
  UN_LINK: "UN_LINK",
  ALTERNATIVE_SERVICE: "ALTERNATIVE_SERVICE",
  PACKS_PER_MONTH: "PACKS_PER_MONTH",
  AS_REQUESTED: "AS_REQUESTED",
  IN: "IN",
  STAKEHOLDER_DESCRIPTION: "STAKEHOLDER_DESCRIPTION",
  SET_ACCEPTED_AS_PRE_REQUESTED: "SET_ACCEPTED_AS_PRE_REQUESTED",
  UN_SET_ACCEPTED_AS_PRE_REQUESTED: "UN_SET_ACCEPTED_AS_PRE_REQUESTED",
  REFRESH_SERVICES_EXCLUSIONS: "REFRESH_SERVICES_EXCLUSIONS",
  SUB_REQUEST: "SUB_REQUEST",
  ASSIGNEE: "ASSIGNEE",
  MAIN_REQUEST: "MAIN_REQUEST",
  REMOVE_MAIN_REQUEST: "REMOVE_MAIN_REQUEST",
  STAKEHOLDER_MESSAGE: "STAKEHOLDER_MESSAGE",
  RE_OPEN: "RE_OPEN",
  ACCEPT_ALL: "ACCEPT_ALL",
  UPDATE_INFO: "UPDATE_INFO",
  FULL_MATCHES: "FULL_MATCHES",
  PARTIAL_MATCH_NAME_DATE_GENDER: "PARTIAL_MATCH_NAME_DATE_GENDER",
  PARTIAL_MATCH_NAME_DATE: "PARTIAL_MATCH_NAME_DATE",
  PARTIAL_MATCH_IDENTITY: "PARTIAL_MATCH_IDENTITY",
  MATCH_DETAILS: "MATCH_DETAILS",
  APPLICATION_ID: "APPLICATION_ID",
  CONTRACT_NAME: "CONTRACT_NAME",
  FULL_MATCHES_EXCLUDE_CARD_NUMBER: "FULL_MATCHES_EXCLUDE_CARD_NUMBER",
  FULL_MATCHES_INCLUDE_CARD_NUMBER: "FULL_MATCHES_INCLUDE_CARD_NUMBER",
  PARTIAL_MATCH_CARD: "PARTIAL_MATCH_CARD",
  READY_FOR_ADDITION: "READY_FOR_ADDITION",
  READY_FOR_READDITION: "READY_FOR_READDITION",
  APPROVE_ALL: "APPROVE_ALL",
  UNAPPROVE_ALL: "UNAPPROVE_ALL",
  GENERATE_APPROVED: "GENERATE_APPROVED",
  APPLICATION_GENERATION_SUMMARY: "APPLICATION_GENERATION_SUMMARY",
  NEW_INSURED: "NEW_INSURED",
  READDED_INSURED: "READDED_INSURED",
  INSURED_READY_FOR_ACTIVATION: "INSURED_READY_FOR_ACTIVATION",
  APPROVED_INSURED: "APPROVED_INSURED",
  INSURED_WITH_DEFAULT_JOIN_DATE: "INSURED_WITH_DEFAULT_JOIN_DATE",
  DEFAULT_JOIN_DATE: "DEFAULT_JOIN_DATE",
  IMPORT_PICTURES: "IMPORT_PICTURES",
  IDENTIFIER_TYPE: "IDENTIFIER_TYPE",
  UPLOADING_PROGRESS: "UPLOADING_PROGRESS",
  REINSURER: "REINSURER",

  CARD_TYPE: "CARD_TYPE",
  END_DATE_AS_PLAN: "END_DATE_AS_PLAN",
  BASE_INSURED_COUNT: "BASE_INSURED_COUNT",
  BENEFIT_COVERAGE_PROPORTIONAL: "BENEFIT_COVERAGE_PROPORTIONAL",
  PREMIUM_INSURED_AGE_YEARS: "PREMIUM_INSURED_AGE_YEARS",
  POLICY_HOLDER_2: "POLICY_HOLDER_2",
  IN_ACTIVE: "IN_ACTIVE",
  FUNDS: "FUNDS",
  CLAIM_TYPE_RULES: "CLAIM_TYPE_RULES",
  FEATURES: "FEATURES",
  RANE_CONFIGURATION: "RANE_CONFIGURATION",
  CONFIGURATION: "CONFIGURATION",
  REVISION_AMOUNT_LOCAL: "REVISION_AMOUNT_LOCAL",
  REVISION_AMOUNT_FOREIGN: "REVISION_AMOUNT_FOREIGN",
  FEATURE: "FEATURE",
  ADDED_IN_CURRENT_ENDORSEMENT: "ADDED_IN_CURRENT_ENDORSEMENT",
  ONLY_CURRENT_ENDORSEMENT: "ONLY_CURRENT_ENDORSEMENT",
  IS_ADDITION: "IS_ADDITION",
  IS_COPIED_ON_RENEWAL: "IS_COPIED_ON_RENEWAL",
  FEATURE_DETAILS: "FEATURE_DETAILS",
  TPA_RULES: "TPA_RULES",
  PREMIUM_VALUE: "PREMIUM_VALUE",
  PER_CLAIM: "PER_CLAIM",
  CLAIM_VALUE: "CLAIM_VALUE",
  INCLUDE_TAXES: "INCLUDE_TAXES",
  POLICY_ADMINISTRATION_COST_INSTALLMENT_RULES:
    "POLICY_ADMINISTRATION_COST_INSTALLMENT_RULES",
  POLICY_ADMINISTRATION_COST_INSTALLMENTS:
    "POLICY_ADMINISTRATION_COST_INSTALLMENTS",
  TAX_AMOUNT: "TAX_AMOUNT",
  INSTALLMENT_CODE: "INSTALLMENT_CODE",
  ENDORSEMENT_SERIAL: "ENDORSEMENT_SERIAL",
  PREMIUM_SHARE: "PREMIUM_SHARE",
  INSTALLMENT_RULES: "INSTALLMENT_RULES",
  BROKER_AMOUNT: "BROKER_AMOUNT",
  CHARGE_AMOUNT: "CHARGE_AMOUNT",
  ISSUED: "ISSUED",
  LATEST_CARD: "LATEST_CARD",
  CARD_GENERATED: "CARD_GENERATED",
  MAXIMUM_AMOUNT: "MAXIMUM_AMOUNT",
  UN_AUDIT: "UN_AUDIT",
  RESYNC_BENEFIT_MAPPING: "RESYNC_BENEFIT_MAPPING",
  SYNC_PAYER_DATA: "SYNC_PAYER_DATA",
  FOLLOWING_PVIS_NOT_AUDITED: "FOLLOWING_PVIS_NOT_AUDITED",
  UN_COMMITTED: "UN_COMMITTED",
  IMPORT_DIAGNOSES_AND_SERVICES: "IMPORT_DIAGNOSES_AND_SERVICES",
  EXCLUDE_REJECTED_APPROVALS: "EXCLUDE_REJECTED_APPROVALS",
  PROCESSED_APPROVALS_ONLY: "PROCESSED_APPROVALS_ONLY",
  INCLUCE_BRANCH_APPROVALS: "INCLUCE_BRANCH_APPROVALS",
  SINGLE_SELECTION: "SINGLE_SELECTION",
  MULTIPLE_SELECTION: "MULTIPLE_SELECTION",
  ATTACHED_ENTITY: "ATTACHED_ENTITY",
  ENTITY_NAME: "ENTITY_NAME",
  ENTITY: "ENTITY",
  SET_MAIN_REQUEST: "SET_MAIN_REQUEST",
  CREATE_SUB_REQUEST: "CREATE_SUB_REQUEST",
  DIAGNOSIS_HIERARCHY: "DIAGNOSIS_HIERARCHY",
  DIAGNOSIS_CHAPTERS: "DIAGNOSIS_CHAPTERS",
  SOURCE: "SOURCE",
  PREEXISTING_ALERT_PERIOD_MONTHS: "PREEXISTING_ALERT_PERIOD_MONTHS",
  SHOW_ONLINE: "SHOW_ONLINE",
  GENERIC: "GENERIC",
  MEDICAL_CONDITION: "MEDICAL_CONDITION",
  DIAGNOSIS_CATEGORIES: "DIAGNOSIS_CATEGORIES",
  DIAGNOSIS_BLOCKS: "DIAGNOSIS_BLOCKS",
  USED_IN_INSURED_EVALUATION: "USED_IN_INSURED_EVALUATION",
  RELATED_CASES: "RELATED_CASES",
  CASE_RATES: "CASE_RATES",
  DOCUMENT_GROUPS: "DOCUMENT_GROUPS",
  SUB_CATEGORIES: "SUB_CATEGORIES",
  LATEST_RENEWAL: "LATEST_RENEWAL",
  REVISION_INSURED: "REVISION_INSURED",
  POLICY_INFO: "POLICY_INFO",
  REVISION_INFO: "REVISION_INFO",
  APPROVAL_ALERT_LEVEL: "APPROVAL_ALERT_LEVEL",
  APPROVAL_ALERT_LEVEL_COMMENT: "APPROVAL_ALERT_LEVEL_COMMENT",
  APPROVAL_ALERT_LEVEL_SET_BY: "APPROVAL_ALERT_LEVEL_SET_BY",
  DENY_AUTOMATIC_APPROVAL: "DENY_AUTOMATIC_APPROVAL",
  SUSPEND_APPROVALS: "SUSPEND_APPROVALS",
  REQUIRES_REJECTION_NOTIFICATION: "REQUIRES_REJECTION_NOTIFICATION",
  APPROVAL_CONFIGURATION: "APPROVAL_CONFIGURATION",
  ALERT_LEVEL: "ALERT_LEVEL",
  DISPENSE_CHRONIC_TO_END_DATE_ONLY: "DISPENSE_CHRONIC_TO_END_DATE_ONLY",
  USE_LATEST_CARD_NUMBER: "USE_LATEST_CARD_NUMBER",
  GENERATE_NEW_CARDS: "GENERATE_NEW_CARDS",
  SET_GENERATED_CARD_AS_PRINTED: "SET_GENERATED_CARD_AS_PRINTED",
  USE_PREFERRED_CARD_NUMBER: "USE_PREFERRED_CARD_NUMBER",
  NEWLY_GENERATED_INSURANCE_CARDS: "NEWLY_GENERATED_INSURANCE_CARDS",
  AGE_AND_RANGE: "AGE_AND_RANGE",
  COVERAGE_PLAN_SUB_PRODUCT: "COVERAGE_PLAN_SUB_PRODUCT",
  LOCAL_AMOUNTS: "LOCAL_AMOUNTS",
  FOREIGN_AMOUNTS: "FOREIGN_AMOUNTS",
  POLICY_CHARGE: "POLICY_CHARGE",
  POLICY_TAX: "POLICY_TAX",
  POLICY_TAX_DETAIL: "POLICY_TAX_DETAIL",
  POLICY_FEATURE: "POLICY_FEATURE",
  COPY_ON_RENEWAL: "COPY_ON_RENEWAL",
  CURRENT_REVISION_INSURED_ONLY: "CURRENT_REVISION_INSURED_ONLY",
  PVP_GROUP: "PVP_GROUP",
  PVC_GROUP: "PVC_GROUP",
  PVC_GROUPS: "PVC_GROUPS",
  PVCS: "PVCS",
  NUMBER_OF_PVPS: "NUMBER_OF_PVPS",
  NUMBER_OF_PVCS: "NUMBER_OF_PVCS",
  AUDIT: "AUDIT",
  PENDING_CLIENT_PAYMENTS: "PENDING_CLIENT_PAYMENTS",
  EXPECTED_AMOUNT: "EXPECTED_AMOUNT",
  IS_CUSTOM_REBATE: "IS_CUSTOM_REBATE",
  CUSTOM_REBATE_AMOUNT: "CUSTOM_REBATE_AMOUNT",
  CUSTOM_REBATE_PERCENTAGE: "CUSTOM_REBATE_PERCENTAGE",
  REBATE_LOCAL: "REBATE_LOCAL",
  REBATE_FOREIGN: "REBATE_FOREIGN",
  COLLECTED_AMOUNT_LOCAL: "COLLECTED_AMOUNT_LOCAL",
  COLLECTED_AMOUNT_FOREIGN: "COLLECTED_AMOUNT_FOREIGN",
  CUSTOM_REBATE: "CUSTOM_REBATE",
  DOCUMENT_GROUP: "DOCUMENT_GROUP",
  EXPORT_MEDICAL_CASES: "EXPORT_MEDICAL_CASES",
  BUILT_IN: "BUILT_IN",
  NEEDS_TO_BE_UPDATED: "NEEDS_TO_BE_UPDATED",
  SUPPORTS_APPROVAL_CREATION: "SUPPORTS_APPROVAL_CREATION",
  SHOW_TO_THIRD_PARTY_USER: "SHOW_TO_THIRD_PARTY_USER",
  REQUEST_TYPE_RESOLUTION: "REQUEST_TYPE_RESOLUTION",
  REQUIRES_WORKFLOW_OBJECT: "REQUIRES_WORKFLOW_OBJECT",
  WORKFLOW_OBJECT_CLASS_ID: "WORKFLOW_OBJECT_CLASS_ID",
  EMAIL_TEMPLATE: "EMAIL_TEMPLATE",
  DELETE_EMAIL_TEMPLATE: "DELETE_EMAIL_TEMPLATE",
  POLICY_FEATURE_DETAIL: "POLICY_FEATURE_DETAIL",
  RULE: "RULE",
  INSTALLMENT: "INSTALLMENT",
  CANCEL_APPLICATION_PREMIUM_FUNDER: "CANCEL_APPLICATION_PREMIUM_FUNDER",
  REVISE: "REVISE",
  PLAN_PRODUCT_COVERAGE: "PLAN_PRODUCT_COVERAGE",
  POLICY_BENEFIT_COVERAGE: "POLICY_BENEFIT_COVERAGE",
  POLICY_CLAIM_TYPE_RULE: "POLICY_CLAIM_TYPE_RULE",
  REMOVE_FROM_REVISION: "REMOVE_FROM_REVISION",
  ADD_TO_REVISION: "ADD_TO_REVISION",
  FOLLOWING_PVPS_NOT_AUDITED: "FOLLOWING_PVPS_NOT_AUDITED",
  SYNDICATE_SHARE: "SYNDICATE_SHARE",
  APPLY_PENALTY: "APPLY_PENALTY",
  REMOVE_PENALTY: "REMOVE_PENALTY",
  HAS_CUSTOM_DISCOUNT: "HAS_CUSTOM_DISCOUNT",
  CUSTOM_DISCOUNT: "CUSTOM_DISCOUNT",
  CUSTOM_DISCOUNT_PERCENTAGE: "CUSTOM_DISCOUNT_PERCENTAGE",
  BANK_ACCOUNT_HOLDER: "BANK_ACCOUNT_HOLDER",
  BANK_ACCOUNT_NUMBER: "BANK_ACCOUNT_NUMBER",
  EXPEDITION_INFO: "EXPEDITION_INFO",
  EXPEDITED_BY: "EXPEDITED_BY",
  EXPEDITION_DATE: "EXPEDITION_DATE",
  EXPEDITION_REASON: "EXPEDITION_REASON",
  PVP_EARLY_PAYMENT_DISCOUNT: "PVP_EARLY_PAYMENT_DISCOUNT",
  PVP_EARLY_PAYMENT_DISCOUNT_PERCENTAGE:
    "PVP_EARLY_PAYMENT_DISCOUNT_PERCENTAGE",
  PENALTY_APPLIED_BY: "PENALTY_APPLIED_BY",
  PENALTY_REASON: "PENALTY_REASON",
  PAID_BY: "PAID_BY",
  GENERATED_FROM_PV_GROUP: "GENERATED_FROM_PV_GROUP",
  RESET_AS_SETTLED: "RESET_AS_SETTLED",
  RESET_AS_COLLECTED: "RESET_AS_COLLECTED",
  UPDATE_BANK_ACCOUNT: "UPDATE_BANK_ACCOUNT",
  PRINT_CARDS: "PRINT_CARDS",
  ALL: "ALL",
  PRINT_CARDS_IN_CURRENT_REVISION: "PRINT_CARDS_IN_CURRENT_REVISION",
  SELECTED_CARDS: "SELECTED_CARDS",
  HOLDER: "HOLDER",
  CARD: "CARD",
  ROLLBACK_PRINT: "ROLLBACK_PRINT",
  INSURED_CARD: "INSURED_CARD",
  FOLLOWING_PVCS_NOT_AUDITED: "FOLLOWING_PVCS_NOT_AUDITED",
  BILL_BY_INSURED: "BILL_BY_INSURED",
  BILL_BY_BRANCH: "BILL_BY_BRANCH",
  BILL_BY_CLIENT: "BILL_BY_CLIENT",
  CDV_GROUP: "CDV_GROUP",
  TOTAL_ADMIN_COST: "TOTAL_ADMIN_COST",
  GENERATE_INVOICES_PER_INSURED: "GENERATE_INVOICES_PER_INSURED",
  EXCLUDED_CLAIMS_NUMBER: "EXCLUDED_CLAIMS_NUMBER",
  EXCLUDED_CLAIMS_AMOUNT: "EXCLUDED_CLAIMS_AMOUNT",
  EXCLUDE_CLAIMS: "EXCLUDE_CLAIMS",
  EXCLUDE_FROM_CDV: "EXCLUDE_FROM_CDV",
  INCLUDE_TO_CDV: "INCLUDE_TO_CDV",
  FOLLOWING_PVPS_NOT_ADDED: "FOLLOWING_PVPS_NOT_ADDED",
  ACTIVATIONS: "ACTIVATIONS",
  DELIVERIES: "DELIVERIES",
  MANAGE_CARD: "MANAGE_CARD",
  DELIVERED_BY: "DELIVERED_BY",
  ACTIVATED_BY: "ACTIVATED_BY",
  PRINTED_BY: "PRINTED_BY",
  PRINT_DATE: "PRINT_DATE",
  RETURNED_BY: "RETURNED_BY",
  ALIAS_CARD_NUMBER: "ALIAS_CARD_NUMBER",
  PRINTED: "PRINTED",
  DELIVERED: "DELIVERED",
  RETURNED: "RETURNED",
  ROLLBACK_ACTIVATION: "ROLLBACK_ACTIVATION",
  ROLLBACK_CANCELLATION: "ROLLBACK_CANCELLATION",
  ROLLBACK_DELIVER: "ROLLBACK_DELIVER",
  ROLLBACK_RETURN: "ROLLBACK_RETURN",
  DELIVER_CARD: "DELIVER_CARD",
  CANCEL_CARD: "CANCEL_CARD",
  MANAGE_INSURED_CARD: "MANAGE_INSURED_CARD",
  AMOUNT_LOCAL: "AMOUNT_LOCAL",
  AMOUNT_FOREIGN: "AMOUNT_FOREIGN",
  RENEWAL_SERIAL: "RENEWAL_SERIAL",
  SYNC_BENEFIT_COVERAGE: "SYNC_BENEFIT_COVERAGE",
  APPLY_PROVISION_MODIFICATIONS: "APPLY_PROVISION_MODIFICATIONS",
  TASKS: "TASKS",
  ADD_INSURED: "ADD_INSURED",
  ACTIVE_INSURED_ONLY: "ACTIVE_INSURED_ONLY",
  ONLY_INSURED_FOLLOWING_DEFAULT_BENEFITS:
    "ONLY_INSURED_FOLLOWING_DEFAULT_BENEFITS",
  FOLLOW_UP: "FOLLOW_UP",
  AFFECTED_BY_ENDORSEMENT: "AFFECTED_BY_ENDORSEMENT",
  MEDIUMS: "MEDIUMS",
  SYSTEM_PORTAL: "SYSTEM_PORTAL",
  CATEGORIES: "CATEGORIES",
  ASSIGNMENT_RULES: "ASSIGNMENT_RULES",
  TEAM: "TEAM",
  ASSIGN_TO_AVAILABLE_USER: "ASSIGN_TO_AVAILABLE_USER",
  MAXIMUM_REQUESTS_PER_USER: "MAXIMUM_REQUESTS_PER_USER",
  ENABLED: "ENABLED",
  MAXIMUM_IDLE_TIME_PER_MINS: "MAXIMUM_IDLE_TIME_PER_MINS",
  REQUEST_ASSIGNMENT_RULE: "REQUEST_ASSIGNMENT_RULE",
  FOLLOWING_PVCS_NOT_ADDED: "FOLLOWING_PVCS_NOT_ADDED",
  REQUESTED_PRICE: "REQUESTED_PRICE",
  PRICE_UPDATE_REQUEST: "PRICE_UPDATE_REQUEST",
  ACTIVE_PROVIDER: "ACTIVE_PROVIDER",
  ACTIVE_PROVIDER_CITY: "ACTIVE_PROVIDER_CITY",
  APPROVAL_PROVIDER: "APPROVAL_PROVIDER",
  APPROVAL_PROVIDER_CITY: "APPROVAL_PROVIDER_CITY",
  CHRONIC_FORM_STATUS: "CHRONIC_FORM_STATUS",
  TREATMENT_DOCTOR_SPECIALTY: "TREATMENT_DOCTOR_SPECIALTY",
  TREATMENT_DOCTOR_GROUP: "TREATMENT_DOCTOR_GROUP",
  FROM_RECEIPT_DATE: "FROM_RECEIPT_DATE",
  TO_RECEIPT_DATE: "TO_RECEIPT_DATE",
  FROM_START_DATE: "FROM_START_DATE",
  TO_START_DATE: "TO_START_DATE",
  FROM_END_DATE: "FROM_END_DATE",
  TO_END_DATE: "TO_END_DATE",
  INCLUDE_SERVICES: "INCLUDE_SERVICES",
  INCLUDE_DIAGNOSIS: "INCLUDE_DIAGNOSIS",
  ONLY_NEW_MEDICAL_CASES: "ONLY_NEW_MEDICAL_CASES",
  HAS_APPROVAL: "HAS_APPROVAL",
  MANAGE_INSURED: "MANAGE_INSURED",
  INSURED_INFO: "INSURED_INFO",
  ORIGINAL_MAX_CLIENT_SHARE: "ORIGINAL_MAX_CLIENT_SHARE",

  NET_COMMISSION: "NET_COMMISSION",
  PREMIUM_CONTRACTOR: "PREMIUM_CONTRACTOR",
  TOTAL_CLAIMS: "TOTAL_CLAIMS",
  REFUND: "REFUND",
  GROSS_PREMIUM_LOCAL: "GROSS_PREMIUM_LOCAL",
  GROSS_PREMIUM_FOREIGN: "GROSS_PREMIUM_FOREIGN",
  CAN_UPDATE: "CAN_UPDATE",
  EXTRA_LIMIT: "EXTRA_LIMIT",
  CHRONIC_EXTRA_LIMIT: "CHRONIC_EXTRA_LIMIT",
  PREEX_EXTRA_LIMIT: "PREEX_EXTRA_LIMIT",
  PREEX_AND_CHRONIC_EXTRA_LIMIT: "PREEX_AND_CHRONIC_EXTRA_LIMIT",
  OVERRIDE_PRESCRIPTION_RULE: "OVERRIDE_PRESCRIPTION_RULE",
  REQUIRES_COMPANY_PRESCRIPTION: "REQUIRES_COMPANY_PRESCRIPTION",
  WAVE_WAITING_PERIOD: "WAVE_WAITING_PERIOD",
  INSURED_CANCELLATION_WORKSPACE: "INSURED_CANCELLATION_WORKSPACE",
  WORKID_DUPLICATES: "WORKID_DUPLICATES",
  INSURED_DUPLICATES: "INSURED_DUPLICATES",
  WORKID_DUPLICATES_DETAILS: "WORKID_DUPLICATES_DETAILS",
  INSURED_DUPLICATES_DETAILS: "INSURED_DUPLICATES_DETAILS",
  PROVIDER_SPECIALTY_DETAIL: "PROVIDER_SPECIALTY_DETAIL",
  PROVIDER_CLASSIFICATION_DETAIL: "PROVIDER_CLASSIFICATION_DETAIL",

  POLICY_ACTIVE_AT_DATE: "POLICY_ACTIVE_AT_DATE",
  CHRONIC_FORM_START_DATE: "CHRONIC_FORM_START_DATE",
  HAS_GENERAL_EXCLUSION: "HAS_GENERAL_EXCLUSION",
  HAS_IMPORTED_DRUGS: "HAS_IMPORTED_DRUGS",
  HAS_NON_ALLOWED_DRUGS: "HAS_NON_ALLOWED_DRUGS",
  TOP_PROVIDERS_COUNT: "TOP_PROVIDERS_COUNT",
  TOP_CLIENTS_COUNT: "TOP_CLIENTS_COUNT",
  EXCLUDE_EXPIRED: "EXCLUDE_EXPIRED",
  CANCEL_PRICE_UPDATE_REQUEST: "CANCEL_PRICE_UPDATE_REQUEST",
  TRACK_REQUESTS: "TRACK_REQUESTS",
  ORGANIZATION_BRANCHES: "ORGANIZATION_BRANCHES",
  GENERATE_BILL: "GENERATE_BILL",
  CANCEL_DEPENDENTS: "CANCEL_DEPENDENTS",
  TRANSFER_INSURED_BRANCH: "TRANSFER_INSURED_BRANCH",
  CLIENT_SHARE_UPDATE: "CLIENT_SHARE_UPDATE",
  BRANCH_TRANSFER: "BRANCH_TRANSFER",

  POLICY_INSURED_PERSON: "POLICY_INSURED_PERSON",
  INHERIT_DEFAULT_COVERAGE: "INHERIT_DEFAULT_COVERAGE",
  PREMIUM_RELATED_INFO: "PREMIUM_RELATED_INFO",
  OVERRIDE_AGE_CONDITION: "OVERRIDE_AGE_CONDITION",
  SET_INSURED_NOT_COVERED: "SET_INSURED_NOT_COVERED",
  SET_INSURED_COVERED: "SET_INSURED_COVERED",
  SET_NOT_FOLLOWS_DEFAULT_BENEFIT: "SET_NOT_FOLLOWS_DEFAULT_BENEFIT",
  SET_FOLLOWS_DEFAULT_BENEFIT: "SET_FOLLOWS_DEFAULT_BENEFIT",
  UPDATE_COVERAGE_PLAN: "UPDATE_COVERAGE_PLAN",
  EDIT_COVERAGE_INFO: "EDIT_COVERAGE_INFO",
  EXTRA_LIMITS: "EXTRA_LIMITS",
  UPDATE_DATE: "UPDATE_DATE",
  FULL_PREMIUM_LOCAL: "FULL_PREMIUM_LOCAL",
  FULL_PREMIUM_FOREIGN: "FULL_PREMIUM_FOREIGN",
  APPLY_ON_ALL_PRODUCT_RELATED_BENEFITS:
    "APPLY_ON_ALL_PRODUCT_RELATED_BENEFITS",
  EXTRA_NUMBER_OF_CLAIMS_PER_INSURED: "EXTRA_NUMBER_OF_CLAIMS_PER_INSURED",
  PREEXISTING_AND_CHRONIC_EXTRA_LIMIT: "PREEXISTING_AND_CHRONIC_EXTRA_LIMIT",
  EXTRA_NUMBER_OF_CHRONIC_CLAIMS_PER_INSURED:
    "EXTRA_NUMBER_OF_CHRONIC_CLAIMS_PER_INSURED",
  EXTRA_NUMBER_OF_ACUTE_CLAIMS_PER_INSURED:
    "EXTRA_NUMBER_OF_ACUTE_CLAIMS_PER_INSURED",
  CANCEL_CURRENT_CARD: "CANCEL_CURRENT_CARD",
  SERVICE_MAPPING: "SERVICE_MAPPING",

  FOLLOWS_MAIN_PROVIDER_SERVICE_MAPPING:
    "FOLLOWS_MAIN_PROVIDER_SERVICE_MAPPING",
  UNFOLLOW_MAIN_PROVIDER_SERVICE_MAPPING:
    "UNFOLLOW_MAIN_PROVIDER_SERVICE_MAPPING",
  PROVIDER_SERVICE_MAPPING_WORKSPACE: "PROVIDER_SERVICE_MAPPING_WORKSPACE",
  PVP_NUMBER: "PVP_NUMBER",
  FROM_BILL_CREATION_DATE: "FROM_BILL_CREATION_DATE",
  INCLUDE_SECTOR_TYPE: "INCLUDE_SECTOR_TYPE",
  INCLUDE_INSURANCE_COMPANY: "INCLUDE_INSURANCE_COMPANY",
  COVERAGE_PLAN_UPDATE: "COVERAGE_PLAN_UPDATE",
  IGNORE_PENDING_PREAUTHORIZATION: "IGNORE_PENDING_PREAUTHORIZATION",
  FUND_TYPE: "FUND_TYPE",
  FUND_STATUS: "FUND_STATUS",
  FUND_ENDORSEMENT_TYPE: "FUND_ENDORSEMENT_TYPE",
  FUND_AMOUNT: "FUND_AMOUNT",
  ADMINISTRATION_COST_AMOUNT: "ADMINISTRATION_COST_AMOUNT",
  BROKERAGE_AMOUNT: "BROKERAGE_AMOUNT",
  NET_AMOUNT: "NET_AMOUNT",
  FUND_NUMBER: "FUND_NUMBER",
  MANAGE_FUND: "MANAGE_FUND",
  REFUND_FUND: "REFUND_FUND",
  REFUND_ADMIN_COST: "REFUND_ADMIN_COST",
  REFUND_BROKERAGE_AMOUNT: "REFUND_BROKERAGE_AMOUNT",
  DEDUCT_FROM_MAIN_LIMITS: "DEDUCT_FROM_MAIN_LIMITS",
  FUND_AMOUNTS: "FUND_AMOUNTS",
  BRANCH_ID: "BRANCH_ID",
  INCLUDE_ADMIN_COST: "INCLUDE_ADMIN_COST",
  ADMIN_COST_PERCENTAGE: "ADMIN_COST_PERCENTAGE",
  ADMIN_COST_AMOUNT: "ADMIN_COST_AMOUNT",
  BROKERAGE_PERCENTAGE: "BROKERAGE_PERCENTAGE",
  NUMBER_OF_INSTALLMENTS: "NUMBER_OF_INSTALLMENTS",
  AMOUNT_ID: "AMOUNT_ID",
  INSTALLMENT_DATE: "INSTALLMENT_DATE",
  INSTALLMENT_PERCENTAGE: "INSTALLMENT_PERCENTAGE",
  POPULATION_TYPE: "POPULATION_TYPE",
  POPULATION_SIZE: "POPULATION_SIZE",
  POPULATION_PERCENATGE: "POPULATION_PERCENATGE",
  TOTAL_POPULATION_SIZE: "TOTAL_POPULATION_SIZE",
  ISSUE: "ISSUE",
  UN_ISSUE: "UN_ISSUE",
  ADMIN_COST_ADVANCE_PAYMENT: "ADMIN_COST_ADVANCE_PAYMENT",
  BROKERAGE_ADVANCE_PAYMENT: "BROKERAGE_ADVANCE_PAYMENT",
  SPECIAL_DISCOUNT_WORKSPACE: "SPECIAL_DISCOUNT_WORKSPACE",
  SPECIAL_DISCOUNT_DUPLICATES: "SPECIAL_DISCOUNT_DUPLICATES",
  SPECIAL_DISCOUNT_DUPLICATES_DETAILS: "SPECIAL_DISCOUNT_DUPLICATES_DETAILS",
  SPECIAL_DISCOUNT: "SPECIAL_DISCOUNT",
  PVP_AMOUNT: "PVP_AMOUNT",
  INVOICE_AMOUNT: "INVOICE_AMOUNT",
  NAME_DUPLICATE_VALIDATION: "NAME_DUPLICATE_VALIDATION",
  CODE_DUPLICATE_VALIDATION: "CODE_DUPLICATE_VALIDATION",
  SERVICE_CODE_DUPLICATE_VALIDATION: "SERVICE_CODE_DUPLICATE_VALIDATION",
  FUND_ORGANIZATION_BRANCH: "FUND_ORGANIZATION_BRANCH",
  FUND_TAX: "FUND_TAX",
  EXPORT_PVIS: "EXPORT_PVIS",
  RENEWALS: "RENEWALS",
  EXTEND: "EXTEND",
  POLICY_INSURED_EXCLUSION: "POLICY_INSURED_EXCLUSION",
  CODE_DUPLICATES: "CODE_DUPLICATES",
  SERVICE_CODE_DUPLICATES: "SERVICE_CODE_DUPLICATES",
  NAME_DUPLICATE_DETAILS: "NAME_DUPLICATE_DETAILS",
  CODE_DUPLICATE_DETAILS: "CODE_DUPLICATE_DETAILS",
  SERVICE_CODE_DUPLICATE_DETAILS: "SERVICE_CODE_DUPLICATE_DETAILS",
  FULL_MATCH_DETAILS: "FULL_MATCH_DETAILS",
  CARD_ISSUANCE_WORKSPACE: "CARD_ISSUANCE_WORKSPACE",
  NEW_CARD_NUMBER: "NEW_CARD_NUMBER",
  DOWNLOAD_ALL: "DOWNLOAD_ALL",
  AMOUNT_TAXES: "AMOUNT_TAXES",
  FUND_INSTALLMENT: "FUND_INSTALLMENT",
  PRODUCTS: "PRODUCTS",
  FUND_COVERAGE_PLAN: "FUND_COVERAGE_PLAN",
  CREATE_POLICY: "CREATE_POLICY",
  MEDICAL_DECLARATION: "MEDICAL_DECLARATION",
  RESYNC_INSTALLMENTS: "RESYNC_INSTALLMENTS",
  OVERRIDE_PRICE_UPDATE_REQUEST: "OVERRIDE_PRICE_UPDATE_REQUEST",
  IN_PVI: "IN_PVI",
  IN_PVP: "IN_PVP",
  PVC_NUMBER: "PVC_NUMBER",
  RENEW_POLICY: "RENEW_POLICY",
  RENEW: "RENEW",
  CURRENT_PLAN: "CURRENT_PLAN",
  RENEWAL_PLAN: "RENEWAL_PLAN",
  KEEP_SAME_CARD_NUMBERS: "KEEP_SAME_CARD_NUMBERS",
  APPLY_NEXT_BIRTHDAY_RULE: "APPLY_NEXT_BIRTHDAY_RULE",
  NOT_RENEW_INSUREDS: "NOT_RENEW_INSUREDS",
  CHANGE_DATES: "CHANGE_DATES",
  CHANGE_PLAN: "CHANGE_PLAN",
  FUND_COVERAGE_PLAN_BENEFIT: "FUND_COVERAGE_PLAN_BENEFIT",
  UPDATED_AFTER: "UPDATED_AFTER",
  SERVICE_WORKSPACE: "SERVICE_WORKSPACE",
  DELETED: "DELETED",
  MANUAL_INVOICE_NUMBER: "MANUAL_INVOICE_NUMBER",
  PER_UNDERWRITING_MONTH: "PER_UNDERWRITING_MONTH",
  COLLECTIVE: "COLLECTIVE",
  PARENT: "PARENT",
  PERSON: "PERSON",
  ACCOUNTING_YEAR: "ACCOUNTING_YEAR",
  VOUCHER_TAG_NO: "VOUCHER_TAG_NO",
  RELATION_OFFICER: "RELATION_OFFICER",
  IS_COLLECTIVE: "IS_COLLECTIVE",
  COLLECTIVE_INVOICE: "COLLECTIVE_INVOICE",
  IS_CREDIT_NOTE: "IS_CREDIT_NOTE",
  IS_DEBIT_NOTE: "IS_DEBIT_NOTE",
  TAX_IS_DEDUCTED: "TAX_IS_DEDUCTED",
  MIGRATED: "MIGRATED",
  DUE_UPDATE_DATE: "DUE_UPDATE_DATE",
  DUE_UPDATED_BY: "DUE_UPDATED_BY",
  SENT_BY_USER: "SENT_BY_USER",
  CANCELLATION_DETAILS: "CANCELLATION_DETAILS",
  INVOICE_ITEMS: "INVOICE_ITEMS",
  SEND_INVOICE: "SEND_INVOICE",
  SEND_EMAIL: "SEND_EMAIL",
  SEND: "SEND",
  UNSEND: "UNSEND",
  PRICE_UPDATES: "PRICE_UPDATES",
  CONTRACT_NAME_LOCAL: "CONTRACT_NAME_LOCAL",
  CONTRACT_NAME_FOREIGN: "CONTRACT_NAME_FOREIGN",
  FOLDER_TYPE: "FOLDER_TYPE",
  SUB_INVOICES: "SUB_INVOICES",
  COLLECTIVE_INVOICES: "COLLECTIVE_INVOICES",
  PACK_SIZE_UNIT: "PACK_SIZE_UNIT",
  POTENCY_UNIT: "POTENCY_UNIT",
  PRICING_WORKSPACE: "PRICING_WORKSPACE",
  WORKSPACE_HISTORY: "WORKSPACE_HISTORY",
  IS_PRICING_SCHEME_SPECIFIC: "IS_PRICING_SCHEME_SPECIFIC",
  REPLACE_EXISTING_PRICES_AUTOMATICALLY:
    "REPLACE_EXISTING_PRICES_AUTOMATICALLY",
  RECEIPT_ADDED_BY: "RECEIPT_ADDED_BY",
  MOVE_TO_PROCESSING: "MOVE_TO_PROCESSING",
  ITEM_INFO: "ITEM_INFO",
  ROLLBACK_RESOLUTION: "ROLLBACK_RESOLUTION",
  SEND_RESPONSE: "SEND_RESPONSE",
  ROLLBACK_RESPONSE: "ROLLBACK_RESPONSE",
  ROLLBACK_RECEIPT: "ROLLBACK_RECEIPT",
  ADD_RECEIPT: "ADD_RECEIPT",
  ATTACH_REQUEST: "ATTACH_REQUEST",
  REQUEST_ATTACHMENT: "REQUEST_ATTACHMENT",
  RESPONSE_ATTACHMENT: "RESPONSE_ATTACHMENT",
  RESPONSE_RECEIPT_ATTACHMENT: "RESPONSE_RECEIPT_ATTACHMENT",
  ATTACH_RESPONSE: "ATTACH_RESPONSE",
  COLLECTED_INVOICES: "COLLECTED_INVOICES",
  ADJUSTMENT_INVOICES: "ADJUSTMENT_INVOICES",
  PARENT_INVOICE: "PARENT_INVOICE",
  CHECKUP_AGREEMENT: "CHECKUP_AGREEMENT",
  CHECKUP_INVOICE: "CHECKUP_INVOICE",
  CLAIM_INVOICE: "CLAIM_INVOICE",
  REMOVE_INVOICES: "REMOVE_INVOICES",
  FOLLOWING_INVOICE_NOT_ADDED: "FOLLOWING_INVOICE_NOT_ADDED",
  IS_REFUND: "IS_REFUND",
  PAYMENT_SOURCE: "PAYMENT_SOURCE",
  EXCLUDED_FROM_TRANSACTIONS: "EXCLUDED_FROM_TRANSACTIONS",
  COLLECTION_INFO: "COLLECTION_INFO",
  UNDER_COLLECTION: "UNDER_COLLECTION",
  UNDER_COLLECTION_DATE: "UNDER_COLLECTION_DATE",
  UNDER_COLLECTION_COMMENT: "UNDER_COLLECTION_COMMENT",
  UNDER_COLLECTION_SET_BY: "UNDER_COLLECTION_SET_BY",
  UNDER_COLLECTION_SET_DATE: "UNDER_COLLECTION_SET_DATE",
  COLLECTED: "COLLECTED",
  COLLECTION_DATE: "COLLECTION_DATE",
  COLLECTION_COMMENT: "COLLECTION_COMMENT",
  UPDATED_BY: "UPDATED_BY",
  CREDIT_NOTES: "CREDIT_NOTES",
  CREDIT_INVOICE_GENERATION_MESSAGE: "CREDIT_INVOICE_GENERATION_MESSAGE",
  CREDIT_NOTE_CREATION_MESSAGE: "CREDIT_NOTE_CREATION_MESSAGE",
  COLLECT: "COLLECT",
  TREASURY_TRANSACTION: "TREASURY_TRANSACTION",
  ENTITY_TYPE: "ENTITY_TYPE",
  RATE: "RATE",
  PAYMENT_DETAILS: "PAYMENT_DETAILS",
  RECEIVE_PAYMENT: "RECEIVE_PAYMENT",
  ADDITIONAL_TAXES: "ADDITIONAL_TAXES",
  DEDUCTION: "DEDUCTION",
  IS_CREDIT: "IS_CREDIT",
  SYSTEM_GENERATED: "SYSTEM_GENERATED",
  VENDOR: "VENDOR",
  BANK_ACCOUNT: "BANK_ACCOUNT",
  PAYMENT_RECIPIENT: "PAYMENT_RECIPIENT",
  ON_HOLD_AMOUNT: "ON_HOLD_AMOUNT",
  HOLD_REASON: "HOLD_REASON",
  HOLD_DATE: "HOLD_DATE",
  HOLD_BY: "HOLD_BY",
  RELEASE_DATE: "RELEASE_DATE",
  RELEASED_BY: "RELEASED_BY",
  ROLLED_BACK_BY: "ROLLED_BACK_BY",
  ROLLBACK_DATE: "ROLLBACK_DATE",
  BILL_ITEMS: "BILL_ITEMS",
  ADD_TO_BILL_AMOUNT: "ADD_TO_BILL_AMOUNT",
  UPDATE_PAYMENT_INFORMATION: "UPDATE_PAYMENT_INFORMATION",
  COST_CENTER: "COST_CENTER",
  ACCOUNT: "ACCOUNT",
  BILL_ITEM: "BILL_ITEM",
  ACCOUNTING_ITEM: "ACCOUNTING_ITEM",
  DUPLICATES: "DUPLICATES",
  DUPLICATE_DETAILS: "DUPLICATE_DETAILS",
  UNITS: "UNITS",
  PERCENTAGE_OF_MAIN_SERVICE: "PERCENTAGE_OF_MAIN_SERVICE",
  KEEP_EXISTING_PRICING: "KEEP_EXISTING_PRICING",
  SERVICE_PRICING_REPLACE_HEADER: "SERVICE_PRICING_REPLACE_HEADER",
  OLD_PRICING: "OLD_PRICING",
  NEW_PRICING: "NEW_PRICING",
  PROCESS_DETAILS: "PROCESS_DETAILS",
  PROCESS_PENDING_DETAILS: "PROCESS_PENDING_DETAILS",
  DOWNLOAD_IMAGES: "DOWNLOAD_IMAGES",
  RELINK_REVISION: "RELINK_REVISION",
  READY_FOR_PROCESSING: "READY_FOR_PROCESSING",
  RESPONSE_NOT_SENT: "RESPONSE_NOT_SENT",
  WAITING_FOR_RECEIPT: "WAITING_FOR_RECEIPT",
  RECIPIENTS: "RECIPIENTS",
  REMARKS: "REMARKS",
  PAYMENT_PROCESSED: "PAYMENT_PROCESSED",
  TOTAL_AMOUNT_TEXT: "TOTAL_AMOUNT_TEXT",
  CHEQUE_NUMBER: "CHEQUE_NUMBER",
  IS_PENDING_TRANSFER: "IS_PENDING_TRANSFER",
  PAYMENT_PROCESSING_DATE: "PAYMENT_PROCESSING_DATE",
  PAYMENT_PROCESSING_COMMENT: "PAYMENT_PROCESSING_COMMENT",
  PAYMENT_PROCESSING_UPDATE_DATE: "PAYMENT_PROCESSING_UPDATE_DATE",
  PAYMENT_PROCESSING_UPDATED_BY: "PAYMENT_PROCESSING_UPDATED_BY",
  ACUTE_COPAYMENT_MAX: "ACUTE_COPAYMENT_MAX",
  ACUTE_COPAYMENT_MIN: "ACUTE_COPAYMENT_MIN",
  CHRONIC_COPAYMENT_MAX: "CHRONIC_COPAYMENT_MAX",
  CHRONIC_COPAYMENT_MIN: "CHRONIC_COPAYMENT_MIN",
  PREEXISTING_COPAYMENT_MAX: "PREEXISTING_COPAYMENT_MAX",
  PREEXISTING_COPAYMENT_MIN: "PREEXISTING_COPAYMENT_MIN",
  PARTIES: "PARTIES",
  ORGANIZATIONS: "ORGANIZATIONS",
  OWNER_LOCAL_NAME: "OWNER_LOCAL_NAME",
  OWNER_FOREIGN_NAME: "OWNER_FOREIGN_NAME",
  NAME_LOCAL: "NAME_LOCAL",
  NAME_FOREIGN: "NAME_FOREIGN",
  INDUSTRY: "INDUSTRY",
  BANK_DETAILS: "BANK_DETAILS",

  ACCOUNT_TYPE: "ACCOUNT_TYPE",
  HOLDER_NAME_LOCAL: "HOLDER_NAME_LOCAL",
  HOLDER_NAME_FOREIGN: "HOLDER_NAME_FOREIGN",
  ACCOUNT_HOLDER_IDENTITY_NUMBER: "ACCOUNT_HOLDER_IDENTITY_NUMBER",
  SECURITY_CODE: "SECURITY_CODE",
  IBAN: "IBAN",
  SWIFT_CODE: "SWIFT_CODE",
  FROZEN: "FROZEN",
  BANK_ACCOUNTS: "BANK_ACCOUNTS",
  APPROVAL_NOTIFICATION: "APPROVAL_NOTIFICATION",
  UNDERWRITING_NOTIFICATION: "UNDERWRITING_NOTIFICATION",
  INVOICE_NOTIFICATION: "INVOICE_NOTIFICATION",
  RECIPIENT_NAME: "RECIPIENT_NAME",
  PAYMENT_RECIPIENTS: "PAYMENT_RECIPIENTS",
  POLICYHOLDER_GROUP: "POLICYHOLDER_GROUP",
  GENERATE_DEBIT_INVOICES_PER_INSURED: "GENERATE_DEBIT_INVOICES_PER_INSURED",
  PAYER_CODE: "PAYER_CODE",
  SYNDICATE: "SYNDICATE",
  ADJUSTMENT: "ADJUSTMENT",
  FOLLOWING_RECIPIENTS_NOT_PROCESSED: "FOLLOWING_RECIPIENTS_NOT_PROCESSED",
  FOLLOWING_RECIPIENTS_PROCESSED: "FOLLOWING_RECIPIENTS_PROCESSED",
  PROCESS: "PROCESS",
  UN_PAY: "UN_PAY",
  INSURANCE_CARD: "INSURANCE_CARD",
  INSURED_IS_ACTIVE: "INSURED_IS_ACTIVE",
  EXCLUDE_CANCELLED_SERVICES: "EXCLUDE_CANCELLED_SERVICES",
  CLAIM_FORM_TYPES: "CLAIM_FORM_TYPES",
  CLAIM_TYPES: "CLAIM_TYPES",
  IS_DOCTOR_VISIT: "IS_DOCTOR_VISIT",
  ADD_CLAIM_FORM_TYPE: "ADD_CLAIM_FORM_TYPE",
  ENABLE_REIMBURSEMENT_REQUEST: "ENABLE_REIMBURSEMENT_REQUEST",
  MOBILE_APPROVAL_TYPE: "MOBILE_APPROVAL_TYPE",
  DEFAULT_SUB_PRODUCT: "DEFAULT_SUB_PRODUCT",
  ONLINE_PROCESSING_TIME: "ONLINE_PROCESSING_TIME",
  OFFLINE_PROCESSING_TIME: "OFFLINE_PROCESSING_TIME",
  PREAUTHORIZATION_PERIOD_IN_DAYS: "PREAUTHORIZATION_PERIOD_IN_DAYS",
  FOLLOWUP_PERIOD_IN_DAYS: "FOLLOWUP_PERIOD_IN_DAYS",
  IS_IN_PATIENT: "IS_IN_PATIENT",
  REQUIRES_TREATMENT_DOCTOR: "REQUIRES_TREATMENT_DOCTOR",
  DISCOUNT_ON_COVERAGE_AMOUNT: "DISCOUNT_ON_COVERAGE_AMOUNT",
  REQUIRES_PRESCRIPTION_CODE: "REQUIRES_PRESCRIPTION_CODE",
  CAN_CLAIM_APPROVAL_WITHOUT_PRESCRIPTION:
    "CAN_CLAIM_APPROVAL_WITHOUT_PRESCRIPTION",
  ALLOW_DISPENSING_VIRTUAL_PREAUTHORIZATION:
    "ALLOW_DISPENSING_VIRTUAL_PREAUTHORIZATION",
  PORTAL_SEARCH_BY_PREAUTH_NET_WORKONLY:
    "PORTAL_SEARCH_BY_PREAUTH_NET_WORKONLY",
  ALLOW_DEFAULT_PROVIDER_ON_APPROVAL_REQUEST:
    "ALLOW_DEFAULT_PROVIDER_ON_APPROVAL_REQUEST",
  DEFAULT_VIRTUAL_PROVIDER: "DEFAULT_VIRTUAL_PROVIDER",
  ALLOW_SERVICE_PRICE_UPDATE_REQUEST: "ALLOW_SERVICE_PRICE_UPDATE_REQUEST",
  ENABLE_AUTOMATIC_SERVICE_PRICE_UPDATE:
    "ENABLE_AUTOMATIC_SERVICE_PRICE_UPDATE",
  REQUIRES_PRICE_UPDATE_REQUEST_ATTACHMENTS:
    "REQUIRES_PRICE_UPDATE_REQUEST_ATTACHMENTS",
  BOOKLET_TYPES: "BOOKLET_TYPES",
  BOOKLET_TYPE: "BOOKLET_TYPE",
  NUMBER_OF_PRESCRIPTIONS: "NUMBER_OF_PRESCRIPTIONS",
  ENABLE_FORCE_PRELIMINARY: "ENABLE_FORCE_PRELIMINARY",
  ENABLE_FORCE_MAIN_OPERATION: "ENABLE_FORCE_MAIN_OPERATION",
  JUMBO_CLAIM_AMOUNT: "JUMBO_CLAIM_AMOUNT",
  SEND_NOTIFICATION_ON_APPROVAL: "SEND_NOTIFICATION_ON_APPROVAL",
  ADD_AMOUNTS_TO_NOTIFICATION_ON_APPROVAL:
    "ADD_AMOUNTS_TO_NOTIFICATION_ON_APPROVAL",
  ORGANIZATION_BANK_ACCOUNT: "ORGANIZATION_BANK_ACCOUNT",
  EXISTING_ACCOUNT: "EXISTING_ACCOUNT",
  EXISTING_BANK_ACCOUNTS: "EXISTING_BANK_ACCOUNTS",
  BANK_ACCOUNT_TYPE: "BANK_ACCOUNT_TYPE",
  BANK_ACCOUNT_HOLDER_LOCAL: "BANK_ACCOUNT_HOLDER_LOCAL",
  BANK_ACCOUNT_HOLDER_FOREIGN: "BANK_ACCOUNT_HOLDER_FOREIGN",
  BILLING_ADDRESS: "BILLING_ADDRESS",
  MAIL_BOX: "MAIL_BOX",
  EXISTING_CONTACT: "EXISTING_CONTACT",
  EXISTING_CONTACTS: "EXISTING_CONTACTS",
  EXISTING_RECIPIENT: "EXISTING_RECIPIENT",
  EXISTING_RECIPIENTS: "EXISTING_RECIPIENTS",
  PAY: "PAY",
  NEXT_AVAILABLE_CHEQUE_NUMBER: "NEXT_AVAILABLE_CHEQUE_NUMBER",
  OVERRIDE_PAYMENT_METHOD: "OVERRIDE_PAYMENT_METHOD",
  SET_PROCESSED: "SET_PROCESSED",
  SET_REJECTED: "SET_REJECTED",
  UNSET_PROCESSED: "UNSET_PROCESSED",
  FOLLOWING_BILLS_NOT_ADDED: "FOLLOWING_BILLS_NOT_ADDED",
  AUTHORIZED_SIGNATORY: "AUTHORIZED_SIGNATORY",
  AUTHORIZED_SIGNATORIES: "AUTHORIZED_SIGNATORIES",
  RECIPIENT_ID: "RECIPIENT_ID",
  BILLS_COUNT: "BILLS_COUNT",
  BILLS_TOTAL_AMOUNT: "BILLS_TOTAL_AMOUNT",
  PENDING_BILLS: "PENDING_BILLS",
  PRINTED_BY_USER: "PRINTED_BY_USER",
  DELIVERED_BY_USER: "DELIVERED_BY_USER",
  PRINTED_DATE: "PRINTED_DATE",
  NUMBER_OF_BOOKLETS: "NUMBER_OF_BOOKLETS",
  NUMBER_OF_CLAIMS: "NUMBER_OF_CLAIMS",
  FROM_SERIAL_NUMBER: "FROM_SERIAL_NUMBER",
  TO_SERIAL_NUMBER: "TO_SERIAL_NUMBER",
  CREATED_BY_USER: "CREATED_BY_USER",
  CHECKED_OUT_BY_USER: "CHECKED_OUT_BY_USER",
  CLAIM_BOOKLETS: "CLAIM_BOOKLETS",
  CLAIM_BOOKLET: "CLAIM_BOOKLET",
  ASSIGN_TO_PROVIDER: "ASSIGN_TO_PROVIDER",
  RECEIPT_REPORT: "RECEIPT_REPORT",
  REMOVE_PRINT: "REMOVE_PRINT",
  COUNT: "COUNT",
  BOOKLET: "BOOKLET",
  REMOVE_DELIVERY: "REMOVE_DELIVERY",
  TPA_DISCOUNT_PERCENTAGE: "TPA_DISCOUNT_PERCENTAGE",
  APPLY_ON_PVP_AMOUNT: "APPLY_ON_PVP_AMOUNT",
  SYNDICATE_SHARES: "SYNDICATE_SHARES",
  CANCEL_SYNDICATE_SHARE: "CANCEL_SYNDICATE_SHARE",
  MAXIMUM_USER_IDLE_TIME: "MAXIMUM_USER_IDLE_TIME",
  IS_ENABLED: "IS_ENABLED",
  BATCH_ASSIGNMENT_RULES: "BATCH_ASSIGNMENT_RULES",
  MAXIMUM_ASSIGNED_REQUESTS_PER_USER: "MAXIMUM_ASSIGNED_REQUESTS_PER_USER",
  DELETED_BY_USER: "DELETED_BY_USER",
  DELETION_DATE: "DELETION_DATE",
  OFFICIAL_LETTER_TYPE: "OFFICIAL_LETTER_TYPE",
  AUTHORIZED_SIGNATORY_CLASS: "AUTHORIZED_SIGNATORY_CLASS",
  FOLLOWING_AUTHORIZED_SIGNATORIES_NOT_ADDED:
    "FOLLOWING_AUTHORIZED_SIGNATORIES_NOT_ADDED",
  GENERATE_PAYMENT: "GENERATE_PAYMENT",
  INCONSISTENT_PAYMENT_SETTINGS: "INCONSISTENT_PAYMENT_SETTINGS",
  CHEQUE_PAYMENT: "CHEQUE_PAYMENT",
  CASH_PAYMENT: "CASH_PAYMENT",
  ADD_TO_TRANSMITTAL: "ADD_TO_TRANSMITTAL",
  FOLLOWING_REQUESTS_NOT_DELETED: "FOLLOWING_REQUESTS_NOT_DELETED",
  DELETE_ALL_REQUESTS: "DELETE_ALL_REQUESTS",
  LAST_SUBMISSION_DATE: "LAST_SUBMISSION_DATE",
  EXISTING_CLAIM: "EXISTING_CLAIM",
  NEW_CLAIM: "NEW_CLAIM",
  INVOICE_PAYMENTS: "INVOICE_PAYMENTS",
  BILL_PAYMENTS: "BILL_PAYMENTS",
  NOT_IN_TRANSMITTAL: "NOT_IN_TRANSMITTAL",
  ORGANIZATION_HISTORY: "ORGANIZATION_HISTORY",
  ACTIVATION_HISTORY: "ACTIVATION_HISTORY",
  BANK_ACCOUNT_HISTORY: "BANK_ACCOUNT_HISTORY",
  MANAGE_BANK_ACCOUNT: "MANAGE_BANK_ACCOUNT",
  FREEZE: "FREEZE",
  UN_FREEZE: "UN_FREEZE",
  ACTIVATION_COMMENT: "ACTIVATION_COMMENT",
  DEACTIVATION_DATE: "DEACTIVATION_DATE",
  MOTHER_NAME_LOCAL: "MOTHER_NAME_LOCAL",
  MOTHER_NAME_FOREIGN: "MOTHER_NAME_FOREIGN",
  SPOUSE_NAME_LOCAL: "SPOUSE_NAME_LOCAL",
  SPOUSE_NAME_FOREIGN: "SPOUSE_NAME_FOREIGN",
  HOME_CITY: "HOME_CITY",
  WORK_CITY: "WORK_CITY",
  HOME_ADDRESS: "HOME_ADDRESS",
  WORK_ADDRESS: "WORK_ADDRESS",
  ROLES: "ROLES",
  RELATION_OFFICERS: "RELATION_OFFICERS",
  SALES_PERSON: "SALES_PERSON",
  COLLECTION_AGENT: "COLLECTION_AGENT",
  RELATION_OFFICER_TYPE: "RELATION_OFFICER_TYPE",
  COLLECTION_AGENTS: "COLLECTION_AGENTS",
  PERSON_HISTORY: "PERSON_HISTORY",
  POLICY_HOLDER_INDIVIDUAL: "POLICY_HOLDER_INDIVIDUAL",
  REMOVAL_ADDITIONAL_INFO: "REMOVAL_ADDITIONAL_INFO",
  ACTION_TYPE: "ACTION_TYPE",
  PERSON_BANK_ACCOUNT: "PERSON_BANK_ACCOUNT",
  BANK_ACCOUNT_ACTIONS_HISTORY: "BANK_ACCOUNT_ACTIONS_HISTORY",
  PERSON_ID: "PERSON_ID",
  SYNDICATES: "SYNDICATES",
  ORGANIZATION_ID: "ORGANIZATION_ID",
  ORGANIZATION_NAME_LOCAL: "ORGANIZATION_NAME_LOCAL",
  ORGANIZATION_NAME_FOREIGN: "ORGANIZATION_NAME_FOREIGN",
  ORGANIZATION_NAME: "ORGANIZATION_NAME",
  DELETED_BY: "DELETED_BY",
  SYNDICATE_BRANCH: "SYNDICATE_BRANCH",
  CONTACT_AND_ADDRESS: "CONTACT_AND_ADDRESS",
  PROFESSIONS: "PROFESSIONS",
  INDUSTRIES: "INDUSTRIES",
  POLICYHOLDERS: "POLICYHOLDERS",
  INDIVIDUAL: "INDIVIDUAL",
  AGENTS: "AGENTS",
  PAYMENT_ORGANIZATION: "PAYMENT_ORGANIZATION",
  GENERATE_BILL_BY_CLIENT: "GENERATE_BILL_BY_CLIENT",
  GENERATE_BILL_BY_BRANCH: "GENERATE_BILL_BY_BRANCH",
  GENERATE_BILL_BY_INSURED: "GENERATE_BILL_BY_INSURED",
  IS_DELETED: "IS_DELETED",
  PROVIDER_TYPES: "PROVIDER_TYPES",
  CODE_SERIAL: "CODE_SERIAL",
  IS_DOCTOR: "IS_DOCTOR",
  MUST_HAVE_SPECIALTY: "MUST_HAVE_SPECIALTY",
  IS_FACILITY: "IS_FACILITY",
  PROVIDER_SPECIALTIES: "PROVIDER_SPECIALTIES",
  PROVIDER_TYPE_CODES: "PROVIDER_TYPE_CODES",
  SPECIALTY_CODE: "SPECIALTY_CODE",
  SHOW_TO_WEB_USERS: "SHOW_TO_WEB_USERS",
  INSURANCE_COMPANY_PROVIDER_TYPE_CODE: "INSURANCE_COMPANY_PROVIDER_TYPE_CODE",
  PROVIDER_CLASSIFICATIONS: "PROVIDER_CLASSIFICATIONS",
  SPECIALTY_SERVICES: "SPECIALTY_SERVICES",
  SPECIALTY_DIAGNOSES: "SPECIALTY_DIAGNOSES",
  SET_DEFAULT: "SET_DEFAULT",
  UNSET_DEFAULT: "UNSET_DEFAULT",
  PROVIDER_CATEGORIES: "PROVIDER_CATEGORIES",
  GROUP_CLASSIFICATIONS: "GROUP_CLASSIFICATIONS",
  PROVIDER_GROUP_CLASSIFICATIONS: "PROVIDER_GROUP_CLASSIFICATIONS",
  PROVIDER_GROUP_CLASSIFICATION: "PROVIDER_GROUP_CLASSIFICATION",
  OFFERED_SERVICES: "OFFERED_SERVICES",
  OFFERED_SERVICE: "OFFERED_SERVICE",
  PROVIDER_REQUEST_ASSIGNMENT_RULES: "PROVIDER_REQUEST_ASSIGNMENT_RULES",
  CONTRACT_SETTINGS: "CONTRACT_SETTINGS",
  DEFAULT_PROVIDER_CONTRACT_SETTINGS: "DEFAULT_PROVIDER_CONTRACT_SETTINGS",
  DEFAULT_PROVIDER_CONTRACT_SETTING: "DEFAULT_PROVIDER_CONTRACT_SETTING",
  REMOVE_DUPLICATES: "REMOVE_DUPLICATES",
  DUPLICATE_PROVIDERS: "DUPLICATE_PROVIDERS",
  PROVIDER_TO_KEEP: "PROVIDER_TO_KEEP",
  PROVIDER_TO_REMOVE: "PROVIDER_TO_REMOVE",
  STAND_ALONE_APPROVAL: "STAND_ALONE_APPROVAL",
  SCHEMES: "SCHEMES",
  PROVIDER_SPECIFIC: "PROVIDER_SPECIFIC",
  OPERATION_CATEGORY_PRICING: "OPERATION_CATEGORY_PRICING",
  HIDE_PROTOCOLS: "HIDE_PROTOCOLS",
  SALES_AGENTS: "SALES_AGENTS",
  CORPORATE_RELATION_OFFICER: "CORPORATE_RELATION_OFFICER",
  SALES_AGENT: "SALES_AGENT",
  FIRST_JOIN_DATE: "FIRST_JOIN_DATE",
  DELETION_REASON: "DELETION_REASON",
  DELETED_APPROVALS: "DELETED_APPROVALS",
  TPA_COMPANIES: "TPA_COMPANIES",
  USERS_AS_SYSTEM_USERS: "USERS_AS_SYSTEM_USERS",
  TPA_COMPANY_CONTRACTS: "TPA_COMPANY_CONTRACTS",
  TPA_COMPANY_CONTRACT: "TPA_COMPANY_CONTRACT",
  RECEIPT_PERIOD_IN_DAYS: "RECEIPT_PERIOD_IN_DAYS",
  FAST_CLAIM_LIFE_CYCLE: "FAST_CLAIM_LIFE_CYCLE",
  ON_GROSS_PREMIUM: "ON_GROSS_PREMIUM",
  MANAGE_TPA_CONTRACT: "MANAGE_TPA_CONTRACT",
  CLEAR_STAMP: "CLEAR_STAMP",
  STAMP: "STAMP",
  SERVICE_DISCOUNT_SETTING: "SERVICE_DISCOUNT_SETTING",
  ON_COVERAGE_AMOUNT: "ON_COVERAGE_AMOUNT",
  DISCOUNT_PERCENTAGE_ON_IMPORTED_DRUGS:
    "DISCOUNT_PERCENTAGE_ON_IMPORTED_DRUGS",
  HAS_DEFAULT_DISCOUNT: "HAS_DEFAULT_DISCOUNT",
  ALWAYS_APPLY_DEFAULT_DISCOUNT: "ALWAYS_APPLY_DEFAULT_DISCOUNT",
  APPLY_WHEN_DETAIL_NOT_AVAILABLE: "APPLY_WHEN_DETAIL_NOT_AVAILABLE",
  DEFAULT_DISCOUNT: "DEFAULT_DISCOUNT",
  DEFAULT_DISCOUNT_PERCENTAGE: "DEFAULT_DISCOUNT_PERCENTAGE",
  IS_CONFIRMED: "IS_CONFIRMED",
  CONFIRMATION_DATE: "CONFIRMATION_DATE",
  APPOINTMENT: "APPOINTMENT",
  APPROVAL_INFO: "APPROVAL_INFO",
  NOTIFICATIONS: "NOTIFICATIONS",
  BASIC: "BASIC",
  CONFIRMATION: "CONFIRMATION",
  OWNER: "OWNER",
  SERVICE_DISCOUNT: "SERVICE_DISCOUNT",
  PROVIDER_NOTIFICATIONS: "PROVIDER_NOTIFICATIONS",
  REQUIERS_PROVIDER_NOTIFICATION: "REQUIERS_PROVIDER_NOTIFICATION",
  PROVIDER_NOTIFIED: "PROVIDER_NOTIFIED",
  PROVIDER_NOTIFIED_BY: "PROVIDER_NOTIFIED_BY",
  PROVIDER_NOTIFICATION_DATE: "PROVIDER_NOTIFICATION_DATE",
  PROVIDER_NOTIFICATION_COMMENT: "PROVIDER_NOTIFICATION_COMMENT",
  INSURED_NOTIFICATIONS: "INSURED_NOTIFICATIONS",
  REQUIRES_CHRONIC_REQUIREMENT_NOTIFICATION:
    "REQUIRES_CHRONIC_REQUIREMENT_NOTIFICATION",
  REQUIRES_INSURED_NOTIFICATION: "REQUIRES_INSURED_NOTIFICATION",
  IS_INSURED_NOTIFIED: "IS_INSURED_NOTIFIED",
  INSURED_NOTIFIED_BY: "INSURED_NOTIFIED_BY",
  INSURED_NOTIFICATION_COMMENT: "INSURED_NOTIFICATION_COMMENT",
  NOTIFICATION_REASON: "NOTIFICATION_REASON",
  SERVICE_GROUPS: "SERVICE_GROUPS",
  IS_FORM_SERVICE_GROUP: "IS_FORM_SERVICE_GROUP",
  IS_PRESCRIPTION_SERVICE_GROUP: "IS_PRESCRIPTION_SERVICE_GROUP",
  PRESCRIPTION_CLAIM_TYPE: "PRESCRIPTION_CLAIM_TYPE",
  SERVICE_GROUP_SERVICES: "SERVICE_GROUP_SERVICES",
  EXPORT_SERVICE_GROUPS: "EXPORT_SERVICE_GROUPS",
  PROVIDER_WEB_REQUESTS: "PROVIDER_WEB_REQUESTS",
  WEB_REQUESTS: "WEB_REQUESTS",
  PROCESSED_DATE: "PROCESSED_DATE",
  AUTOMATICALLY_GENERATED: "AUTOMATICALLY_GENERATED",
  MOVE_TO_VALIDATION: "MOVE_TO_VALIDATION",
  DOCTOR_NAME: "DOCTOR_NAME",
  HAS_SCANNED_COPY: "HAS_SCANNED_COPY",
  LINKED_TO_APPROVAL: "LINKED_TO_APPROVAL",
  IS_NEW_CLAIM: "IS_NEW_CLAIM",
  LINK_TO_APPROVAL: "LINK_TO_APPROVAL",
  UN_LINK_APPROVAL: "UN_LINK_APPROVAL",
  UNSET_NEW_CLAIM: "UNSET_NEW_CLAIM",
  SET_NEW_CLAIM: "SET_NEW_CLAIM",
  SYNC_TREATMENT_DOCTOR: "SYNC_TREATMENT_DOCTOR",
  SYNC_SERVICES: "SYNC_SERVICES",
  EDI_ITEMS: "EDI_ITEMS",
  INSURANCE_COMPANY_REBATE: "INSURANCE_COMPANY_REBATE",
  APPLY_ON_AMOUNT_TO_PAY: "APPLY_ON_AMOUNT_TO_PAY",
  FROM_PVI_AMOUNT: "FROM_PVI_AMOUNT",
  TO_PVI_AMOUNT: "TO_PVI_AMOUNT",
  FROM_POLICY_START_DATE: "FROM_POLICY_START_DATE",
  INSURANCE_COMPANY_REBATES: "INSURANCE_COMPANY_REBATES",
  ACTIVE_CARD: "ACTIVE_CARD",
  ACTIVE_POLICYHOLDERS: "ACTIVE_POLICYHOLDERS",
  IN_ACTIVE_POLICYHOLDERS: "IN_ACTIVE_POLICYHOLDERS",
  INSURED_CARDS: "INSURED_CARDS",
  BANK_ACCOUNTS_AND_PAYMENT_RECIPIENS: "BANK_ACCOUNTS_AND_PAYMENT_RECIPIENS",
  COMMENTS_AND_SUPERVISION: "COMMENTS_AND_SUPERVISION",
  TICKETS_AND_APPOINTMENTS: "TICKETS_AND_APPOINTMENTS",
  MEDICAL_INFO: "MEDICAL_INFO",
  NOTIFICATION_APPROVALS: "NOTIFICATION_APPROVALS",
  USERS: "USERS",
  WORKSPACE_OTP: "WORKSPACE_OTP",
  MOBILE_USER: "MOBILE_USER",
  APPOINTMENT_DATE: "APPOINTMENT_DATE",
  CONFIRMED_PHYSICIAN: "CONFIRMED_PHYSICIAN",
  PHYSICIAN_TYPE: "PHYSICIAN_TYPE",
  IS_CHRONIC: "IS_CHRONIC",
  IS_PRE_EXISTING: "IS_PRE_EXISTING",
  IS_CANCELED: "IS_CANCELED",
  IS_CANCELED_BY_STAKEHOLDER: "IS_CANCELED_BY_STAKEHOLDER",
  CANCELED_BY_STAKEHOLDER_DATE: "CANCELED_BY_STAKEHOLDER_DATE",
  NOTIFIED_BY: "NOTIFIED_BY",
  IS_NOTIFIED: "IS_NOTIFIED",
  NOTIFICATION_DATE: "NOTIFICATION_DATE",
  UN_LINK_EDI_ITEM: "UN_LINK_EDI_ITEM",
  LINK_TO_EDI_ITEM: "LINK_TO_EDI_ITEM",
  MAP_SERVICE: "MAP_SERVICE",
  POTENTIAL_MAPPING: "POTENTIAL_MAPPING",
  ADD_CONSULTATION: "ADD_CONSULTATION",
  PRESCRIPTION_FORM: "PRESCRIPTION_FORM",
  HOSPITALIZATION_FORM: "HOSPITALIZATION_FORM",
  CLAIM_SCAN_SERIAL: "CLAIM_SCAN_SERIAL",
  ITEM_ID: "ITEM_ID",
  USER_COMMENT: "USER_COMMENT",
  MANAGE_PAYER_CONTRACT: "MANAGE_PAYER_CONTRACT",
  PAYER_REBATES: "PAYER_REBATES",
  SPECIALTY_YEARS: "SPECIALTY_YEARS",
  PVI_AMOUNTS: "PVI_AMOUNTS",
  PAYER_REBATE: "PAYER_REBATE",
  FEMALE: "FEMALE",
  MALE: "MALE",
  TRANSACTIONS: "TRANSACTIONS",
  TPA_CONTRACT: "TPA_CONTRACT",
  INSURED_COVERAGES: "INSURED_COVERAGES",
  SET_UNDER_SUPERVISION: "SET_UNDER_SUPERVISION",
  REMOVE_SUPERVISION: "REMOVE_SUPERVISION",
  NOTES: "NOTES",
  ADDED_BY_SYSTEM_USER: "ADDED_BY_SYSTEM_USER",
  NOTE: "NOTE",
  NOTE_TYPE: "NOTE_TYPE",
  INSURED_MEDICAL_NOTE: "INSURED_MEDICAL_NOTE",
  PROVIDER_WEB_REQUEST: "PROVIDER_WEB_REQUEST",
  MATCH: "MATCH",
  BANK_ACCOUNT_HOLDER_IDENTITY_NUMBER: "BANK_ACCOUNT_HOLDER_IDENTITY_NUMBER",
  HAS_CONTRACT: "HAS_CONTRACT",
  ONLINE_REQUESTS: "ONLINE_REQUESTS",
  NEW_VALUE: "NEW_VALUE",
  OLD_VALUE: "OLD_VALUE",
  FINAL_VALUE: "FINAL_VALUE",
  PAYMENT_SETTINGS: "PAYMENT_SETTINGS",
  SYNC_WITH_SCANNED_COPIES: "SYNC_WITH_SCANNED_COPIES",
  INSURED_BANK_ACCOUNT: "INSURED_BANK_ACCOUNT",
  PROVIDER_BANK_ACCOUNT: "PROVIDER_BANK_ACCOUNT",
  SYNC_UNPAID_PVPS: "SYNC_UNPAID_PVPS",
  UNPAID_PROVIDER_PAYMENT_VOUCHERS: "UNPAID_PROVIDER_PAYMENT_VOUCHERS",
  FOLLOW_MAIN_BRANCH_PAYMENT_SETTINGS: "FOLLOW_MAIN_BRANCH_PAYMENT_SETTINGS",
  UN_FOLLOW_MAIN_BRANCH_PAYMENT_SETTINGS:
    "UN_FOLLOW_MAIN_BRANCH_PAYMENT_SETTINGS",
  AVAILABLE_PHYSICIANS: "AVAILABLE_PHYSICIANS",
  IS_PREFERRED: "IS_PREFERRED",
  APPOINTMENT_REQUEST_DATE: "APPOINTMENT_REQUEST_DATE",
  USE_PREFERRED_DATE: "USE_PREFERRED_DATE",
  PRE_CONFIRM: "PRE_CONFIRM",
  ROLLBACK_PRECONFERMATION: "ROLLBACK_PRECONFERMATION",
  CONFIRM: "CONFIRM",
  CREATION: "CREATION",
  SUBMISSION: "SUBMISSION",
  ROLLBACK_PRECONFIRMATION: "ROLLBACK_PRECONFIRMATION",
  CANCEL_APPOINTMENT: "CANCEL_APPOINTMENT",
  ROLLBACK_CONFERMATION: "ROLLBACK_CONFERMATION",
  ASSIGN_TO_ME: "ASSIGN_TO_ME",
  NOTIFY: "NOTIFY",
  UN_NOTIFY: "UN_NOTIFY",
  ISSUE_APPROVAL: "ISSUE_APPROVAL",
  APPOINTMENT_ELIGIBILITY: "APPOINTMENT_ELIGIBILITY",
  RECEIVER_TYPE: "RECEIVER_TYPE",
  APPROVAL_INSPECTION: "APPROVAL_INSPECTION",
  RESPONSIBLE_USER: "RESPONSIBLE_USER",
  IS_CLIENT_MANAGEMENT: "IS_CLIENT_MANAGEMENT",
  REQUIRES_BENEFIT_MAPPING: "REQUIRES_BENEFIT_MAPPING",
  SHOW_REQUESTED_AMOUNT_ON_SETTLEMENT_REPORTS:
    "SHOW_REQUESTED_AMOUNT_ON_SETTLEMENT_REPORTS",
  RETENTION: "RETENTION",
  HEAD_QUARTER: "HEAD_QUARTER",
  INCLUDE_INVOICE_NUMBERS: "INCLUDE_INVOICE_NUMBERS",
  INCLUDE_MEDICAL_NETWORK: "INCLUDE_MEDICAL_NETWORK",
  POLICYHOLDER_CITY: "POLICYHOLDER_CITY",
  INCLUDE_ONLY_MODIFIED_CARDS: "INCLUDE_ONLY_MODIFIED_CARDS",
  FROM_RETURN_DATE: "FROM_RETURN_DATE",
  TO_RETURN_DATE: "TO_RETURN_DATE",
  IS_AGENT: "IS_AGENT",
  PAYMENT_UPFRONT: "PAYMENT_UPFRONT",
  COMMISSION: "COMMISSION",
  BROKER_CONTRACT_TAX: "BROKER_CONTRACT_TAX",
  BROKER_BANK_ACCOUNT: "BROKER_BANK_ACCOUNT",

  GROUP_PREMIUM_FUNDER_INSTALLMENTS: "GROUP_PREMIUM_FUNDER_INSTALLMENTS",
  EXPORT_PER_BRANCH: "EXPORT_PER_BRANCH",
  OPERATION_SCHEMES: "OPERATION_SCHEMES",
  SCHEME_DATE: "SCHEME_DATE",
  OPERATION_SCHEME: "OPERATION_SCHEME",
  SERVICE_OPERATION_CATEGORY: "SERVICE_OPERATION_CATEGORY",
  SERVICE_OPERATION_CATEGORY_WORKSPACE: "SERVICE_OPERATION_CATEGORY_WORKSPACE",
  SERVICE_DISCOUNT_WORKSPACE: "SERVICE_DISCOUNT_WORKSPACE",
  PROVIDER_CONTRACT_EARLY_PAYMENT: "PROVIDER_CONTRACT_EARLY_PAYMENT",
  ADVANCE_PAYMENT_PERCENTAGE: "ADVANCE_PAYMENT_PERCENTAGE",
  ADVANCE_PAYMENT_DISCOUNT_PERCENTAGE: "ADVANCE_PAYMENT_DISCOUNT_PERCENTAGE",
  ADVANCE_PAYMENT_AMOUNT: "ADVANCE_PAYMENT_AMOUNT",
  IS_EARLY_PAYMENT: "IS_EARLY_PAYMENT",
  PENDING_PROVIDER_PAYMENTS: "PENDING_PROVIDER_PAYMENTS",
  ADVANCE_PAYMENTS: "ADVANCE_PAYMENTS",
  PENDING_REIMBURSEMENT_PAYMENTS: "PENDING_REIMBURSEMENT_PAYMENTS",
  PENDING_BROKER_PAYMENTS: "PENDING_BROKER_PAYMENTS",
  BANKING_ACCOUNT_TYPE: "BANKING_ACCOUNT_TYPE",
  BANKING_ACCOUNT_TYPES: "BANKING_ACCOUNT_TYPES",
  BANKS: "BANKS",
  MAXIMUM_AMOUNT_PER_PAYMENT: "MAXIMUM_AMOUNT_PER_PAYMENT",
  SINGLE_PROVIDER_PAYMENT: "SINGLE_PROVIDER_PAYMENT",
  BANK_ACCOUNT_TYPES: "BANK_ACCOUNT_TYPES",
  PREFIX: "PREFIX",
  SERIAL_NUMBER_OF_DIGITS: "SERIAL_NUMBER_OF_DIGITS",
  SERIAL_START: "SERIAL_START",
  SERIAL_END: "SERIAL_END",
  NUMBER_OF_CHEQUES: "NUMBER_OF_CHEQUES",
  NEXT_AVAILABLE_SERIAL: "NEXT_AVAILABLE_SERIAL",
  HAS_AVAILABLE_CHEQUES: "HAS_AVAILABLE_CHEQUES",
  FOLLOWS_CHEQUE_BOOK: "FOLLOWS_CHEQUE_BOOK",
  CHEQUE_BOOKS: "CHEQUE_BOOKS",
  CHEQUE_BOOK: "CHEQUE_BOOK",
  ADJUSTMENT_DATE: "ADJUSTMENT_DATE",
  ADJUSTED_BY: "ADJUSTED_BY",
  NUMBER_OF_CHEQUES_TO_SKIP: "NUMBER_OF_CHEQUES_TO_SKIP",
  FROM_SERIAL: "FROM_SERIAL",
  TO_SERIAL: "TO_SERIAL",
  SERIAL_ADJUSTMENT: "SERIAL_ADJUSTMENT",
  HEADER_TITLE_LOCAL: "HEADER_TITLE_LOCAL",
  HEADER_TITLE_FOREIGN: "HEADER_TITLE_FOREIGN",
  COLUMN_ORDER: "COLUMN_ORDER",
  COLUMN_HEADER_TITLE_ALIAS: "COLUMN_HEADER_TITLE_ALIAS",
  PAYMENT_REPORTS: "PAYMENT_REPORTS",
  PAYMENT_DETAILS_COLUMNS: "PAYMENT_DETAILS_COLUMNS",
  DISPLAY_LANGUAGE: "DISPLAY_LANGUAGE",
  TRANSFER_PAYMENT_BY_BANK_ID: "TRANSFER_PAYMENT_BY_BANK_ID",
  TRANSFER_PAYMENT_BY_BANK_NAME: "TRANSFER_PAYMENT_BY_BANK_NAME",
  DRAW_COMMISSION_FROM_DESTINATION_ACCOUNT:
    "DRAW_COMMISSION_FROM_DESTINATION_ACCOUNT",
  DRAW_COMMISSION_FROM_SOURCE_ACCOUNT: "DRAW_COMMISSION_FROM_SOURCE_ACCOUNT",
  SOURCE_BANK_ACCOUNTS: "SOURCE_BANK_ACCOUNTS",
  NO_PAYMENTS_GENERATED: "NO_PAYMENTS_GENERATED",
  GENERATING: "GENERATING",
  PHONE_NUMBER: "PHONE_NUMBER",
  CUSTOMER: "CUSTOMER",
  PARENT_CUSTOMER: "PARENT_CUSTOMER",
  TAX_NUMBER: "TAX_NUMBER",
  COMMERCIAL_REGISTER_NUMBER: "COMMERCIAL_REGISTER_NUMBER",
  MEMO: "MEMO",
  CUSTOMERS: "CUSTOMERS",
  FINANCE: "FINANCE",
  COMMUNICATION_INFO: "COMMUNICATION_INFO",
  OPENING_BALANCE_DATE: "OPENING_BALANCE_DATE",
  TRANSACTION: "TRANSACTION",
  OPENING_BALANCES: "OPENING_BALANCES",
  BASIC_INFO: "BASIC_INFO",
  LEGAL_INFO: "LEGAL_INFO",
  OPENING_BALANCE: "OPENING_BALANCE",
  VENDORS: "VENDORS",
  FROZEN_DATE: "FROZEN_DATE",
  CONSULTATION_ELIGIBILITY: "CONSULTATION_ELIGIBILITY",
  PLEASE_ADD_APPOINTMENT_REQUEST_DATE: "PLEASE_ADD_APPOINTMENT_REQUEST_DATE",
  APPOINTMENT_REASON: "APPOINTMENT_REASON",
  PLEASE_CHOOSE_THE_AVAILABLE_PHYSICIAN:
    "PLEASE_CHOOSE_THE_AVAILABLE_PHYSICIAN",
  MODULES: "MODULES",
  COMMANDS: "COMMANDS",
  KEY: "KEY",
  MODULE: "MODULE",
  ANONYMOUS_ACCESS: "ANONYMOUS_ACCESS",
  RESET_AUTH: "RESET_AUTH",
  ACTIVE_AT_DATE: "ACTIVE_AT_DATE",
  FROM_ISSUANCE_DATE: "FROM_ISSUANCE_DATE",
  TO_ISSUANCE_DATE: "TO_ISSUANCE_DATE",
  HIDE_DENTIST_SPECIALTIES: "HIDE_DENTIST_SPECIALTIES",
  HAS_CLAIMS: "HAS_CLAIMS",
  CONTRACT_ACTIVE: "CONTRACT_ACTIVE",
  GROUP_ACTIVE: "GROUP_ACTIVE",
  FROM_SUPERVISION_DATE: "FROM_SUPERVISION_DATE",
  TO_SUPERVISION_DATE: "TO_SUPERVISION_DATE",
  IS_REMOVED: "IS_REMOVED",
  HAS_TRANSMITTALS: "HAS_TRANSMITTALS",
  PROVIDER_LIST_ONLY: "PROVIDER_LIST_ONLY",
  CUSTOMIZE: "CUSTOMIZE",
  PROVIDER_LOCAL_NAME: "PROVIDER_LOCAL_NAME",
  PROVIDER_FOREIGN_NAME: "PROVIDER_FOREIGN_NAME",
  PROVIDER_BANK_ACCOUNT_BANK: "PROVIDER_BANK_ACCOUNT_BANK",
  PROVIDER_BANK_ACCOUNT_BANK_BRANCH: "PROVIDER_BANK_ACCOUNT_BANK_BRANCH",
  CONTRACTS_INFO: "CONTRACTS_INFO",
  AT_ENDORSEMENT_DATE: "AT_ENDORSEMENT_DATE",
  INSURANCE_PRODUCTS: "INSURANCE_PRODUCTS",
  SUB_PRODUCT_DETAIL: "SUB_PRODUCT_DETAIL",
  SUB_PRODUCT_DESCRIPTION: "SUB_PRODUCT_DESCRIPTION",
  SUB_PRODUCTS: "SUB_PRODUCTS",
  SUB_PRODUCT_DETAILS: "SUB_PRODUCT_DETAILS",
  FROM_INSURED_JOIN_DATE: "FROM_INSURED_JOIN_DATE",
  TO_INSURED_JOIN_DATE: "TO_INSURED_JOIN_DATE",
  IS_OUT_STANDING: "IS_OUT_STANDING",
  BY_BENEFIT: "BY_BENEFIT",
  EMPLOYEE: "EMPLOYEE",
  EMPLOYEES: "EMPLOYEES",
  OVERRIDE_OLD_CLAIM_RULE: "OVERRIDE_OLD_CLAIM_RULE",
  PRESCRIPTION_APPROVAL: "PRESCRIPTION_APPROVAL",
  CHOOSE_CONSULTATION_TYPE: "CHOOSE_CONSULTATION_TYPE",
  NEW_CONSULTATION: "NEW_CONSULTATION",
  CHECKUP: "CHECKUP",
  CONSULTATION_FORM: "CONSULTATION_FORM",
  ROLE: "ROLE",
  COMMAND: "COMMAND",
  ADD_ACTION: "ADD_ACTION",
  UNADDED_ACTIONS: "UNADDED_ACTIONS",
  ADDING_ACTIONS: "ADDING_ACTIONS",
  ADD_COMMAND: "ADD_COMMAND",
  UNADDED_COMMANDS: "UNADDED_COMMANDS",
  ADDING_COMMANDS: "ADDING_COMMANDS",
  PREFERRED_PHYSICIAN: "PREFERRED_PHYSICIAN",
  GROUPS: "GROUPS",
  UN_FROZEN: "UN_FROZEN",
  FROM_PAYMENT_DATE: "FROM_PAYMENT_DATE",
  TO_PAYMENT_DATE: "TO_PAYMENT_DATE",
  FROM_PVP_DATE: "FROM_PVP_DATE",
  TO_PVP_DATE: "TO_PVP_DATE",

  INCLUDE_COMMENTS: "INCLUDE_COMMENTS",
  INCLUDE_SUPERVISION: "INCLUDE_SUPERVISION",
  INCLUDE_CONTRACT: "INCLUDE_CONTRACT",
  CANCELLED_SERVICES: "CANCELLED_SERVICES",
  CANCELLED_PRICING: "CANCELLED_PRICING",
  COMMUNICATION: "COMMUNICATION",
  PROVIDER_COMMUNICATIONS: "PROVIDER_COMMUNICATIONS",
  INSURED_COMMUNICATIONS: "INSURED_COMMUNICATIONS",
  PROVIDER_COMMUNICATION: "PROVIDER_COMMUNICATION",
  PROVIDER_MESSAGE: "PROVIDER_MESSAGE",
  COMMUNICATIONS: "COMMUNICATIONS",
  FUND_PROPERTIES: "FUND_PROPERTIES",
  RESYNC: "RESYNC",
  POST_TO_ALL_PLANS: "POST_TO_ALL_PLANS",
  UN_POST: "UN_POST",
  APPLY_ON_BROKER_PAYMENTS: "APPLY_ON_BROKER_PAYMENTS",
  APPLY_ON_PROVIDER_PAYMENTS: "APPLY_ON_PROVIDER_PAYMENTS",
  TAX_DETAIL: "TAX_DETAIL",
  TAX_ENDORSEMENT_TYPE: "TAX_ENDORSEMENT_TYPE",
  APPLY_ON: "APPLY_ON",
  ACTIVE_AT: "ACTIVE_AT",
  SHOW_BRANCHES: "SHOW_BRANCHES",
  CONTACT_NAME: "CONTACT_NAME",
  CARD_TYPES: "CARD_TYPES",
  CARD_TYPE_PRICE: "CARD_TYPE_PRICE",
  CARD_TYPE_PRICES: "CARD_TYPE_PRICES",
  FROM_RESOLUTION_DATE: "FROM_RESOLUTION_DATE",
  TO_RESOLUTION_DATE: "TO_RESOLUTION_DATE",
  HAS_BANK_ACCOUNT: "HAS_BANK_ACCOUNT",
  WITHOUT_MOBILE_NUMBER: "WITHOUT_MOBILE_NUMBER",
  ADDING_ROLES: "ADDING_ROLES",
  UNADDED_ROLES: "UNADDED_ROLES",
  ADD_ROLE: "ADD_ROLE",
  MANAGE_ROLE: "MANAGE_ROLE",
  CHECK_FOR_VISITS_WITHIN_CHECKUP_PERIOD:
    "CHECK_FOR_VISITS_WITHIN_CHECKUP_PERIOD",
  NO_EARLIER_VISITS_WITHIN_CHECKUP_PERIOD:
    "NO_EARLIER_VISITS_WITHIN_CHECKUP_PERIOD",
  EARLIER_DOCTOR_VISIT_FOUND: "EARLIER_DOCTOR_VISIT_FOUND",
  INSURED_MESSAGE: "INSURED_MESSAGE",
  ENDORSEMENT_TYPES: "ENDORSEMENT_TYPES",
  CHARGES_TITLE: "CHARGES_TITLE",
  IS_ACTIVE_IN_POLICY: "IS_ACTIVE_IN_POLICY",
  INCLUDE_ISSUANCE: "INCLUDE_ISSUANCE",
  INCLUDE_PREMIUM: "INCLUDE_PREMIUM",
  TO_POLICY_START_DATE: "TO_POLICY_START_DATE",
  ALLOWED_CASES: "ALLOWED_CASES",
  RESULTS: "RESULTS",
  CHEMICAL_COMPOUNDS: "CHEMICAL_COMPOUNDS",
  ADD_COMMENT: "ADD_COMMENT",
  REINSURERS: "REINSURERS",
  RELATION_OFFICER_TYPES: "RELATION_OFFICER_TYPES",
  INSURANCE_COMPANY_BENEFITS: "INSURANCE_COMPANY_BENEFITS",
  INSURANCE_COMPANY_BENEFITS_BRIEF: "INSURANCE_COMPANY_BENEFITS_BRIEF",
  INSURANCE_COMPANY_BENEFIT: "INSURANCE_COMPANY_BENEFIT",
  INSURANCE_BENEFIT: "INSURANCE_BENEFIT",
  BENEFIT_MAPPING_TEMPLATES: "BENEFIT_MAPPING_TEMPLATES",
  BENEFIT_MAPPING: "BENEFIT_MAPPING",
  NOT_FUND_RELATED: "NOT_FUND_RELATED",
  FUND_RELATED: "FUND_RELATED",
  POLICYHOLDER_TYPES: "POLICYHOLDER_TYPES",
  CLASS_TYPES: "CLASS_TYPES",
  PLAN_TYPES: "PLAN_TYPES",
  INSURANCE_TYPES: "INSURANCE_TYPES",
  POLICY_CLASSIFICATIONS: "POLICY_CLASSIFICATIONS",
  ACCOUNT_CATEGORY: "ACCOUNT_CATEGORY",
  ACCOUNT_CATEGORIES: "ACCOUNT_CATEGORIES",
  OPENING_BALANCE_ENTITY_TYPE: "OPENING_BALANCE_ENTITY_TYPE",
  ACCOUNT_NUMBER_ACCOUNTING_SYSTEM: "ACCOUNT_NUMBER_ACCOUNTING_SYSTEM",
  ALLOW_IN_TRANSACTIONS: "ALLOW_IN_TRANSACTIONS",
  ACCOUNTS: "ACCOUNTS",
  PARENT_ACCOUNT: "PARENT_ACCOUNT",
  IS_TAX_ACCOUNT: "IS_TAX_ACCOUNT",
  IS_ADDITIONAL_TAX: "IS_ADDITIONAL_TAX",
  PARENT_COST_CENTER: "PARENT_COST_CENTER",
  COST_CENTERS: "COST_CENTERS",
  NUMBER_OF_PERIODS: "NUMBER_OF_PERIODS",
  PERIOD_GENERATED: "PERIOD_GENERATED",
  GENERATED_BY: "GENERATED_BY",
  GENERATION_DATE: "GENERATION_DATE",
  FISCAL_YEAR: "FISCAL_YEAR",
  FISCAL_YEARS: "FISCAL_YEARS",
  CLOSING_TRANSACTIONS_GENERATED: "CLOSING_TRANSACTIONS_GENERATED",
  OPENING_BALANCE_TRANSACTIONS_GENERATED:
    "OPENING_BALANCE_TRANSACTIONS_GENERATED",
  ADJUSTMENT_PERIOD: "ADJUSTMENT_PERIOD",
  CLOSING_DATE: "CLOSING_DATE",
  RE_OPENED_BY: "RE_OPENED_BY",
  RE_OPENING_DATE: "RE_OPENING_DATE",
  ACCOUNTING_PERIODS: "ACCOUNTING_PERIODS",
  BUDGET_PERIODS: "BUDGET_PERIODS",
  TRANSACTION_DATE: "TRANSACTION_DATE",
  TOTAL_CREDIT_AMOUNT: "TOTAL_CREDIT_AMOUNT",
  TOTAL_DEBIT_AMOUNT: "TOTAL_DEBIT_AMOUNT",
  TRANSACTION_TYPE: "TRANSACTION_TYPE",
  ACCOUNTING_PERIOD: "ACCOUNTING_PERIOD",
  SYSTEM_ENTITY: "SYSTEM_ENTITY",
  UNLINKED_ENTITIES: "UNLINKED_ENTITIES",
  IS_POSTED: "IS_POSTED",
  POSTED_BY: "POSTED_BY",
  POST_DATE: "POST_DATE",
  IS_AUDITED: "IS_AUDITED",
  IS_DETACHED: "IS_DETACHED",
  DETACHED_BY: "DETACHED_BY",
  DETACHMENT_DETAILS: "DETACHMENT_DETAILS",
  DETACHMENT_DATE: "DETACHMENT_DATE",
  IS_EXPORTED: "IS_EXPORTED",
  EXPORT_DATE: "EXPORT_DATE",
  IS_REVERSE: "IS_REVERSE",
  REVERSED_TRANSACTION: "REVERSED_TRANSACTION",
  FROM_FISCAL_YEAR: "FROM_FISCAL_YEAR",
  REVOKE_ACTION: "REVOKE_ACTION",
  INVOKE_COMMAND: "INVOKE_COMMAND",
  RELATED_ITEM: "RELATED_ITEM",
  VALUE_DATE: "VALUE_DATE",
  CREDIT_AMOUNT: "CREDIT_AMOUNT",
  POST_AND_AUDIT: "POST_AND_AUDIT",
  REVERSE: "REVERSE",
  REVERSE_DATE: "REVERSE_DATE",
  SETTING_VALUE: "SETTING_VALUE",
  MAX_CLAIM_AMOUNT: "MAX_CLAIM_AMOUNT",
  MAX_CLAIM_TYPE_AMOUNT: "MAX_CLAIM_TYPE_AMOUNT",
  MAX_CLAIM_TYPE_COUNT: "MAX_CLAIM_TYPE_COUNT",
  MAX_MONTHLY_CLAIM_TYPE_COUNT: "MAX_MONTHLY_CLAIM_TYPE_COUNT",
  MAX_SERVICE_REQUEST_QUANTITY: "MAX_SERVICE_REQUEST_QUANTITY",
  MAX_SERVICE_COUNT: "MAX_SERVICE_COUNT",
  FORCE_APPROVAL_MAX_AMOUNT: "FORCE_APPROVAL_MAX_AMOUNT",
  ENABLE_TOTAL_CLAIM_COUNT_EVALUATION: "ENABLE_TOTAL_CLAIM_COUNT_EVALUATION",
  APPROVAL_ENGINE: "APPROVAL_ENGINE",
  DENY_APPROVAL: "DENY_APPROVAL",
  APPLY_PROVIDER: "APPLY_PROVIDER",
  APPLY_TREATMENT_DOCTOR: "APPLY_TREATMENT_DOCTOR",
  AGE_INDICATOR: "AGE_INDICATOR",
  AGE_INDICATORS: "AGE_INDICATORS",
  GENERAL_SETTINGS: "GENERAL_SETTINGS",
  CLAIM_TYPE_SETTINGS: "CLAIM_TYPE_SETTINGS",
  PROVIDER_SPECIALTY_SETTINGS: "PROVIDER_SPECIALTY_SETTINGS",
  INSURED_UNDER_SUPERVISION: "INSURED_UNDER_SUPERVISION",
  INSURED_UNDER_SUPERVISION_WEIGHT: "INSURED_UNDER_SUPERVISION_WEIGHT",
  PROVIDER_UNDER_SUPERVISION: "PROVIDER_UNDER_SUPERVISION",
  PROVIDER_UNDER_SUPERVISION_WEIGHT: "PROVIDER_UNDER_SUPERVISION_WEIGHT",
  PHYSICIAN_UNDER_SUPERVISION: "PHYSICIAN_UNDER_SUPERVISION",
  PHYSICIAN_UNDER_SUPERVISION_WEIGHT: "PHYSICIAN_UNDER_SUPERVISION_WEIGHT",
  INSURED_IS_VIP: "INSURED_IS_VIP",
  INSURED_IS_VIP_WEIGHT: "INSURED_IS_VIP_WEIGHT",
  LINKED_TO_CHRONIC_FORM: "LINKED_TO_CHRONIC_FORM",
  LINKED_TO_CHRONIC_FORM_WEIGHT: "LINKED_TO_CHRONIC_FORM_WEIGHT",
  REQUESTED_AMOUNT_WITH_WEIGHT: "REQUESTED_AMOUNT_WITH_WEIGHT",
  CLAIM_TYPE_COUNT_WITH_WEIGHT: "CLAIM_TYPE_COUNT_WITH_WEIGHT",
  APPROVAL_SEGMENTS: "APPROVAL_SEGMENTS",
  POLICY_CLAIM_TYPE_RULES: "POLICY_CLAIM_TYPE_RULES",
  ATC_INGREDIENTS: "ATC_INGREDIENTS",
  ATC_INGREDIENT_LEVEL_1: "ATC_INGREDIENT_LEVEL_1",
  ATC_INGREDIENT_LEVEL_2: "ATC_INGREDIENT_LEVEL_2",
  ATC_INGREDIENT_LEVEL_3: "ATC_INGREDIENT_LEVEL_3",
  ATC_INGREDIENT_LEVEL_4: "ATC_INGREDIENT_LEVEL_4",
  WORK_SPACES: "WORK_SPACES",
  SERVICE_INDICATORS: "SERVICE_INDICATORS",
  GENDER_INDICATOR: "GENDER_INDICATOR",
  SERVICE_INDICATOR: "SERVICE_INDICATOR",
  DIAGNOSIS_INDICATORS: "DIAGNOSIS_INDICATORS",
  DIAGNOSIS_INDICATOR: "DIAGNOSIS_INDICATOR",
  SECURITY_GROUP: "SECURITY_GROUP",
  FIRST_NAME: "FIRST_NAME",
  LAST_NAME: "LAST_NAME",
  USER_PICTURE: "USER_PICTURE",
  DISABLED: "DISABLED",
  IS_TPA: "IS_TPA",
  IS_INSURANCE_COMPANY: "IS_INSURANCE_COMPANY",
  IS_POLICY_HOLDER: "IS_POLICY_HOLDER",
  IS_PROVIDER: "IS_PROVIDER",
  IS_PROVIDER_MANAGEMENT: "IS_PROVIDER_MANAGEMENT",
  IS_INSURED: "IS_INSURED",
  IS_REINSURER: "IS_REINSURER",
  APPROVAL_AUTHORIZED_AMOUNT: "APPROVAL_AUTHORIZED_AMOUNT",
  CHRONIC_FORM_AUTHORIZED_AMOUNT: "CHRONIC_FORM_AUTHORIZED_AMOUNT",
  OVERRIDE_HR_APPROVAL: "OVERRIDE_HR_APPROVAL",
  OVERRIDE_POLICY_EXP: "OVERRIDE_POLICY_EXP",
  OVERRIDE_APPROVAL_EXP: "OVERRIDE_APPROVAL_EXP",
  ACCESS_MEDICAL_NETWORK: "ACCESS_MEDICAL_NETWORK",
  ENABLE_PORTAL_APPROVAL_REQUEST: "ENABLE_PORTAL_APPROVAL_REQUEST",
  IN_HOUSE_PROVIDER: "IN_HOUSE_PROVIDER",
  APPROVAL_REQUEST_DEFAULT_PROVIDER: "APPROVAL_REQUEST_DEFAULT_PROVIDER",
  PROVIDER_MANAGEMENT: "PROVIDER_MANAGEMENT",
  FORCE_LOG_OFF: "FORCE_LOG_OFF",
  DEFAULT_REQUEST_PROVIDER: "DEFAULT_REQUEST_PROVIDER",
  EDIT_USERNAME: "EDIT_USERNAME",
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
  SIGNATURE: "SIGNATURE",
  ATTRIBUTE: "ATTRIBUTE",
  USER_IS_DISABLED: "USER_IS_DISABLED",
  BENEFIT_MAPPING_TEMPLATES_BRIEF: "BENEFIT_MAPPING_TEMPLATES_BRIEF",
  PERSON_LIST: "PERSON_LIST",
  MOBILE_NUMBER_WORKSPACES: "MOBILE_NUMBER_WORKSPACES",
  MOBILE_NUMBER_WORKSPACE: "MOBILE_NUMBER_WORKSPACE",
  MOBILE_WORKSPACES: "MOBILE_WORKSPACES",
  ENTITY_HISTORY: "ENTITY_HISTORY",
  DECLARED_MEDICAL_CONDITIONS: "DECLARED_MEDICAL_CONDITIONS",
  DECLARED_MEDICAL_CONDITION: "DECLARED_MEDICAL_CONDITION",
  FUND_INSTALLMENTS: "FUND_INSTALLMENTS",
  RECEIVED_ONLY: "RECEIVED_ONLY",
  PROCESSED_ONLY: "PROCESSED_ONLY",
  MAIN_PROVIDER: "MAIN_PROVIDER",
  EDIT_USER: "EDIT_USER",
  CREATE_USER: "CREATE_USER",
  INCLUDE_PROVIDER_DISTRIBUTION: "INCLUDE_PROVIDER_DISTRIBUTION",
  INCLUDE_TREATMENT_DOCTOR_DISTRIBUTION:
    "INCLUDE_TREATMENT_DOCTOR_DISTRIBUTION",
  SERVICE_UNIT_TYPE: "SERVICE_UNIT_TYPE",
  IS_TPA_COMPANY: "IS_TPA_COMPANY",
  PRIVILEGES: "PRIVILEGES",
  POLICY_HOLDER_SETTINGS: "POLICY_HOLDER_SETTINGS",
  DEFAULT_TREATMENT_DOCTOR_ID: "DEFAULT_TREATMENT_DOCTOR_ID",
  OVERRIDE_SUPERVISION_FLAG: "OVERRIDE_SUPERVISION_FLAG",
  E_CARD: "E_CARD",
  UTILIZATION_RESET: "UTILIZATION_RESET",
  HAS_SUB_INVOICES: "HAS_SUB_INVOICES",
  HAS_PARENT_INVOICES: "HAS_PARENT_INVOICES",
  LINKED_TO_EMAIL: "LINKED_TO_EMAIL",
  EMAIL_SENT: "EMAIL_SENT",
  TO_PROCESS_DATE: "TO_PROCESS_DATE",
  FROM_PROCESS_DATE: "FROM_PROCESS_DATE",
  RELATED_CLIENT: "RELATED_CLIENT",
  RELATED_SECTOR: "RELATED_SECTOR",
  RELATED_PROVIDER: "RELATED_PROVIDER",
  RELATED_INSURANCE_COMPANY: "RELATED_INSURANCE_COMPANY",
  BILL_ENTITY: "BILL_ENTITY",
  INSURED_PERSON_INFORMATION: "INSURED_PERSON_INFORMATION",
  APPLICANT: "APPLICANT",
  PRIMARY_APPLICANT: "PRIMARY_APPLICANT",
  COPY_LOCAL_TO_FOREIGN: "COPY_LOCAL_TO_FOREIGN",
  UTILITIES: "UTILITIES",
  PICTURE: "PICTURE",
  GEOGRAPHY: "GEOGRAPHY",
  LOCATIONS: "LOCATIONS",
  GOVERNORATES: "GOVERNORATES",
  GEOGRAPHICAL_SCOPES: "GEOGRAPHICAL_SCOPES",
  CARD_INFORMATION: "CARD_INFORMATION",
  NATIONALITY_NAME_LOCAL: "NATIONALITY_NAME_LOCAL",
  NATIONALITY_NAME_FOREIGN: "NATIONALITY_NAME_FOREIGN",
  ISO_CODE: "ISO_CODE",
  LATEST_RESULT: "LATEST_RESULT",
  CLAIMS_LINKED_TO_RESULTS: "CLAIMS_LINKED_TO_RESULTS",
  INCLUDE_DETAILS: "INCLUDE_DETAILS",
  MOVE: "MOVE",
  FOLLOWING_BATCHES_NOT_MOVED: "FOLLOWING_BATCHES_NOT_MOVED",
  SURE_TO_MOVE_ALL_BATCHES: "SURE_TO_MOVE_ALL_BATCHES",
  SET_AS_MANAGEMENT: "SET_AS_MANAGEMENT",
  MANAGEMENT_USER_SAME_AS_APPROVALS_USER:
    "MANAGEMENT_USER_SAME_AS_APPROVALS_USER",
  CREATE_NEW_MANAGEMENT_USER: "CREATE_NEW_MANAGEMENT_USER",
  PROVIDER_APPROVALS_USER: "PROVIDER_APPROVALS_USER",
  CAN_APPROVE_JUMBO_CLAIMS: "CAN_APPROVE_JUMBO_CLAIMS",
  CLAIM_FORM_DUPLICATES: "CLAIM_FORM_DUPLICATES",
  CLAIM_FORM_DUPLICATES_DETAILS: "CLAIM_FORM_DUPLICATES_DETAILS",
  DEFAULT_APPROVAL_COMMENT: "DEFAULT_APPROVAL_COMMENT",
  OVERRIDE_OLD_CLAIMS: "OVERRIDE_OLD_CLAIMS",
  OPEN_TASK: "OPEN_TASK",
  DETACH_TASK: "DETACH_TASK",
  FILE_REVIEWS: "FILE_REVIEWS",
  FILE_REVIEW: "FILE_REVIEW",
  FILE_REVIEW_TYPE: "FILE_REVIEW_TYPE",
  ACTION_DATE: "ACTION_DATE",
  DIAGNOSTIC_TEST_RESULTS: "DIAGNOSTIC_TEST_RESULTS",
  DIAGNOSTIC_TEST_RESULT: "DIAGNOSTIC_TEST_RESULT",
  DIAGNOSTIC_TEST_TYPE: "DIAGNOSTIC_TEST_TYPE",
  RESULT_TEXT: "RESULT_TEXT",
  CLAIM_FORM: "CLAIM_FORM",
  NOTIFY_USER: "NOTIFY_USER",
  USER_NOTIFIED: "USER_NOTIFIED",
  SEARCH_BY: "SEARCH_BY",
  PROTOCOL_PRICING: "PROTOCOL_PRICING",
  HAS_ACTIVE_CONTRACT: "HAS_ACTIVE_CONTRACT",
  REIMBURSEMENT_REQUEST_ID: "REIMBURSEMENT_REQUEST_ID",
  RECEIPT: "RECEIPT",
  SERVICE_SEARCH: "SERVICE_SEARCH",
  PROVIDER_SEARCH: "PROVIDER_SEARCH",
  ALERT: "ALERT",
  NOT_ALLOWED: "NOT_ALLOWED",
  SET_ALLOWED: "SET_ALLOWED",
  SET_NOT_ALLOWED: "SET_NOT_ALLOWED",
  MEDICAL_CONDITION_SERVICE: "MEDICAL_CONDITION_SERVICE",
  FORM_MEDICAL_CONDITIONS: "FORM_MEDICAL_CONDITIONS",
  FORM_MEDICAL_CONDITION: "FORM_MEDICAL_CONDITION",
  REGIONS: "REGIONS",
  REGION: "REGION",
  REGION_COUNTRY: "REGION_COUNTRY",
  BILLING_TYPE: "BILLING_TYPE",
  CURRENT_BALANCE: "CURRENT_BALANCE",
  TREASURIES: "TREASURIES",
  TREASURY: "TREASURY",
  CURRENT_BALANCE_LOCAL: "CURRENT_BALANCE_LOCAL",
  TRANSFER_RATE: "TRANSFER_RATE",
  SEARCH_MEDICAL_NETWORK: "SEARCH_MEDICAL_NETWORK",
  PROVIDER_REGISTRATION_FORM: "PROVIDER_REGISTRATION_FORM",
  IN_CASE_OF_DOCTOR_PLEASE_ENTER_FIRST_LAST_NAMES:
    "IN_CASE_OF_DOCTOR_PLEASE_ENTER_FIRST_LAST_NAMES",
  ENTER_CITY_CODE_WITH_PHONE_NUMBER: "ENTER_CITY_CODE_WITH_PHONE_NUMBER",
  APPROVAL_ASSIGNMENT_RULES: "APPROVAL_ASSIGNMENT_RULES",
  MAXIMUM_ASSIGNED_APPROVAL_PER_USER: "MAXIMUM_ASSIGNED_APPROVAL_PER_USER",
  APPROVAL_ASSIGNMENT_RULE: "APPROVAL_ASSIGNMENT_RULE",
  MAX_APPROVALS_PER_USER: "MAX_APPROVALS_PER_USER",
  GENERAL_SETTING: "GENERAL_SETTING",
  APPROVAL_SEGMENT: "APPROVAL_SEGMENT",
  REQUESTED_AMOUNT_FROM: "REQUESTED_AMOUNT_FROM",
  REQUESTED_AMOUNT_TO: "REQUESTED_AMOUNT_TO",
  CLAIM_TYPE_COUNT_FROM: "CLAIM_TYPE_COUNT_FROM",
  CLAIM_TYPE_COUNT_TO: "CLAIM_TYPE_COUNT_TO",
  CLAIM_TYPE_COUNT: "CLAIM_TYPE_COUNT",
  MAXIMUM_MONTHLY_CLAIM_TYPE_COUNT: "MAXIMUM_MONTHLY_CLAIM_TYPE_COUNT",
  FORCE_AUTOMATIC_APPROVAL_MAX_AMOUNT: "FORCE_AUTOMATIC_APPROVAL_MAX_AMOUNT",
  CLAIM_TYPE_SETTING: "CLAIM_TYPE_SETTING",
  APPROVAL_SEGMENT_CONFIGURATION: "APPROVAL_SEGMENT_CONFIGURATION",
  PLAN_COUNTRY: "PLAN_COUNTRY",
  MEDICAL_FIELD: "MEDICAL_FIELD",
  OPEN_IN_NEW_TAB: "OPEN_IN_NEW_TAB",
  IS_API_USER: "IS_API_USER",
  APP_KEY: "APP_KEY",
  UNSET_AS_MANAGEMENT: "UNSET_AS_MANAGEMENT",
  SERVICE_CANCELLATION_DATE: "SERVICE_CANCELLATION_DATE",
  SERVICE_FOLLOWUP_DATE: "SERVICE_FOLLOWUP_DATE",
  SPECIFIC_POLICIES: "SPECIFIC_POLICIES",
  UPDATED_FROM_DATE: "UPDATED_FROM_DATE",
  UPDATED_TO_DATE: "UPDATED_TO_DATE",
  CHRONIC_FORM_IS_ACTIVE: "CHRONIC_FORM_IS_ACTIVE",
  GPS_LOCATION: "GPS_LOCATION",
  HAS_GPS_LOCATION: "HAS_GPS_LOCATION",
  ACCOUNT_BASIC_TYPE: "ACCOUNT_BASIC_TYPE",
  ACCOUNT_TYPES: "ACCOUNT_TYPES",
  SHOW_IN_BUDGET: "SHOW_IN_BUDGET",
  ACCOUNTING_ITEMS: "ACCOUNTING_ITEMS",
  TOTAL_WEIGHT: "TOTAL_WEIGHT",
  EXCLUDE_POOL_AND_FUND: "EXCLUDE_POOL_AND_FUND",
  CLAIMS_COUNT: "CLAIMS_COUNT",
  GROUP_BY_BRANCHES: "GROUP_BY_BRANCHES",
  LATEST_RENEWALS_ONLY: "LATEST_RENEWALS_ONLY",
  ISSUANCE_ONLY: "ISSUANCE_ONLY",
  SET_ATTACHMENT_DESCRIPTION: "SET_ATTACHMENT_DESCRIPTION",
  NO_REFUND_WHEN_INSURED_HAS_CLAIMS_ON_BENEFIT:
    "NO_REFUND_WHEN_INSURED_HAS_CLAIMS_ON_BENEFIT",
  UPDATE_CARD_TYPE: "UPDATE_CARD_TYPE",
  RECALCULATE_CARD_AMOUNTS: "RECALCULATE_CARD_AMOUNTS",
  FOLLOWING_SERVICES_NOT_ACCEPTED: "FOLLOWING_SERVICES_NOT_ACCEPTED",
  TREASURY_TRANSACTION_DETAIL: "TREASURY_TRANSACTION_DETAIL",
  SCOPES: "SCOPES",
  TRANSFER: "TRANSFER",
  TRANSACTION_DETAIL: "TRANSACTION_DETAIL",
  ALLOW_PRICE_UPDATE: "ALLOW_PRICE_UPDATE",
  IS_ADDED_TO_PVC: "IS_ADDED_TO_PVC",
  IS_VALIDATED: "IS_VALIDATED",
  ORIGINALS_RECEIVED: "ORIGINALS_RECEIVED",
  PRICE_UPDATE_REQUESTS: "PRICE_UPDATE_REQUESTS",
  AUTOMATICALLY_ACCEPTED: "AUTOMATICALLY_ACCEPTED",
  ONE_TIME_ACCEPT: "ONE_TIME_ACCEPT",
  ACCEPT_SIMILAR_REQUESTS: "ACCEPT_SIMILAR_REQUESTS",
  SET_AS_DEFAULT_PRICING: "SET_AS_DEFAULT_PRICING",
  CANCEL_EXISTING_PRICING: "CANCEL_EXISTING_PRICING",
  FOR_THIS_PROVIDER: "FOR_THIS_PROVIDER",
  SIMILAR_REQUESTS: "SIMILAR_REQUESTS",
  NO_APPROVAL_REQUIRED: "NO_APPROVAL_REQUIRED",
  BATCH_CLAIM_WORKSPACE: "BATCH_CLAIM_WORKSPACE",
  BATCH_CLAIM_DUPLICATES: "BATCH_CLAIM_DUPLICATES",
  BATCH_CLAIM_DUPLICATES_DETAILS: "BATCH_CLAIM_DUPLICATES_DETAILS",
  THIRD_PARTY_APPROVAL_REQUIRED: "THIRD_PARTY_APPROVAL_REQUIRED",
  REQUIRED_APPROVALS: "REQUIRED_APPROVALS",
  OVERRIDE_APPROVAL_REQUIREMENTS: "OVERRIDE_APPROVAL_REQUIREMENTS",
  APPROVAL_RECEIVED: "APPROVAL_RECEIVED",
  OVERRIDE_THIRD_PARTY_APPROVAL: "OVERRIDE_THIRD_PARTY_APPROVAL",
  OVERRIDE_EXCLUSION: "OVERRIDE_EXCLUSION",
  REQUEST_DOSE_COUNT: "REQUEST_DOSE_COUNT",
  PERSON_WORKSPACE: "PERSON_WORKSPACE",
  MATCH_IDENTITY_NAME_GENDER_DATE: "MATCH_IDENTITY_NAME_GENDER_DATE",
  SHOW_CANCELLED_SERVICES: "SHOW_CANCELLED_SERVICES",
  SHOW_SERVICE_BALANCE: "SHOW_SERVICE_BALANCE",
  SHOW_DELETED_ATTACHMENTS: "SHOW_DELETED_ATTACHMENTS",
  POLICYHOLDERS_INDIVIDUAL: "POLICYHOLDERS_INDIVIDUAL",
  MISSING_ONLY: "MISSING_ONLY",
  INCLUDE_ADJUSTED_CLAIMS: "INCLUDE_ADJUSTED_CLAIMS",
  IN_HOUSE_DOCTORS_ONLY: "IN_HOUSE_DOCTORS_ONLY",
  EXCLUDE_IN_HOUSE_DOCTORS: "EXCLUDE_IN_HOUSE_DOCTORS",
  IN_PVC: "IN_PVC",
  ADD_MULTIPLE: "ADD_MULTIPLE",
  BULK_PAYMENT_RECIPIENT_PROCESSING: "BULK_PAYMENT_RECIPIENT_PROCESSING",
  BILL_PAYMENT_PROCESSING_WORKSPACE: "BILL_PAYMENT_PROCESSING_WORKSPACE",
  PDF: "PDF",
  EXCEL: "EXCEL",
  ALL_SERVICES: "ALL_SERVICES",
  IN_SCHEME_ONLY: "IN_SCHEME_ONLY",
  APPROVAL_NOT_RECEIVED: "APPROVAL_NOT_RECEIVED",
  WAIVE_ATTACHMENT: "WAIVE_ATTACHMENT",
  OVERRIDE_VALIDITY_CHECK: "OVERRIDE_VALIDITY_CHECK",
  REQUIRES_APPROVAL_REASONS: "REQUIRES_APPROVAL_REASONS",
  ATTACHMENT_WAIVED: "ATTACHMENT_WAIVED",
  INBOX: "INBOX",
  USER_TASKS: "USER_TASKS",
  ASSIGN_TO_USER: "ASSIGN_TO_USER",
  ASSIGN_TO_DEPARTMENT: "ASSIGN_TO_DEPARTMENT",
  ASSIGN_TO_TEAM: "ASSIGN_TO_TEAM",
  APPEAR_OFFLINE: "APPEAR_OFFLINE",
  APPEAR_ONLINE: "APPEAR_ONLINE",
  TRACK_REIMBURSEMENT_SUBMISSIONS: "TRACK_REIMBURSEMENT_SUBMISSIONS",
  SHOW_MY_REQUESTS_ONLY: "SHOW_MY_REQUESTS_ONLY",
  EXPORT_PVI: "EXPORT_PVI",
  EXPORT_CLAIMS: "EXPORT_CLAIMS",
  EXPORT_CLIENT_SUMMARY: "EXPORT_CLIENT_SUMMARY",
  EXPORT_PVI_CLIENT_SUMMARY: "EXPORT_PVI_CLIENT_SUMMARY",
  CLAIMED: "CLAIMED",
  NUMBER_OF_PROVIDER_INVOICES: "NUMBER_OF_PROVIDER_INVOICES",
  YES_INCLUDE_CONSULTATION: "YES_INCLUDE_CONSULTATION",
  NO_CLINICAL_SERVICES_ONLY: "NO_CLINICAL_SERVICES_ONLY",
  OVER_PAY: "OVER_PAY",
  SHOW_DELETED_SUB_CATEGORIES: "SHOW_DELETED_SUB_CATEGORIES",
  ALLOW_ANONYMOUS_ACCESS: "ALLOW_ANONYMOUS_ACCESS",
  IS_VIEWER_ONLY: "IS_VIEWER_ONLY",
  ENABLE_LOGING: "ENABLE_LOGING",
  STORE_BODY: "STORE_BODY",
  ENABLE_ON_ALL_COMMAND: "ENABLE_ON_ALL_COMMAND",
  ALLOW_ANONYMOUS: "ALLOW_ANONYMOUS",
  OPEN_LATEST: "OPEN_LATEST",
  REJECTED_APPROVALS_ONLY: "REJECTED_APPROVALS_ONLY",
  INSURED_MOBILE_NUMBER: "INSURED_MOBILE_NUMBER",
  APPROVAL_REQUEST_ASSIGNMENT_RULE: "APPROVAL_REQUEST_ASSIGNMENT_RULE",
  APPROVAL_REQUEST_ASSIGNMENT_RULES: "APPROVAL_REQUEST_ASSIGNMENT_RULES",
  REQUEST_ASSIGNMENT_RULES: "REQUEST_ASSIGNMENT_RULES",
  CLAIM_SUBMISSION_PERIOD: "CLAIM_SUBMISSION_PERIOD",
  SCANNED_CLAIMS: "SCANNED_CLAIMS",
  ATTACHMENT_ID: "ATTACHMENT_ID",
  CLAIMS_LINKED_TO_FUND_ONLY: "CLAIMS_LINKED_TO_FUND_ONLY",
  DOWNLOAD_BUNDLE: "DOWNLOAD_BUNDLE",
  INVOICE_DETAILS: "INVOICE_DETAILS",
  CLAIM_DETAILS: "CLAIM_DETAILS",
  PAYER_DATA: "PAYER_DATA",
  PVI_CLIENT_SUMMARY: "PVI_CLIENT_SUMMARY",
  CHRONIC_FORM_REQUESTS: "CHRONIC_FORM_REQUESTS",
  CHRONIC_FORM_REQUEST: "CHRONIC_FORM_REQUEST",
  IS_OLD: "IS_OLD",
  DOWNLOADS: "DOWNLOADS",
  GROSS_CLAIMED: "GROSS_CLAIMED",
  ACCEPTED_CLAIMED: "ACCEPTED_CLAIMED",
  NET_CLAIMED: "NET_CLAIMED",
  SET_DEFAULT_VALUES: "SET_DEFAULT_VALUES",
  PVI_GROUP_INVOICE: "PVI_GROUP_INVOICE",
  FROM_PVI_ID: "FROM_PVI_ID",
  TO_PVI_ID: "TO_PVI_ID",
  EXPORT_PVI_GROUP: "EXPORT_PVI_GROUP",
  FIELDS_TO_UPDATE: "FIELDS_TO_UPDATE",
  BY_PVI: "BY_PVI",
  BY_INVOICE: "BY_INVOICE",
  ALL_SELECTED_COMMANDS_ARE_ADDED: "ALL_SELECTED_COMMANDS_ARE_ADDED",
  ALL_SELECTED_ACTIONS_ARE_ADDED: "ALL_SELECTED_ACTIONS_ARE_ADDED",
  ALL_SELECTED_ROLE_ARE_ADDED: "ALL_SELECTED_ROLE_ARE_ADDED",
  ASSIGNED_BY: "ASSIGNED_BY",
  CLAIM_FORM_TYPE_SEGMENT: "CLAIM_FORM_TYPE_SEGMENT",
  UNREAD: "UNREAD",
  READ: "READ",
  EXPAND: "EXPAND",
  COLLAPSE: "COLLAPSE",
  CLAIM_TYPE_STAKEHOLDER: "CLAIM_TYPE_STAKEHOLDER",
  INITIAL_RENEAL_JOIN_DATE: "INITIAL_RENEAL_JOIN_DATE",
  LAST_RENEWAL_JOIN_DATE: "LAST_RENEWAL_JOIN_DATE",
  INITIAL_POLICY_JOIN_DATE: "INITIAL_POLICY_JOIN_DATE",
  LAST_CONTINUOUS_JOIN_DATE: "LAST_CONTINUOUS_JOIN_DATE",
  POLICY_JOIN_DATE: "POLICY_JOIN_DATE",
  ADDITIONAL_NON_REFUNDABLE_PREMIUM_PERCENTAGE:
    "ADDITIONAL_NON_REFUNDABLE_PREMIUM_PERCENTAGE",
  CLAIMS_SCANNED: "CLAIMS_SCANNED",
  CLAIMS_SCANNED_DATE: "CLAIMS_SCANNED_DATE",
  CLAIMS_SCANNED_BY: "CLAIMS_SCANNED_BY",
  INCLUDE_PRIMARY_INSURED: "INCLUDE_PRIMARY_INSURED",
  INCLUDE_PROVIDERS: "INCLUDE_PROVIDERS",
  INCLUDE_ALL_ALERTS: "INCLUDE_ALL_ALERTS",
  INCLUDE_AUDITED: "INCLUDE_AUDITED",
  POLICY_LIMIT: "POLICY_LIMIT",
  INSURED_LIMIT: "INSURED_LIMIT",
  PRODUCT_LIMIT: "PRODUCT_LIMIT",
  BENEFIT_LIMIT: "BENEFIT_LIMIT",
  PRODUCT_COUNT_LIMIT: "PRODUCT_COUNT_LIMIT",
  BENEFIT_COUNT_LIMIT: "BENEFIT_COUNT_LIMIT",
  AUTOMATIC: "AUTOMATIC",
  EXCLUDE_CHRONIC_FORMS: "EXCLUDE_CHRONIC_FORMS",
  POLICY_HOLDER_BRANCH: "POLICY_HOLDER_BRANCH",
  UNSUSPEND: "UNSUSPEND",
  SUSPENSION_TIME: "SUSPENSION_TIME",
  UNSET_IN_HOUSE_PROVIDER: "UNSET_IN_HOUSE_PROVIDER",
  SET_IN_HOUSE_PROVIDER: "SET_IN_HOUSE_PROVIDER",
  LINK_TO_CHRONIC_FORM: "LINK_TO_CHRONIC_FORM",
  UNLINK_CHRONIC_FORM: "UNLINK_CHRONIC_FORM",
  CREATE_NEW_CHRONIC_FORM: "CREATE_NEW_CHRONIC_FORM",
  TURN_AROUND_TIME_MINUTES: "TURN_AROUND_TIME_MINUTES",
  IS_PROVIDER_NOTIFIED: "IS_PROVIDER_NOTIFIED",
  DISTRIBUTION: "DISTRIBUTION",
  ONLY_UNPROCESSED_REQUESTS: "ONLY_UNPROCESSED_REQUESTS",
  ONLY_TRACKED_REQUESTS: "ONLY_TRACKED_REQUESTS",
  INITIAL_REQUEST_DATE: "INITIAL_REQUEST_DATE",
  LATEST_REVISION_REQUEST_DATE: "LATEST_REVISION_REQUEST_DATE",
  REPORT_TYPE: "REPORT_TYPE",
  USER_PERFORMANCE: "USER_PERFORMANCE",
  OVERALL_PERFORMANCE: "OVERALL_PERFORMANCE",
  TRANSMITTAL_RECEIPT_DATE: "TRANSMITTAL_RECEIPT_DATE",
  TRANSMITTAL_DUE_DATE: "TRANSMITTAL_DUE_DATE",
  IS_POLICY_ACTIVE: "IS_POLICY_ACTIVE",
  DEPARTMENTS: "DEPARTMENTS",
  TEAMS: "TEAMS",
  DEPARTMENT_SIGNATURE: "DEPARTMENT_SIGNATURE",
  TURN_AROUND_TIME: "TURN_AROUND_TIME",
  IS_AUTOMATIC: "IS_AUTOMATIC",
  EARLY_PAYMENT_STATUS: "EARLY_PAYMENT_STATUS",
  PVP_PAYMENT_DATE: "PVP_PAYMENT_DATE",
  TRACK_CHRONIC_FORM_REQUESTS: "TRACK_CHRONIC_FORM_REQUESTS",
  CHRONIC_FORM_REQUEST_STATUS: "CHRONIC_FORM_REQUEST_STATUS",
  LATEST_SUBMISSION_DATE: "LATEST_SUBMISSION_DATE",
  UPDATE_COMMENT: "UPDATE_COMMENT",
  IS_CODED: "IS_CODED",
  CODED_DATE: "CODED_DATE",
  CODED_BY: "CODED_BY",
  USE_TPA_SERVICE_CODE: "USE_TPA_SERVICE_CODE",
  BILL_PAYMENT: "BILL_PAYMENT",
  YEARS: "YEARS",
  CDV_INVOICE: "CDV_INVOICE",
  DIAGNOSIS_X_SERVICE: "DIAGNOSIS_X_SERVICE",
  AUTOMATICALLY_REJECT_ON_DIAGNOSIS_SERVICE_MISMATCH:
    "AUTOMATICALLY_REJECT_ON_DIAGNOSIS_SERVICE_MISMATCH",
  ENABLE_DIAGNOSIS_SERVICE_MATCH_CHECK: "ENABLE_DIAGNOSIS_SERVICE_MATCH_CHECK",
  SERVICE_DIAGNOSIS_MISMATCH: "SERVICE_DIAGNOSIS_MISMATCH",
  PROCEED: "PROCEED",
  TRACKED_CHRONIC_FORM_REQUESTS: "TRACKED_CHRONIC_FORM_REQUESTS",
  AUDIT_COMMENTS: "AUDIT_COMMENTS",
  AUDIT_COMMENT: "AUDIT_COMMENT",
  DISPENSE_FROM_EXCEPTION_FUND_AUTOMATICALLY:
    "DISPENSE_FROM_EXCEPTION_FUND_AUTOMATICALLY",
  ACCEPT_EXCEEDING_LIMIT_AUTOMATICALLY: "ACCEPT_EXCEEDING_LIMIT_AUTOMATICALLY",
  PROCESSED_REQUESTS_ONLY: "PROCESSED_REQUESTS_ONLY",
  NEED_SUBMISSION_REQUESTS_ONLY: "NEED_SUBMISSION_REQUESTS_ONLY",
  UNDER_PROCESSING_REQUESTS_ONLY: "UNDER_PROCESSING_REQUESTS_ONLY",
  SUBMITTED_REQUESTS_ONLY: "SUBMITTED_REQUESTS_ONLY",
  INHERIT_MAIN_BRANCH_INFO: "INHERIT_MAIN_BRANCH_INFO",
  COPY_ALL_ORGANIZATION_BRANCHES: "COPY_ALL_ORGANIZATION_BRANCHES",
  GROUP_BY_INSURED: "GROUP_BY_INSURED",
  GROUP_BY_FAMILY: "GROUP_BY_FAMILY",
  FAMILY: "FAMILY",
  GROUP_BY_SUB_PRODUCT: "GROUP_BY_SUB_PRODUCT",
  GROUP_BY_CLAIM: "GROUP_BY_CLAIM",
  OUTSTANDING_TRANSACTIONS_ONLY: "OUTSTANDING_TRANSACTIONS_ONLY",
  INCLUDE_CLAIM_DETAILS: "INCLUDE_CLAIM_DETAILS",
  EDIT_TABLE_TYPE_CODE: "EDIT_TABLE_TYPE_CODE",
  DISABLE_BEFORE_POLICY_END_DATE_DAYS: "DISABLE_BEFORE_POLICY_END_DATE_DAYS",
  PLAN_ENDORSEMENT: "PLAN_ENDORSEMENT",
  IS_PVI_OUTSTANDING: "IS_PVI_OUTSTANDING",
  IS_PVP_PVC_OUTSTANDING: "IS_PVP_PVC_OUTSTANDING",
  DOWNLOAD_SUBMISSION: "DOWNLOAD_SUBMISSION",
  TOTALS_ONLY: "TOTALS_ONLY",
  CHRONIC_FORM_REQUEST_ASSIGNMENT_RULE: "CHRONIC_FORM_REQUEST_ASSIGNMENT_RULE",
  CHRONIC_FORM_REQUEST_ASSIGNMENT_RULES:
    "CHRONIC_FORM_REQUEST_ASSIGNMENT_RULES",
};

export default CPresentationConstantsHandler;
