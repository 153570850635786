export default Object.freeze({
  NETWORK: "faChartNetwork",
  SERVICE: "faHandHoldingMedical",
  HIERARCHY: "faSitemap",
  TAG: "faTag",
  LIST_ALT: "faListAlt",
  LIST_TREE: "falListTree",
  SQUARE_E: "faSquareE",
  MEDICAL: "faSuitcaseMedical",
  KIT_MEDICAL: "faKitMedical",
  DIAGNOSIS: "faStethoscope",
  BLOCK: "faBlock",
  REGISTERED: "faRegistered",
  BUILDING: "faBuilding",
  HOSPITAL: "faHospital",
  USERS_GROUP: "faUsers",
  OBJECT_GROUP: "faObjectGroup",
  CARD_ID: "faIdCard",
  // MEMO_PAD: "fal-memo-pad",
  SQUARE_POLL_HORIZONTAL: "faSquarePollHorizontal",
  HAND_HOLDING_BOX: "faHandHoldingBox",
  USER_LOCK: "faUserLock",
  MEDIUM: "faPhoneVolume",
  SWAP: "faSwap",
  // SWAP: "fal-arrow-down-arrow-up",
  // USER_TIE: "fal-user-tie",
  WREATH: "faWreath",
  MONEY_CHECK: "faMoneyCheckDollarPen",
  ID_CARD_CLIP: "faIdCardClip",
  ID_CLIPBOARD_CHECK: "faClipboardCheck",
  FILE_CIRCLE_CHECK: "faFileCircleCheck",
  FOLDER_GEAR: "faFolderGear",
  FOLDER_X_MARK: "faFolderXmark",
  CIRCLE_C: "faCircleC",
  //CIRCLE_F: "fal-circle-f",
  CIRCLE_ARROW_RIGHT: "faCircleArrowRight",
  DOLLAR: "faDollar",
  LAYER_GROUP: "faLayerGroup",
  LIST: "faList",
  SHIELD_HALVED: "faShieldHalved",
  FOLDER: "faFolder",
  BOOKS_MEDICAL: "faBooksMedical",
  GRID_2_PLUS: "faGrid2Plus",
  NOTE_MEDICAL: "faNoteMedical",
  CAPSULES: "faCapsules",
  HOURGLASS_CLOCK: "faHourGlassClock",
  SHIELD_XMARK: "faShieldXMark",
  SHIELD_PLUS: "faShieldPlus",
  MESSAGE_PEN: "faMessagePen",
  MESSAGE_XMARK: "faMessageXMark",
  HANDSHAKE_SIMPLE: "faHandshakeSimple",
  HANDSHAKE_SIMPLE_SLASH: "faHandshakeSimpleSlash",
  PEOPLE_ROOF: "faPeopleRoof",
  SHEET_PLASTIC: "faSheetPlastic",
  SQUARE_PEN: "faSquarePen",
  FILE_PEN: "faFilePen",
  FILE_SLASH: "faFileSlash",
  FILE_PENCIL_SLASH: "faPencilSlash",
  FILE_ARROW_DOWN: "faFileArrowDown",
  FILE_CHECK: "faFileCheck",
  FILE_IMPORT: "faFileImport",
  FILE_LINES: "faFileLines",
  BOOK: "faBook",
  BUILDING_FLAG: "faBuildingFlag",
  USER_HELMET: "faUserHelmetSafety",
  HOUSE_CHIMNEY: "faHouseChimney",
  PROVIDER: "faUserMd",
  USER_SHIELD: "faUserShield",
  COINS: "faCoins",
  BUILDING_SHIELD: "faBuildingShield",
  CALENDAR_CLOCK: "faCalendarClock",
  ROTATE_LEFT: "faRotateLeft",
  BELL: "faBell",
  USER_TIE: "faUserTie",
  FILES: "faFiles",
  BANK: "faBank",
  SQUARE_C: "faSquareC",
  SQUARE_V: "faSquareV",
  FaLockKeyhole: "faLockKeyhole",
  FaLockKeyholeOpen: "faLockKeyholeOpen",
  FILE_MEDICAL: "faFileMedical",
  FILE_INVOICE_DOLLAR: "faFileInvoiceDollar",
  ADDRESS_CARD: "faAddressCard",
  FILE_SHIELD: "faFileShield",
  BOXES_STACKED: "faBoxesStacked",
  SACK_DOLLAR: "faSackDollar",
  INGREDIENTS: "faFlaskGear",
  CHEMICAL_COMPOUND: "faFlaskVial",
  SEND: "faPaperPlane",
  SQUARE_Y: "faSquareY",
  CHECK_IN: "faArrowLeftToArc",
  CHECK_IN_WHITE: "faArrowLeftToArcWhite",
  CHECK_OUT: "faArrowRightFromArc",
  CHECK_OUT_WHITE: "faArrowRightFromArcWhite",
  RADAR: "faRadar",
  LEVEL_1: "faCircle1",
  LEVEL_2: "faCircle2",
  LEVEL_3: "faCircle3",
  LEVEL_4: "faCircle4",
  USER_HEADSET: "faUserHeadset",
  EXCEL: "faFileExcel",
  POINTER: "faPointer",
  ARROW_UP_LEFT_FROM_CIRCULE: "faArrowUpLeftFromCircle",
  USER_CHECK: "faUserCheck",
  USER_SLASH: "faUserSlash",
  EDIT_USER: "faUserPen",
  SIGNATURE: "faFileSignature",
  HOSPITAL_USER: "faHospitalUser",
  HANDS_HOLDING_DOLLAR: "faHandsHoldingDollar",
  USER_TAG: "faUserTag",
  SHIELD_CHECK: "faShieldCheck",
  DOCTOR: "faUserDoctor",
  MESSAGE_DOTS: "faMessageDots",
  DOCTOR_COMMUNICATION: "faUserDoctorMessage",
  SMS: "faMessageSms",
  EARTH_AMERICAS: "faEarthAmericas",
  FLAG: "faFlag",
  MAP_LOCATION: "faMapLocation",
  STAFF_SNAKE: "faStaffSnake",
  PERSCRIPTION_BOTTLE_PILL: "faPrescriptionBottlePill",
  WALLET: "faWallet",
  VAULT: "faVault",
  LIST_CHECK: "fa-list-check",
  FILE_EXPORT: "faFileExport",
  POWER: "faPowerOff",
});
